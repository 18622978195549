import React from "react";
import { connect } from "react-redux";
import axios from "axios";

import { setLastCustomer, fetchCustomers } from "../../actions";

import { API_ENDPOINT } from "../../config.js";
import {
  Button,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Loader,
  Modal,
  Table,
  TextArea,
} from "semantic-ui-react";

import ContactCard from "./ContactCard";
import BookingList from "../bookings/BookingList";

const INITIAL_STATE = {
  loading: true,
  saved: true,
  id: 0,
  name: "",
  city: "",
  country: "se",
  chain: "1",
  category: "1",
  postal_address: "",
  invoice_address: "",
  invoice_email: "",
  visiting_address: "",
  website: "",
  distance: "",
  google_maps: "",
  notes_to_staff: "",
  internal_notes: "",
  marked: false,
  contacts: [],
  newContact: false,
  hasEvents: true,
  photos: [],
  show: "",
  delete: false,
  deletePhotoWarning: false,
};

class Customer extends React.Component {
  state = INITIAL_STATE;

  constructor(props) {
    super(props);

    this.keyStroke = this.keyStroke.bind(this);
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyStroke, false);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyStroke, false);
  }

  keyStroke({ keyCode }) {
    if (this.state.show) {
      switch (keyCode) {
        case 37:
          this.previousPhoto();
          break;
        case 39:
          this.nextPhoto();
          break;
        default:
          break;
      }
    }
  }

  previousPhoto = () => {
    const { photos, show } = this.state;
    if (photos.length < 2) {
      return;
    }

    if (show === photos[0]) {
      this.setState({ show: photos[photos.length - 1] });
    } else {
      this.setState({ show: photos[photos.indexOf(show) - 1] });
    }
  };

  nextPhoto = () => {
    const { photos, show } = this.state;
    if (photos.length < 2) {
      return;
    }

    if (show === photos[photos.length - 1]) {
      this.setState({ show: photos[0] });
    } else {
      this.setState({ show: photos[photos.indexOf(show) + 1] });
    }
  };

  componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);

    if (Number(nextProps.match.params.id) !== this.state.id) {
      this.fetchCustomer(nextProps.match.params.id);
      if (nextProps.match.params.id !== "new") {
        // TODO: Move to fetchCustomer
        this.props.setLastCustomer(Number(nextProps.match.params.id));
      }
    }

    const customer = nextProps.customers.find((customer) => customer.id === Number(nextProps.match.params.id));
    if (customer && this.state.marked !== customer.marked) {
      this.setState({ marked: customer.marked });
    }
  }

  async fetchCustomer(id) {
    if (id === "new") {
      this.setState({
        ...INITIAL_STATE,
        loading: false,
      });
    } else {
      this.setState({ loading: true });

      const { data } = await axios.get(`${API_ENDPOINT}/api/customers/${id}`);

      this.setState({
        id,
        ...data,
        loading: false,
      });
    }
  }

  onChange(type, value) {
    this.setState({
      [type]: value,
      saved: false,
    });
  }

  submit(event) {
    event.preventDefault();

    axios.put(`${API_ENDPOINT}/api/customers/${this.props.match.params.id}`, this.state).then(({ data }) => {
      this.props.fetchCustomers();
      if (this.state.id === 0) {
        this.props.history.push(`/customers/${data}`);
      } else {
        this.fetchCustomer(this.props.match.params.id);
      }
    });
  }

  renderContacts() {
    if (this.state.id === 0) {
      return;
    }

    return this.state.contacts.map((contact) => {
      return <ContactCard key={`contact${contact.id}`} contact={contact.id} />;
    });
  }

  renderNewContact() {
    if (this.state.id === 0 || this.state.loading) {
      return <div />;
    }

    return this.state.newContact ? (
      <ContactCard
        cancelNewContact={() => this.setState({ newContact: false })}
        saveNewContact={() => {
          this.setState({ newContact: false });
          this.fetchCustomer(this.props.match.params.id);
        }}
        customer={this.state.id}
      />
    ) : (
      <Button color="green" fluid icon="plus" onClick={() => this.setState({ newContact: true })} />
    );
  }

  renderDelete() {
    if (!this.state.hasEvents && this.state.contacts.length === 0) {
      if (this.state.delete) {
        return (
          <>
            <Button
              color="red"
              style={{ float: "right", marginBottom: 24 }}
              onClick={async () => {
                await axios.delete(`${API_ENDPOINT}/api/customers/${this.state.id}`);
                this.props.setLastCustomer(104);
                this.props.history.push(`/customers/new`);
              }}
            >
              <Icon name="trash" /> Är du säker?
            </Button>
            <Button style={{ float: "right", marginBottom: 24 }} onClick={() => this.setState({ delete: false })}>
              <Icon name="cancel" /> Avbryt
            </Button>
          </>
        );
      }

      return (
        <Button
          color="red"
          style={{ float: "right", marginBottom: 24 }}
          onClick={() => this.setState({ delete: true })}
        >
          <Icon name="trash" /> Radera
        </Button>
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <Loader style={{ marginLeft: 100 }} size="massive" active />;
    }

    return (
      <>
        <Modal
          open={this.state.show.length > 0}
          onClose={() => this.setState({ show: "", deletePhotoWarning: false })}
          basic
        >
          <Image
            src={`${API_ENDPOINT}/api/static/1600/${this.state.show}.jpg`}
            style={{ display: "block", maxHeight: "80vh", margin: "0 auto" }}
          />
          <Modal.Actions style={{ width: 560, margin: "0 auto" }}>
            <Grid columns={2} style={{ padding: "0px 10px 0 10px" }}>
              <Grid.Row>
                <Grid.Column textAlign="left" width={9}>
                  {!this.state.deletePhotoWarning && (
                    <Button
                      color="green"
                      as="a"
                      target="_blank"
                      href={`${API_ENDPOINT}/api/static/full/${this.state.show}.jpg`}
                    >
                      <Icon name="download" /> Ladda ner
                    </Button>
                  )}
                  {this.state.deletePhotoWarning ? (
                    <>
                      <Button
                        color="red"
                        onClick={() =>
                          axios
                            .delete(`${API_ENDPOINT}/api/bookings/${this.props.id}/photos/${this.state.show}`)
                            .then(() => {
                              this.setState({
                                photos: this.state.photos.reduce(
                                  (acc, next) => (next === this.state.show ? acc : [...acc, next]),
                                  []
                                ),
                                show: "",
                                deletePhotoWarning: false,
                              });
                            })
                        }
                      >
                        <Icon name="warning" /> Är du säker?
                      </Button>
                      <Button onClick={() => this.setState({ deletePhotoWarning: false })}>
                        <Icon name="cancel" /> Avbryt
                      </Button>
                    </>
                  ) : (
                    <Button color="red" onClick={() => this.setState({ deletePhotoWarning: true })}>
                      <Icon name="trash" /> Radera
                    </Button>
                  )}
                </Grid.Column>
                <Grid.Column textAlign="right" width={7}>
                  {!this.state.deleteWarning && (
                    <>
                      <Button icon="arrow circle left" onClick={this.previousPhoto} />
                      <Button style={{ cursor: "default" }}>
                        {this.state.photos.indexOf(this.state.show) + 1} / {this.state.photos.length}
                      </Button>
                      <Button icon="arrow circle right" onClick={this.nextPhoto} />
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Actions>
        </Modal>
        <Grid>
          <Grid.Column width={8}>
            <Form onSubmit={this.submit.bind(this)}>
              <Table basic="very">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ width: 160, textAlign: "center" }}>
                      <Checkbox
                        toggle
                        checked={this.state.marked}
                        onChange={() => {
                          if (this.state.new) {
                            this.setState({ marked: !this.state.marked });
                          } else {
                            if (this.props.match.params.id === "new") {
                              this.setState({ marked: !this.state.marked });
                            } else {
                              axios
                                .patch(`${API_ENDPOINT}/api/customers/${this.state.id}/marked`, {
                                  marked: !this.state.marked,
                                })
                                .then(() => {
                                  this.setState({ marked: !this.state.marked });
                                  this.props.fetchCustomers();
                                });
                            }
                          }
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="Företag"
                        style={{ fontSize: 24 }}
                        value={this.state.name}
                        onChange={(input) => this.onChange("name", input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Ort</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="Ort"
                        value={this.state.city}
                        onChange={(input) => this.onChange("city", input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Land</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        noResultsMessage="Inga länder matchade..."
                        selection
                        fluid
                        search
                        options={[
                          { key: "se", value: "se", flag: "se", text: "Sverige" },
                          { key: "no", value: "no", flag: "no", text: "Norge" },
                          { key: "dk", value: "dk", flag: "dk", text: "Danmark" },
                        ]}
                        value={this.state.country}
                        onChange={(event, data) => this.onChange("country", data.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Kejda</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        noResultsMessage="Inga kedjor matchade..."
                        selection
                        fluid
                        search
                        options={this.props.chains.map((chain) => {
                          return {
                            key: `chain${chain.id}`,
                            value: String(chain.id),
                            text: chain.name,
                          };
                        })}
                        value={String(this.state.chain)}
                        onChange={(event, data) => this.onChange("chain", Number(data.value))}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Kategori</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        noResultsMessage="Ingen kategori matchade..."
                        selection
                        fluid
                        search
                        options={this.props.customerCategories.map((category) => {
                          return {
                            key: `category${category.id}`,
                            value: String(category.id),
                            text: category.name,
                          };
                        })}
                        value={String(this.state.category)}
                        onChange={(event, data) => this.onChange("category", Number(data.value))}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Postadress</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <TextArea
                        value={this.state.postal_address}
                        onChange={(input) => this.onChange("postal_address", input.target.value)}
                        autoHeight
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Faktura-adress</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <TextArea
                        value={this.state.invoice_address}
                        onChange={(input) => this.onChange("invoice_address", input.target.value)}
                        autoHeight
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Faktura-epost</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder=""
                        value={this.state.invoice_email}
                        onChange={(input) => this.onChange("invoice_email", input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Besöksadress</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <TextArea
                        value={this.state.visiting_address}
                        onChange={(input) => this.onChange("visiting_address", input.target.value)}
                        autoHeight
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header
                        sub
                        as="a"
                        href={`${this.state.website.substring(4) === "https://" ? "" : "https://"}${
                          this.state.website
                        }`}
                        target="_blank"
                      >
                        Hemsida
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="www.exempel.se"
                        value={this.state.website}
                        onChange={(input) => this.onChange("website", input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Avstånd</Header>km en väg
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        value={this.state.distance}
                        onChange={(input) => this.onChange("distance", input.target.value.replace(/[^0-9]/g, ""))}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Google Maps</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="http://maps.google.se/maps?q=XXXXX"
                        value={this.state.google_maps}
                        onChange={(input) => this.onChange("google_maps", input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>
                        Info till personal
                        <br />
                        (Visas i mobilen)
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <TextArea
                        value={this.state.notes_to_staff}
                        onChange={(input) => this.onChange("notes_to_staff", input.target.value)}
                        autoHeight
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Interna anteckningar</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <TextArea
                        rows={5}
                        value={this.state.internal_notes}
                        onChange={(input) => this.onChange("internal_notes", input.target.value)}
                        autoHeight
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>

              <Button color={this.state.saved ? "grey" : "green"} fluid>
                <Icon name="save" />
                {this.state.saved ? "Sparat!" : "Spara"}
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column width={8} style={{ marginTop: 10 }}>
            {this.renderDelete()}
            {this.renderContacts()}
            {this.renderNewContact()}
          </Grid.Column>
          <Grid.Column width={16}>
            {this.state.photos.map((photo) => (
              <Image
                key={photo}
                src={`${API_ENDPOINT}/api/static/320/${photo}.jpg`}
                className="fade-in"
                style={{
                  maxHeight: 134,
                  marginRight: 10,
                  marginBottom: 10,
                  cursor: "pointer",
                  borderRadius: 3,
                  float: "left",
                  border: "1px solid #eee",
                }}
                alt=""
                onClick={() => this.setState({ show: photo })}
              />
            ))}
          </Grid.Column>
          <Grid.Column width={16}>{this.state.id !== 0 && <BookingList customer={this.state.id} />}</Grid.Column>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ app }) => {
  const { chains, customers, customerCategories } = app;

  return { chains, customers, customerCategories };
};

export default connect(mapStateToProps, { setLastCustomer, fetchCustomers })(Customer);
