import { NAVIGATE, SET_TICKET_CITY, LOGOUT } from '../actions/types';

const INITIAL_STATE = {
  main: 'bookings',
  ticket: 'dashboard'
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case NAVIGATE:
      return {
        ...state,
        main: action.payload.main
      };
    case SET_TICKET_CITY:
      return {
        ...state,
        ticket: action.payload
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
