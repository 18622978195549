import React from 'react';
import { Modal, Header } from 'semantic-ui-react';

class TechnicalInfo extends React.Component {
  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={this.props.handleClose}
        size="small"
      >
        <Header icon="browser" content="Eventteknisk info" />
        <Modal.Content>{this.props.technicalInfo}</Modal.Content>
      </Modal>
    );
  }
}

export default TechnicalInfo;
