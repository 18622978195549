import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { ChromePicker } from 'react-color';
import { API_ENDPOINT } from '../../config';
import { formatSize } from '../../utilities';
import { fetchEvents, navigate } from '../../actions';

import { Modal, Header, Icon, Button, Form, Dropdown, Checkbox, Image, Input, Table, Grid } from 'semantic-ui-react';
import Textarea from 'react-textarea-autosize';

import './event.css';

const INITIAL_STATE = {
  name: '',
  active: true,
  color: '',
  sek: '',
  dkk: '',
  nok: '',
  notesToCustomer: '',
  notesToStaff: '',
  miscNotes: '',
  mountingNotes: '',
  mountingTime: '1.0',
  technicalInfo: '',
  created: '',
  updated: '',
  photos: [],
  show: '',
  count: 0,
  image: '',
  fileName: '',
  instructions: '',
  internalNotes: '',
  size: 0,
  hasImage: false,
  hasInstructions: false,
  uploadingThumbnail: false,
  uploadingInstructions: false,
  deleteInstructionsWarning: false,
  deletePhotoWarning: false,
  deleteWarning: false,
  saved: true,
};

class Event extends React.Component {
  state = INITIAL_STATE;

  constructor(props) {
    super(props);

    this.keyStroke = this.keyStroke.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.keyStroke, false);
  }

  keyStroke({ keyCode }) {
    if (this.state.show) {
      switch (keyCode) {
        case 37:
          this.previousPhoto();
          break;
        case 39:
          this.nextPhoto();
          break;
        default:
          break;
      }
    }
  }

  previousPhoto = () => {
    const { photos, show } = this.state;
    if (photos.length < 2) {
      return;
    }

    if (show === photos[0]) {
      this.setState({ show: photos[photos.length - 1] });
    } else {
      this.setState({ show: photos[photos.indexOf(show) - 1] });
    }
  };

  nextPhoto = () => {
    const { photos, show } = this.state;
    if (photos.length < 2) {
      return;
    }

    if (show === photos[photos.length - 1]) {
      this.setState({ show: photos[0] });
    } else {
      this.setState({ show: photos[photos.indexOf(show) + 1] });
    }
  };

  componentWillReceiveProps(nextProps) {
    if (nextProps.id > 0) {
      axios.get(`${API_ENDPOINT}/api/events/${nextProps.id}`).then(({ data }) => {
        const {
          name,
          active,
          color,
          sek,
          dkk,
          nok,
          notes_to_customer: notesToCustomer,
          notes_to_staff: notesToStaff,
          mounting_notes: mountingNotes,
          mounting_time: mountingTime,
          technical_info: technicalInfo,
          internal_notes: internalNotes,
          misc_notes: miscNotes,
          created,
          updated,
          count,
          hasImage,
          hasInstructions,
          size,
          photos,
        } = data;

        this.setState({
          name,
          active,
          color: `#${color}`,
          sek,
          dkk,
          nok,
          notesToCustomer,
          notesToStaff,
          mountingNotes,
          mountingTime,
          technicalInfo,
          internalNotes,
          miscNotes,
          created,
          updated,
          count: Number(count),
          hasImage,
          hasInstructions,
          image: `v${this.props.id}.jpg`,
          instructions: '',
          size,
          photos,
          show: '',
        });
      });
    }
  }

  save() {
    const {
      name,
      active,
      color,
      sek,
      dkk,
      nok,
      mountingNotes,
      mountingTime,
      technicalInfo,
      notesToCustomer,
      notesToStaff,
      image,
      instructions,
      internalNotes,
      miscNotes,
    } = this.state;

    axios
      .put(`${API_ENDPOINT}/api/events/${this.props.id}`, {
        name,
        active,
        color: color.substring(1),
        sek: Number(sek),
        dkk: Number(dkk),
        nok: Number(nok),
        mountingNotes,
        mountingTime,
        technicalInfo,
        notesToCustomer,
        notesToStaff,
        image,
        instructions,
        internalNotes,
        miscNotes,
      })
      .then(() => {
        this.setState(INITIAL_STATE);
        this.props.fetchEvents();
        this.props.close();
      });
  }

  componentWillUnmount() {
    this.setState(INITIAL_STATE);
    document.removeEventListener('keydown', this.keyStroke, false);
  }

  render() {
    return (
      <>
        <Modal
          open={this.state.show.length > 0}
          onClose={() => this.setState({ show: '', deletePhotoWarning: false })}
          basic
        >
          <Image
            src={`${API_ENDPOINT}/api/static/1600/${this.state.show}.jpg`}
            style={{ display: 'block', maxHeight: '80vh', margin: '0 auto' }}
          />
          <Modal.Actions style={{ width: 560, margin: '0 auto' }}>
            <Grid columns={2} style={{ padding: '0px 10px 0 10px' }}>
              <Grid.Row>
                <Grid.Column textAlign="left" width={9}>
                  {!this.state.deletePhotoWarning && (
                    <Button
                      color="green"
                      as="a"
                      target="_blank"
                      href={`${API_ENDPOINT}/api/static/full/${this.state.show}.jpg`}
                    >
                      <Icon name="download" /> Ladda ner
                    </Button>
                  )}
                  {this.state.deletePhotoWarning ? (
                    <>
                      <Button
                        color="red"
                        onClick={() =>
                          axios
                            .delete(`${API_ENDPOINT}/api/bookings/${this.props.id}/photos/${this.state.show}`)
                            .then(() => {
                              this.setState({
                                photos: this.state.photos.reduce(
                                  (acc, next) => (next === this.state.show ? acc : [...acc, next]),
                                  []
                                ),
                                show: '',
                                deletePhotoWarning: false,
                              });
                            })
                        }
                      >
                        <Icon name="warning" /> Är du säker?
                      </Button>
                      <Button onClick={() => this.setState({ deletePhotoWarning: false })}>
                        <Icon name="cancel" /> Avbryt
                      </Button>
                    </>
                  ) : (
                    <Button color="red" onClick={() => this.setState({ deletePhotoWarning: true })}>
                      <Icon name="trash" /> Radera
                    </Button>
                  )}
                </Grid.Column>
                <Grid.Column textAlign="right" width={7}>
                  {!this.state.deleteWarning && (
                    <>
                      <Button icon="arrow circle left" onClick={this.previousPhoto} />
                      <Button style={{ cursor: 'default' }}>
                        {this.state.photos.indexOf(this.state.show) + 1} / {this.state.photos.length}
                      </Button>
                      <Button icon="arrow circle right" onClick={this.nextPhoto} />
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Actions>
        </Modal>
        <Modal
          open={this.props.id > 0 || this.props.new}
          onClose={() => {
            this.setState(INITIAL_STATE);
            this.props.close();
          }}
          closeOnDimmerClick={false}
          size="large"
          style={{
            borderTop: `24px solid ${this.state.color}`,
            borderBottom: `5px solid ${this.state.color}`,
            borderLeft: `1px solid ${this.state.color}`,
            borderRight: `1px solid ${this.state.color}`,
          }}
        >
          <Grid columns={2} style={{ padding: '20px 20px 0 20px' }}>
            <Grid.Row>
              <Grid.Column>
                <div style={{ float: 'left' }}>
                  <div
                    style={{
                      width: 86,
                      height: 86,
                      marginLeft: 1,
                      textAlign: 'center',
                      borderRadius: 3,
                      paddingTop: 30,
                      background: '#eee',
                      color: '#333',
                    }}
                  >
                    {this.state.uploading ? (
                      <i>
                        Laddar
                        <br />
                        upp...
                      </i>
                    ) : (
                      <i>
                        Ladda upp
                        <br />
                        eventbild
                      </i>
                    )}

                    {this.state.hasImage && !this.state.uploading && (
                      <Image
                        src={`${API_ENDPOINT}/api/static/eventthumbnails/${this.state.image}?i=${Math.ceil(
                          Math.random() * 10000
                        )}`}
                        role="presentation"
                        style={{
                          position: 'absolute',
                          left: 15,
                          top: 0,
                          width: 86,
                          height: 86,
                          borderRadius: 3,
                        }}
                      />
                    )}
                    <input
                      type="file"
                      accept="image/jpeg"
                      style={{
                        position: 'absolute',
                        left: 15,
                        top: 0,
                        width: 86,
                        height: 86,
                        opacity: 0,
                        cursor: 'pointer',
                      }}
                      onChange={(event) => {
                        this.setState({ uploadingThumbnail: true });

                        const formData = new FormData();
                        formData.append('file', event.target.files[0], event.target.files[0].name);

                        axios
                          .post(`${API_ENDPOINT}/api/events/${this.props.id}/thumbnail`, formData)
                          .then(({ data }) => {
                            this.setState({
                              image: data,
                              hasImage: true,
                              saved: false,
                              uploadingThumbnail: false,
                            });
                          });
                      }}
                    />
                  </div>
                </div>
                <div style={{ float: 'left', marginLeft: 20, marginTop: 32, height: 60 }}>
                  <Header size="large" content={this.props.new ? 'Lägg till event' : `${this.state.name}`} />
                  {this.props.id > 0 && (
                    <span style={{ color: '#aaa', fontSize: 12, marginTop: 10 }}>
                      ID: {this.props.id} - Senast ändrad: {moment(this.state.updated).format('YYYY-MM-DD HH:MM')}
                    </span>
                  )}
                </div>
              </Grid.Column>
              <Grid.Column textAlign="right">
                <Checkbox
                  checked={this.state.active}
                  toggle
                  onChange={() => this.setState({ active: !this.state.active, saved: false })}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <Modal.Content scrolling>
            <Form onSubmit={this.save.bind(this)}>
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>Namn</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder=""
                        value={this.state.name}
                        onChange={(input) =>
                          this.setState({
                            name: input.target.value,
                            saved: false,
                          })
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>Färg</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Grid>
                        <Grid.Row>
                          <Grid.Column width={4}>
                            <ChromePicker
                              color={this.state.color}
                              onChange={(color) => {
                                this.setState({ color: color.hex, saved: false });
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column verticalAlign="middle" width={4}>
                            <Header sub textAlign="right">
                              Pris
                            </Header>
                          </Grid.Column>
                          <Grid.Column verticalAlign="middle" width={4}>
                            <Input
                              fluid
                              value={this.state.sek}
                              labelPosition="right"
                              label="SEK"
                              onChange={(input) =>
                                this.setState({
                                  sek: input.target.value.replace(/[^0-9]/g, ''),
                                  saved: false,
                                })
                              }
                            />
                            <br />
                            <Input
                              fluid
                              value={this.state.dkk}
                              labelPosition="right"
                              label="DKK"
                              onChange={(input) =>
                                this.setState({
                                  dkk: input.target.value.replace(/[^0-9]/g, ''),
                                  saved: false,
                                })
                              }
                            />
                            <br />
                            <Input
                              fluid
                              value={this.state.nok}
                              labelPosition="right"
                              label="NOK"
                              onChange={(input) =>
                                this.setState({
                                  nok: input.target.value.replace(/[^0-9]/g, ''),
                                  saved: false,
                                })
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      </Grid>
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>Viktiga upplysningar</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Textarea
                        style={{ minHeight: 80 }}
                        value={this.state.notesToCustomer}
                        onChange={(input) =>
                          this.setState({
                            notesToCustomer: input.target.value,
                            saved: false,
                          })
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>Montering</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        value={this.state.mountingNotes}
                        onChange={(input) =>
                          this.setState({
                            mountingNotes: input.target.value,
                            saved: false,
                          })
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>Uppbyggnadstid</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        icon="clock"
                        options={[
                          { key: '1', value: '1.0', text: '1' },
                          { key: '1.5', value: '1.5', text: '1.5' },
                          { key: '2', value: '2.0', text: '2' },
                          { key: '2.5', value: '2.5', text: '2.5' },
                          { key: '3', value: '3.0', text: '3' },
                        ]}
                        value={this.state.mountingTime}
                        onChange={(event, data) =>
                          this.setState({
                            mountingTime: data.value,
                            saved: false,
                          })
                        }
                      />{' '}
                      {this.state.mountingTime === '1.0' ? 'timme' : 'timmar'}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>Eventteknisk info</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Textarea
                        style={{ minHeight: 80 }}
                        value={this.state.technicalInfo}
                        onChange={(input) =>
                          this.setState({
                            technicalInfo: input.target.value,
                            saved: false,
                          })
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>Instruktioner</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input fluid type="text" action>
                        <Button
                          color="black"
                          icon="upload"
                          style={{
                            borderBottomRightRadius: 0,
                            borderTopRightRadius: 0,
                          }}
                        />
                        <input
                          type="file"
                          accept="application/pdf"
                          style={{
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            opacity: 0,
                            height: 40,
                            width: 40,
                            cursor: 'pointer',
                          }}
                          onChange={(event) => {
                            event.preventDefault();

                            this.setState({ uploadingInstructions: true });

                            const formData = new FormData();
                            const { name: fileName, size } = event.target.files[0];

                            formData.append('file', event.target.files[0], fileName);

                            axios
                              .post(`${API_ENDPOINT}/api/events/${this.props.id}/instructions`, formData)
                              .then(({ data }) => {
                                this.setState({
                                  instructions: data,
                                  hasInstructions: true,
                                  saved: false,
                                  uploadingInstructions: false,
                                  fileName,
                                  size,
                                });
                              });
                          }}
                        />
                        <input
                          disabled
                          value={
                            this.state.hasInstructions
                              ? `${
                                  this.state.fileName ? this.state.fileName : `Instruktioner ${this.state.name}`
                                } (${formatSize(this.state.size)})`
                              : 'Inga instruktioner uppladdade'
                          }
                          style={{ zIndex: 10 }}
                        />
                        {this.state.deleteInstructionsWarning ? (
                          <>
                            <Button
                              color="black"
                              onClick={(event) => {
                                event.preventDefault();
                                this.setState({
                                  deleteInstructionsWarning: false,
                                });
                              }}
                            >
                              <Icon name="cancel" /> Avbryt
                            </Button>
                            <Button
                              color="red"
                              disabled={!this.state.hasInstructions}
                              onClick={(event) => {
                                event.preventDefault();

                                axios
                                  .delete(
                                    `${API_ENDPOINT}/api/events/${this.props.id}/instructions/${
                                      this.state.instructions ? this.state.instructions : this.props.id + '.pdf'
                                    }`
                                  )
                                  .then(() => {
                                    this.setState({
                                      deleteInstructionsWarning: false,
                                      hasInstructions: this.state.instructions ? this.state.hasInstructions : false,
                                      instructions: '',
                                      fileName: '',
                                    });
                                  });
                              }}
                            >
                              <Icon name="trash" /> Är du säker?
                            </Button>
                          </>
                        ) : (
                          <>
                            <Button
                              as="a"
                              href={`${API_ENDPOINT}/api/instructions/${
                                this.state.instructions ? this.state.instructions : `${this.props.id}.pdf`
                              }`}
                              target="_blank"
                              color="black"
                              icon="download"
                              disabled={!this.state.hasInstructions}
                            />
                            <Button
                              color="red"
                              disabled={!this.state.hasInstructions}
                              icon="remove"
                              onClick={(event) => {
                                event.preventDefault();

                                this.setState({
                                  deleteInstructionsWarning: true,
                                });
                              }}
                            />
                          </>
                        )}
                      </Input>
                      {this.state.file && (
                        <span style={{ color: '#999' }}>
                          {this.state.file.name} ({formatSize(this.state.file.size)})
                        </span>
                      )}
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>Anteckningar</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Textarea
                        style={{ minHeight: 80 }}
                        value={this.state.internalNotes}
                        onChange={(input) =>
                          this.setState({
                            internalNotes: input.target.value,
                            saved: false,
                          })
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>
                        Instruktioner
                        <br />
                        till personal
                      </Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Textarea
                        style={{ minHeight: 80 }}
                        value={this.state.notesToStaff}
                        onChange={(input) =>
                          this.setState({
                            notesToStaff: input.target.value,
                            saved: false,
                          })
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>Övrigt</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Textarea
                        style={{ minHeight: 80, background: '#fffff2' }}
                        value={this.state.miscNotes}
                        onChange={(input) =>
                          this.setState({
                            miscNotes: input.target.value,
                            saved: false,
                          })
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                  {this.state.photos.length ? (
                    <Table.Row>
                      <Table.Cell textAlign="right" style={{ width: 120 }}>
                        <Header sub>Bilder</Header>
                      </Table.Cell>
                      <Table.Cell>
                        {this.state.photos.map((photo) => (
                          <Image
                            key={photo}
                            src={`${API_ENDPOINT}/api/static/320/${photo}.jpg`}
                            className="fade-in"
                            style={{
                              maxHeight: 134,
                              marginRight: 10,
                              marginBottom: 10,
                              cursor: 'pointer',
                              borderRadius: 3,
                              float: 'left',
                              border: '1px solid #eee',
                            }}
                            alt=""
                            onClick={() => this.setState({ show: photo })}
                          />
                        ))}
                      </Table.Cell>
                    </Table.Row>
                  ) : null}
                </Table.Body>
              </Table>
            </Form>
          </Modal.Content>
          <Modal.Actions>
            <Grid columns={2} style={{ padding: '0px 10px 0 10px' }}>
              <Grid.Row>
                <Grid.Column textAlign="left">
                  {!this.props.new && this.state.deleteWarning ? (
                    <>
                      <Button onClick={() => this.setState({ deleteWarning: false })}>
                        <Icon name="remove" /> Avbryt
                      </Button>
                      <Button
                        color="red"
                        onClick={async () => {
                          await axios.delete(`${API_ENDPOINT}/api/events/${this.props.id}`);
                          this.props.fetchEvents();
                          this.setState(INITIAL_STATE);
                          this.props.close();
                        }}
                      >
                        <Icon name="trash" /> Är du säker?
                      </Button>
                    </>
                  ) : (
                    <Button
                      color="red"
                      onClick={() => this.setState({ deleteWarning: true })}
                      disabled={this.state.count > 0}
                    >
                      <Icon name="trash" /> Radera
                    </Button>
                  )}
                </Grid.Column>
                {!this.state.deleteWarning && (
                  <Grid.Column textAlign="right">
                    <Button color="grey" onClick={this.props.close}>
                      <Icon name="cancel" /> Avbryt
                    </Button>
                    <Button
                      color="green"
                      onClick={this.save.bind(this)}
                      disabled={this.state.saved || !this.state.name.length}
                    >
                      <Icon name="save" />
                      Spara
                    </Button>
                  </Grid.Column>
                )}
              </Grid.Row>
            </Grid>
          </Modal.Actions>
        </Modal>
      </>
    );
  }
}

export default connect(null, { fetchEvents, navigate })(Event);
