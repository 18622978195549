import React from 'react';
import { connect } from 'react-redux';
import { Menu, Button, Input, Icon, Popup } from 'semantic-ui-react';
import { Link, Route } from 'react-router-dom';
import { navigate } from '../../actions';
import PackingList from './PackingList';
import axios from 'axios';

import './packing.css';
import { API_ENDPOINT } from '../../config';

const INITIAL_STATE = {
  search: '',
  reset: false,
  deleteList: false,
  packingLists: [],
  saved: true,
  saving: false,
  saveButtonEnabled: false,
};

class Packing extends React.Component {
  state = INITIAL_STATE;

  constructor(props) {
    super(props);

    this.packingList = React.createRef();
  }

  componentDidMount() {
    this.props.navigate('packing', 'lists');
    this.fetchLists();
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps() {
    this.props.navigate('packing', 'lists');
  }

  fetchLists = () => {
    axios
      .get(`${API_ENDPOINT}/api/packing`)
      .then(({ data: packingLists }) => this.setState({ ...INITIAL_STATE, packingLists }));
  };

  setSaved = (saved) => this.setState({ saved });
  setSaveButtonEnabled = (saveButtonEnabled) => this.setState({ saveButtonEnabled });
  onDelete = () =>
    this.packingList.current.onDelete().then(() => {
      this.props.history.push(`/packing/${this.state.packingLists[0] ? this.state.packingLists[0].id : 'new'}`);
    });
  onSave = () => this.packingList.current.onSave();
  fetchPackingList = () => this.packingList.current.fetchPackingList();

  renderHeader() {
    const { reset, deleteList, saved, saving, saveButtonEnabled } = this.state;

    return (
      <div style={{ top: 51, position: 'fixed', zIndex: 200, width: '100%' }}>
        <Menu style={{ background: '#fff', marginLeft: -22, paddingRight: 22 }}>
          {reset ? (
            <>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Button
                    style={{ cursor: 'pointer', marginRight: 16 }}
                    onClick={() => this.setState({ reset: false })}
                  >
                    <Icon name="cancel" />
                    Avbryt
                  </Button>
                  <Button
                    color="red"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.setState({ reset: false }, () => this.fetchPackingList())}
                  >
                    <Icon name="undo" />
                    Återställ packningslista
                  </Button>
                </Menu.Item>
              </Menu.Menu>
            </>
          ) : deleteList ? (
            <>
              <Menu.Menu position="right">
                <Menu.Item>
                  <Button
                    style={{ cursor: 'pointer', marginRight: 16 }}
                    onClick={() => this.setState({ deleteList: false })}
                  >
                    <Icon name="cancel" />
                    Avbryt
                  </Button>
                  <Button
                    color="red"
                    style={{ cursor: 'pointer' }}
                    onClick={() => this.onDelete().then(this.fetchLists)}
                  >
                    <Icon name="trash" />
                    Radera packningslista
                  </Button>
                </Menu.Item>
              </Menu.Menu>
            </>
          ) : (
            <>
              <Menu.Item>
                <Button color="green" style={{ cursor: 'pointer' }} as={Link} to="/packing/new">
                  <Icon name="plus" />
                  Lista
                </Button>
              </Menu.Item>
              {this.props.match.params.id && (
                <Menu.Menu position="right">
                  {!!Number(this.props.match.params.id) && (
                    <>
                      <Popup
                        content="Du behöver spara listan för att kunna skriva ut den."
                        position="bottom center"
                        disabled={saved}
                        trigger={
                          <Menu.Item
                            target={saved ? '_blank' : ''}
                            href={saved ? `${API_ENDPOINT}/api/packing/${this.props.match.params.id}/print` : '#'}
                          >
                            <Button
                              color="black"
                              disabled={!saved}
                              style={{ cursor: 'pointer', marginTop: -12, marginBottom: -12 }}
                            >
                              <Icon name="print" />
                              Skriv ut
                            </Button>
                          </Menu.Item>
                        }
                      />
                    </>
                  )}
                  <Menu.Item>
                    {!!Number(this.props.match.params.id) && (
                      <>
                        <Button
                          color="red"
                          style={{ cursor: 'pointer', marginRight: 16 }}
                          onClick={() => this.setState({ deleteList: true })}
                        >
                          <Icon name="trash" />
                          Radera
                        </Button>
                        {!saved && (
                          <Button
                            style={{ cursor: 'pointer', marginRight: 16 }}
                            onClick={() => this.setState({ reset: true })}
                          >
                            <Icon name="undo" />
                            Återställ
                          </Button>
                        )}
                      </>
                    )}
                    <Button
                      color="green"
                      style={{ cursor: 'pointer' }}
                      loading={saving}
                      disabled={saved || !saveButtonEnabled}
                      onClick={() =>
                        this.setState({ saving: true }, () => {
                          this.onSave().then(() => {
                            this.setState({ saving: false, saved: true });
                            this.fetchLists();
                          });
                        })
                      }
                    >
                      <Icon name="save" />
                      {saving ? 'Sparar...' : saved ? 'Sparad!' : 'Spara'}
                    </Button>
                  </Menu.Item>
                </Menu.Menu>
              )}
            </>
          )}
        </Menu>
      </div>
    );
  }

  render() {
    const { packingLists, search } = this.state;

    return (
      <div style={{ display: 'flex' }}>
        {this.renderHeader()}
        <div style={{ width: 300, float: 'left', marginRight: 20 }}>
          <Menu vertical fluid style={{ fontSize: 15 }}>
            <Menu.Item>
              <Input
                icon={
                  <Icon
                    name="remove"
                    link
                    onClick={() => this.setState({ search: '' })}
                    style={{ visibility: search.length > 0 ? 'visible' : 'hidden ' }}
                  />
                }
                placeholder="Sök..."
                value={this.state.search}
                onChange={(input) => this.setState({ search: input.target.value })}
              />
            </Menu.Item>
            {packingLists
              .filter(({ title }) => search.length === 0 || title.toLowerCase().includes(search.toLowerCase()))
              .map(({ id, title }) => (
                <Menu.Item
                  key={`packing-list-${id}`}
                  as={Link}
                  to={`/packing/${id}`}
                  style={{ paddingTop: 12, paddingBottom: 12 }}
                  active={Number(this.props.match.params.id) === id}
                >
                  {title}
                  {Number(this.props.match.params.id) === id && <Icon name="edit" />}
                </Menu.Item>
              ))}
          </Menu>
        </div>
        <div
          style={{
            width: '100%',
            float: 'right',
          }}
        >
          {this.props.match.params.id && (
            <Route
              path="/packing/:id"
              children={
                <PackingList
                  id={this.props.match.params.id}
                  fetchLists={this.fetchLists}
                  saved={this.state.saved}
                  setSaved={this.setSaved}
                  setSaveButtonEnabled={this.setSaveButtonEnabled}
                  ref={this.packingList}
                />
              }
            />
          )}
        </div>
      </div>
    );
  }
}

export default connect(null, { navigate })(Packing);
