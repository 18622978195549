import React from 'react';
import { connect } from 'react-redux';
import { Icon, Modal, Header, Table, Button, Input, Popup } from 'semantic-ui-react';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { fetchChains } from '../../actions';

class Chains extends React.Component {
  state = {
    edit: 0,
    name: ''
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.close} size="small">
        <Header icon="chain" content="Kedjor" />
        <Modal.Content>
          <Table>
            <Table.Body>
              {this.props.chains.map(chain => (
                <Table.Row key={`chain${chain.id}`}>
                  <Table.Cell>
                    {this.state.edit === chain.id ? (
                      <Input
                        fluid
                        value={this.state.editName}
                        onChange={input => this.setState({ editName: input.target.value })}
                      />
                    ) : (
                      <>
                        <Header style={{ cursor: 'pointer' }} onClick={() => this.props.showChain(chain.id)}>
                          {chain.name}
                        </Header>
                        {chain.count} {Number(chain.count) === 1 ? 'kund' : 'kunder'}
                      </>
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {!this.state.edit ? (
                      <>
                        <Button
                          color="black"
                          circular
                          icon="edit"
                          onClick={() => this.setState({ editName: chain.name, edit: chain.id })}
                        />
                        {Number(chain.count) ? (
                          <Popup
                            position="left center"
                            trigger={
                              <Button
                                color="red"
                                style={{ opacity: 0.5, marginRight: 0, marginLeft: 12 }}
                                circular
                                icon="delete"
                              />
                            }
                            hideOnScroll
                            content="Kan inte ta bort kedjor som har kunder"
                            size="small"
                          />
                        ) : (
                          <Button
                            color="red"
                            style={{ marginRight: 0, marginLeft: 12 }}
                            circular
                            icon="delete"
                            onClick={() =>
                              axios
                                .delete(`${API_ENDPOINT}/api/chains/${chain.id}`)
                                .then(() => this.props.fetchChains())
                            }
                          />
                        )}
                      </>
                    ) : this.state.edit === chain.id ? (
                      <>
                        <Button color="grey" onClick={() => this.setState({ editName: '', edit: 0 })}>
                          <Icon name="cancel" /> Avbryt
                        </Button>
                        <Button
                          disabled={!this.state.editName}
                          color="green"
                          style={{ marginRight: 0, marginLeft: 12 }}
                          onClick={() =>
                            axios
                              .patch(`${API_ENDPOINT}/api/chains/${this.state.edit}`, { name: this.state.editName })
                              .then(() => {
                                this.setState({ edit: 0, editName: '' });
                                this.props.fetchChains();
                              })
                          }
                        >
                          <Icon name="save" /> Spara
                        </Button>
                      </>
                    ) : (
                      <></>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
              <Table.Row>
                <Table.Cell>
                  <Input
                    fluid
                    value={this.state.name}
                    onChange={input => this.setState({ name: input.target.value })}
                  />
                </Table.Cell>
                <Table.Cell>
                  <Button
                    color="green"
                    fluid
                    disabled={!this.state.name.length}
                    onClick={() =>
                      axios.post(`${API_ENDPOINT}/api/chains`, { name: this.state.name }).then(() => {
                        this.setState({ name: '' });
                        this.props.fetchChains();
                      })
                    }
                  >
                    Lägg till
                  </Button>
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>
    );
  }
}

const mapStateToProps = ({ app }) => {
  const { chains } = app;

  return { chains };
};

export default connect(
  mapStateToProps,
  { fetchChains }
)(Chains);
