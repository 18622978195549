import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import axios from "axios";
import { API_ENDPOINT } from "../../config";
import {
  Dropdown,
  Header,
  Icon,
  Checkbox,
  Menu,
  Button,
  Input,
  Table,
  ButtonGroup,
} from "semantic-ui-react";
import { months } from "../../utilities";
import _ from "lodash";
import moment from "moment";

import {
  navigate,
  setBookingListInterval,
  bookingListFilter,
} from "../../actions";

import "./bookingList.css";

import BookingRow from "./BookingRow";

class BookingList extends React.Component {
  state = {
    bookings: [],
    missionsOverdue: [],
    eventFilter: [],
    eventFilterMode: false,
    lastMonth: -1,
    loading: true,
    search: "",
    selected: [],
    showPreliminary: true,
    showSelected: false,
    loadingSelectedBookingsPDF: false,
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    !this.props.customer &&
      !this.props.staff &&
      this.props.navigate("bookings", "list");

    this.fetchBookings(
      this.props.start,
      this.props.end,
      this.props.customer || 0,
      this.props.staff || 0,
      this.props.desc
    );
  }

  componentWillReceiveProps(nextProps) {
    !this.props.customer &&
      !this.props.staff &&
      this.props.navigate("bookings", "list");

    if (this.props.customer || this.props.staff) {
      this.fetchBookings(
        nextProps.start,
        nextProps.end,
        nextProps.customer || 0,
        nextProps.staff || 0,
        true
      );
    }
  }

  fetchBookings(start, end, customer, staff, desc) {
    this.setState({ loading: true, eventFilter: [] });
    window.scrollTo(0, 0);

    const sorting = customer === 0 && !desc ? "asc" : "desc";

    axios
      .get(`${API_ENDPOINT}/api/bookings/missions/1`)
      .then(({ data: missionsOverdue }) => this.setState({ missionsOverdue }))
      .catch((error) => {
        console.error(error);
      });

    if (staff === 0) {
      axios
        .get(
          `${API_ENDPOINT}/api/bookings/${customer}/from/${
            customer === 0 ? start : "2000-01-01"
          }/to/${customer === 0 ? end : "3000-01-01"}/${sorting}`
        )
        .then(({ data: bookings }) => {
          this.setState({ bookings, loading: false });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      axios
        .get(`${API_ENDPOINT}/api/bookings/staff/${staff}`)
        .then(({ data }) => {
          this.setState({ bookings: data, loading: false });
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  setSelected(id) {
    const selected = this.state.selected.includes(id)
      ? this.state.selected.filter((s) => s !== id)
      : [...this.state.selected, id];

    if (this.state.showSelected) {
      this.setState({
        loadingSelectedBookingsPDF: true,
      });
      axios
        .post(`${API_ENDPOINT}/api/bookings/print`, this.state.selected)
        .then(() => this.setState({ loadingSelectedBookingsPDF: false }));
    }

    this.setState({ selected });
  }

  renderHeader() {
    const eventSet = new Set();
    this.state.bookings
      .filter(({ event }) => event !== 112)
      .forEach((booking) => eventSet.add(booking.eventname));
    const eventList = Array(...eventSet).sort();

    return (
      <div style={{ top: 51, position: "fixed", zIndex: 10, width: "100%" }}>
        <Menu style={{ background: "#fff", marginLeft: -22 }}>
          {this.props.isManager && (
            <Menu.Item>
              {this.props.isAdmin && (
                <Button
                  color="green"
                  style={{ cursor: "pointer" }}
                  as={Link}
                  to="/bookings/new"
                >
                  <Icon name="plus" />
                  Bokning
                </Button>
              )}
              <Button
                color="green"
                style={{ cursor: "pointer", marginLeft: 17 }}
                as={Link}
                to="/bookings/mission/new"
              >
                <Icon name="plus" />
                Uppdrag
              </Button>
            </Menu.Item>
          )}
          <Dropdown
            text={`${
              this.props.activeInterval === "upcoming"
                ? "Kommande"
                : this.props.activeInterval === "lastQuarter"
                ? "Tidigare"
                : this.props.activeInterval === "old"
                ? "Alla tidigare"
                : this.props.activeInterval === "thisMonth"
                ? `${months[moment().format("M") - 1]} ${moment().format(
                    "YYYY"
                  )}`
                : this.props.activeInterval === "lastMonth"
                ? `${
                    months[moment().subtract(1, "month").format("M") - 1]
                  } ${moment().subtract(1, "month").format("YYYY")}`
                : this.props.activeInterval
            }`}
            pointing
            className="item"
          >
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  this.props.setBookingListInterval(
                    moment().format("YYYY-MM-DD"),
                    "3000-01-01",
                    "upcoming"
                  );
                  this.fetchBookings(
                    moment().format("YYYY-MM-DD"),
                    "3000-01-01",
                    0,
                    0
                  );
                }}
              >
                Kommande
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  const from = moment()
                    .subtract(3, "months")
                    .format("YYYY-MM-DD");
                  const to = moment().format("YYYY-MM-DD");
                  this.props.setBookingListInterval(
                    from,
                    to,
                    "lastQuarter",
                    true
                  );
                  this.fetchBookings(from, to, 0, 0, true);
                }}
              >
                Tidigare
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  const lastMonthStart = moment()
                    .startOf("month")
                    .format("YYYY-MM-DD");
                  const lastMonthEnd = moment()
                    .endOf("month")
                    .format("YYYY-MM-DD");
                  this.props.setBookingListInterval(
                    lastMonthStart,
                    lastMonthEnd,
                    "thisMonth"
                  );
                  this.fetchBookings(lastMonthStart, lastMonthEnd, 0, 0);
                }}
              >
                {months[moment().format("M") - 1]} {moment().format("YYYY")}
              </Dropdown.Item>
              <Dropdown.Item
                onClick={() => {
                  const lastMonthStart = moment()
                    .subtract(1, "month")
                    .startOf("month")
                    .format("YYYY-MM-DD");
                  const lastMonthEnd = moment()
                    .subtract(1, "month")
                    .endOf("month")
                    .format("YYYY-MM-DD");
                  this.props.setBookingListInterval(
                    lastMonthStart,
                    lastMonthEnd,
                    "lastMonth"
                  );
                  this.fetchBookings(lastMonthStart, lastMonthEnd, 0, 0);
                }}
              >
                {months[moment().subtract(1, "month").format("M") - 1]}{" "}
                {moment().subtract(1, "month").format("YYYY")}
              </Dropdown.Item>
              <Dropdown.Divider />
              {this.renderYears()}
              <Dropdown.Divider />
              <Dropdown.Item
                onClick={() => {
                  const from = moment()
                    .subtract(12, "months")
                    .format("YYYY-MM-DD");
                  const to = moment().format("YYYY-MM-DD");
                  this.props.setBookingListInterval(from, to, "old");
                  this.fetchBookings(from, to, 0, 0);
                }}
              >
                Alla tidigare
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
          <Menu.Item style={{ padding: "0 0 0 8px" }}>
            <Button.Group style={{ marginRight: 10 }}>
              <Button
                style={{
                  background: this.props.eventsVisible ? "#333" : "#ddd",
                  color: "#fff",
                }}
                onClick={() => this.props.bookingListFilter("eventsVisible")}
              >
                Event
              </Button>
              <Button
                style={{
                  background: this.props.missionsOpen ? "#333" : "#ddd",
                  color: "#fff",
                }}
                onClick={() => this.props.bookingListFilter("missionsOpen")}
              >
                Uppdrag
              </Button>
              <Button
                style={{
                  background: this.props.missionsCompleted ? "#333" : "#ddd",
                  color: "#fff",
                }}
                onClick={() =>
                  this.props.bookingListFilter("missionsCompleted")
                }
              >
                Slutförda
              </Button>
            </Button.Group>
          </Menu.Item>
          {this.props.eventsVisible ? (
            <Menu.Item style={{ width: 400, padding: 4 }}>
              <Button.Group style={{ marginRight: 10 }}>
                <Button
                  style={{
                    background: "#fff",
                    border: !this.state.eventFilterMode
                      ? "1px solid #333"
                      : "1px solid #ddd",
                  }}
                  onClick={() =>
                    this.setState({
                      eventFilterMode: false,
                    })
                  }
                >
                  Visa
                </Button>
                <Button
                  style={{
                    background: "#fff",
                    border: this.state.eventFilterMode
                      ? "1px solid #333"
                      : "1px solid #ddd",
                  }}
                  onClick={() =>
                    this.setState({
                      eventFilterMode: true,
                    })
                  }
                >
                  Dölj
                </Button>
              </Button.Group>
              <Dropdown
                placeholder={
                  this.state.eventFilterMode
                    ? "Döljer inga event"
                    : "Visar alla event"
                }
                fluid
                multiple
                selection
                search
                noResultsMessage="Inga matchande event..."
                value={this.state.eventFilter}
                options={eventList.reduce((acc, next) => {
                  return [
                    ...acc,
                    {
                      key: next,
                      text: next,
                      value: next,
                    },
                  ];
                }, [])}
                onChange={(_, data) =>
                  this.setState({ eventFilter: data.value })
                }
              />
            </Menu.Item>
          ) : null}
          <Button
            style={{
              background: "#fff",
              margin: 4,
              border: 0,
            }}
            onClick={() =>
              this.setState({
                showPreliminary: !this.state.showPreliminary,
              })
            }
          >
            <Checkbox
              checked={this.state.showPreliminary}
              style={{ marginBottom: -4, marginRight: 4 }}
              onChange={() => {}}
            />
            Visa preliminära
          </Button>
          <Menu.Menu position="right">
            <Menu.Item>
              <Button
                color="black"
                disabled={
                  this.state.selected.length === 0 && !this.state.showSelected
                }
                onClick={() => {
                  axios
                    .post(
                      `${API_ENDPOINT}/api/bookings/print`,
                      this.state.selected
                    )
                    .then(() =>
                      this.setState({ loadingSelectedBookingsPDF: false })
                    );
                  this.setState({
                    showSelected: !this.state.showSelected,
                    selected: this.state.showSelected
                      ? []
                      : this.state.selected,
                    loadingSelectedBookingsPDF: true,
                  });
                }}
              >
                {this.state.showSelected ? "Återställ" : "Urval"}
              </Button>
              {this.state.showSelected && (
                <Button
                  color="black"
                  loading={this.state.loadingSelectedBookingsPDF}
                  style={{ marginLeft: 16 }}
                  as="a"
                  href={`${API_ENDPOINT}/api/print/selectedbookings`}
                  target="_blank"
                >
                  Skriv ut
                </Button>
              )}
            </Menu.Item>
            <Menu.Item>
              <Input
                icon="search"
                style={{ marginRight: 22 }}
                placeholder="Sök bokning..."
                value={this.state.search}
                onChange={(input) => {
                  window.scrollTo(0, 0);
                  this.setState({ search: input.target.value });
                }}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }

  renderOverdueMissions() {
    const { missionsOverdue } = this.state;

    return (
      <Table striped definition stackable={false}>
        <Table.Body>
          {missionsOverdue.map((booking, index) => {
            return (
              <BookingRow
                key={`mission${booking.id}`}
                booking={booking}
                selected={this.state.selected.includes(booking.id)}
                setSelected={this.setSelected.bind(this)}
                compact={false}
                index={index}
                isAdmin={this.props.isAdmin}
                deleteBooking={() => {
                  this.setState({
                    missions: this.state.bookings.filter(
                      (b) => b.id !== booking.id
                    ),
                  });
                }}
              />
            );
          })}
        </Table.Body>
      </Table>
    );
  }

  renderBookingList() {
    const {
      loading,
      showSelected,
      eventFilter,
      eventFilterMode,
      selected,
      search,
    } = this.state;

    const { eventsVisible, missionsOpen, missionsCompleted } = this.props;

    if (loading) {
      return !this.props.customer && !this.props.staff && <div />;
    }

    const bookings = this.state.bookings.filter((booking) => {
      return (
        (search.length < 2 ||
          booking.eventname.toLowerCase().includes(search.toLowerCase()) ||
          booking.custom_name.toLowerCase().includes(search.toLowerCase())) &&
        (!showSelected || selected.includes(booking.id)) &&
        (!eventFilter.length ||
          (!eventFilterMode && eventFilter.includes(booking.eventname)) ||
          (eventFilterMode && !eventFilter.includes(booking.eventname)))
      );
    });

    if (bookings.length === 0) {
      return (
        <Header textAlign="center">
          {this.props.customer || this.props.staff
            ? "Inga bokningar ännu."
            : "Inga bokningar matchade sökningen..."}
        </Header>
      );
    }

    if (this.props.activeInterval === "old") {
      bookings.reverse();
    }

    const compact = this.props.customer || this.props.staff;
    let rowIndex = 0;

    return (
      <div
        style={{ marginTop: this.props.customer || this.props.staff ? 0 : 20 }}
      >
        <Table striped definition stackable={false}>
          <Table.Body>
            {bookings
              .filter(
                ({ confirmed, event }) =>
                  (event !== 112 && eventsVisible) ||
                  (event === 112 && missionsOpen && !confirmed) ||
                  (event === 112 && missionsCompleted && confirmed)
              )
              .filter(
                ({ confirmed }) =>
                  confirmed || (!confirmed && this.state.showPreliminary)
              )
              .map((booking, index) => {
                const thisMonth = moment(booking.startdate).format("M");
                const thisYear = moment(booking.startdate).format("Y");
                if (
                  index === 0 ||
                  moment(bookings[index - 1].startdate).format("M") !==
                    thisMonth ||
                  moment(bookings[index - 1].startdate).format("Y") !== thisYear
                ) {
                  const header = `${
                    months[moment(booking.startdate).format("M") - 1]
                  } ${moment(booking.startdate).format("YYYY")}`;
                  return (
                    <React.Fragment key={`bookingHeader${booking.id}`}>
                      <Table.Row>
                        <Table.Cell
                          style={{ backgroundColor: "#333" }}
                          colSpan={9}
                          textAlign="center"
                        >
                          <Header style={{ color: "#fff" }}>
                            {header.substring(0, 1).toUpperCase()}
                            {header.substring(1)}
                          </Header>
                        </Table.Cell>
                      </Table.Row>
                      <BookingRow
                        key={`booking${booking.id}`}
                        booking={booking}
                        selected={this.state.selected.includes(booking.id)}
                        setSelected={this.setSelected.bind(this)}
                        compact={compact}
                        index={(rowIndex += 2)}
                        isAdmin={this.props.isAdmin}
                        bookingListBackground={this.props.bookingListBackground}
                        deleteBooking={() => {
                          this.setState({
                            bookings: this.state.bookings.filter(
                              (b) => b.id !== booking.id
                            ),
                          });
                        }}
                      />
                    </React.Fragment>
                  );
                } else {
                  return (
                    <BookingRow
                      key={`booking${booking.id}`}
                      booking={booking}
                      selected={this.state.selected.includes(booking.id)}
                      setSelected={this.setSelected.bind(this)}
                      compact={compact}
                      index={(rowIndex += 1)}
                      isAdmin={this.props.isAdmin}
                      bookingListBackground={this.props.bookingListBackground}
                      deleteBooking={() => {
                        this.setState({
                          bookings: this.state.bookings.filter(
                            (b) => b.id !== booking.id
                          ),
                        });
                      }}
                    />
                  );
                }
              })}
          </Table.Body>
        </Table>
        {this.props.desc && (
          <Button
            color="green"
            fluid
            onClick={() => {
              this.props.setBookingListInterval(
                moment().subtract(12, "month").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
                "old",
                true
              );
              this.fetchBookings(
                moment().subtract(12, "month").format("YYYY-MM-DD"),
                moment().format("YYYY-MM-DD"),
                0,
                0,
                true
              );
            }}
          >
            Hämta fler...
          </Button>
        )}
      </div>
    );
  }

  renderYears() {
    const years = _.range(2009, Number(moment().format("YYYY")) + 2).reverse();

    return years.map((year) => (
      <Dropdown.Item
        key={`year${year}`}
        onClick={() => {
          this.props.setBookingListInterval(
            `${year}-01-01`,
            `${year}-12-31`,
            year
          );
          this.fetchBookings(`${year}-01-01`, `${year}-12-31`, 0, 0);
        }}
      >
        {year}
      </Dropdown.Item>
    ));
  }

  render() {
    return (
      <div>
        {!this.props.customer && !this.props.staff && this.renderHeader()}
        {!this.props.customer &&
        this.props.missionsOpen &&
        this.props.activeInterval === "upcoming" &&
        this.state.missionsOverdue.length
          ? this.renderOverdueMissions()
          : null}
        {this.renderBookingList()}
      </div>
    );
  }
}

const mapStateToProps = ({ app, user }) => {
  return {
    start: app.start,
    end: app.end,
    activeInterval: app.activeInterval,
    isAdmin: user.accessLevel === 1,
    isManager: user.accessLevel <= 2,
    bookingListBackground: app.bookingListBackground,
    desc: app.desc,
    eventsVisible: app.eventsVisible,
    missionsOpen: app.missionsOpen,
    missionsCompleted: app.missionsCompleted,
  };
};

export default connect(mapStateToProps, {
  navigate,
  setBookingListInterval,
  bookingListFilter,
})(BookingList);
