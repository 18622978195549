import React from 'react';
import axios from 'axios';
import { Button, Container, Divider, Form, Grid, Header, Image, Loader, Table, TextArea } from 'semantic-ui-react';
import moment from 'moment';
import { months, days } from '../../utilities';

import { formatDate } from '../../utilities';
import { API_ENDPOINT, WEB_ENDPOINT } from '../../config';

class Mail extends React.Component {
  state = {
    id: 0,
    name: '',
    customName: '',
    customer: '',
    date: '',
    startdate: '',
    enddate: '',
    duration: '',
    hours: '',
    customerContact: '',
    location: '',
    notesFromCustomer: '',
    customerFollowUp: '',
    loading: true,
    saved: true,
    initialLoad: true
  };

  componentDidMount() {
    const { token } = this.props.match.params;
    axios.get(`${API_ENDPOINT}/api/mail/${token}`).then(({ data }) => {
      this.setState({ ...data, loading: false });
    });
  }

  save() {
    const { id, customerContact, location, notesFromCustomer, customerFollowUp } = this.state;

    axios
      .patch(`${API_ENDPOINT}/api/mail/${id}`, {
        customerContact,
        location,
        notesFromCustomer,
        customerFollowUp
      })
      .then(() => {
        this.setState({ saved: true, initialLoad: false });
      });
  }

  renderHours() {
    const hours = [];
    for (let day = 0; day < this.state.hours.length; day += 11) {
      hours.push(this.state.hours.substring(day, day + 11));
    }

    return hours.map((day, index) => {
      const currentDay = moment(this.state.startdate).add(index, 'days');
      return (
        <Table.Row key={`booking${this.state.id}hours${index}`}>
          <Table.Cell style={{ width: 30 }}>
            <span style={{ fontWeight: 'bold' }}>{days[currentDay.format('d')]}</span>
          </Table.Cell>
          <Table.Cell style={{ width: 30 }}>
            {currentDay.format('D')} {months[currentDay.format('M') - 1].toLowerCase()}
          </Table.Cell>
          <Table.Cell singleLine style={{ width: 40 }}>
            {day.replace('-', ' – ')}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader size="huge" />;
    }

    return (
      <Container
        style={{
          background: '#fff',
          marginTop: 60,
          marginBottom: 40,
          padding: 20,
          borderRadius: 3
        }}
      >
        <style>{`
      html, body {
        background-color: #111 !important;
      }
      p {
        align-content: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
    }
    `}</style>
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column verticalAlign="middle">
              <Header as="h1">Bokningsbekräftelse</Header>
            </Grid.Column>
            <Grid.Column textAlign="right">
              <Image
                src={`${WEB_ENDPOINT}/logo_black.png`}
                size="medium"
                style={{ float: 'right', marginBottom: 30, marginTop: 24 }}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Divider />
        <Grid columns={2} stackable>
          <Grid.Row>
            <Grid.Column>
              <Header as="h2">{this.state.customName.length > 0 ? this.state.customName : this.state.name}</Header>
              <Header as="h3" style={{ marginTop: 5 }}>
                {this.state.customer} {formatDate(this.state.startdate, this.state.enddate, this.state.duration)}
              </Header>
              <Table unstackable singleLine compact="very" striped={this.state.duration > 2} style={{ border: 0 }}>
                <Table.Body>{this.renderHours()}</Table.Body>
              </Table>
              <div style={{ marginTop: 20, marginBottom: 20 }}>
                <p>
                  Snart kommer vi med ovanstående event till er. Vänligen fyll i eller ändra uppgifterna i fälten nedan.
                  Informationen skickas automatiskt ut till vår personal och du kan ändra eller komplettera uppgifterna
                  ända fram till dagen före eventet.
                </p>
                <p>
                  Behöver du göra ändringar efter detta går det bra att kontakta oss direkt på tel 0707-38 23 23 eller
                  0700-90 30 35. Hälsningar Mathias
                </p>
              </div>
              <Form onSubmit={this.save.bind(this)}>
                <Header>Vakt / Kontaktperson</Header>
                <TextArea
                  value={this.state.customerContact}
                  onChange={input =>
                    this.setState({ customerContact: input.target.value, saved: false, initialLoad: false })
                  }
                  rows={2}
                  autoHeight
                />
                <Header>Eventplats</Header>
                <TextArea
                  value={this.state.location}
                  onChange={input => this.setState({ location: input.target.value, saved: false, initialLoad: false })}
                  rows={2}
                  autoHeight
                />
                <Header>Instruktioner</Header>
                <TextArea
                  value={this.state.notesFromCustomer}
                  onChange={input =>
                    this.setState({ notesFromCustomer: input.target.value, saved: false, initialLoad: false })
                  }
                  rows={4}
                  autoHeight
                  style={{ marginBottom: 20 }}
                />
                <Button disabled={this.state.saved} fluid color="green">
                  {this.state.saved && !this.state.initialLoad ? 'Sparad!' : 'Spara'}
                </Button>
              </Form>
            </Grid.Column>
            <Grid.Column>
              <p>
                Annonsmaterial kan laddas ner via vår hemsida:
                <br />
                <a href="http://www.magnetcompany.se/ladda-ner.html" target="_blank" rel="noopener noreferrer">
                  www.magnetcompany.se/ladda-ner.html
                </a>
              </p>
              <p>Klicka på "{this.state.name}".</p>
              <br />
              <br />
              <Button
                as="a"
                href="http://www.magnetcompany.se/avbokningspolicy.html"
                basic
                color="red"
                style={{ float: 'right' }}
              >
                Avbokningspolicy
              </Button>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Container>
    );
  }
}

export default Mail;
