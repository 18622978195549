import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import axios from 'axios';
import { Input, Menu, Dropdown, Button, Icon, Form } from 'semantic-ui-react';
import { navigate } from '../../actions';

import { months } from '../../utilities';
import { API_ENDPOINT } from '../../config';

import EventReport from './EventReport';
import StaffReport from './StaffReport';
import StaffReportListed from './StaffReportListed';

class Reports extends React.Component {
  state = {
    type: 'event',
    period: 'custom',
    from: moment()
      .subtract(1, 'month')
      .startOf('month')
      .format('YYYY-MM-DD'),
    to: moment()
      .subtract(1, 'month')
      .endOf('month')
      .format('YYYY-MM-DD'),
    workingStaff: [],
    events: [],
    loadingReport: false,
    search: '',
    staffSelected: 'all-listed',
    staffWorking: [],
    staffEvents: {},
    fetchButtonActive: false
  };

  componentDidMount() {
    this.props.navigate('reports');

    window.scrollTo(0, 0);

    const from = moment()
      .subtract(1, 'month')
      .date(1);
    const to = moment()
      .subtract(1, 'month')
      .date(from.daysInMonth());

    this.setState(
      {
        from: from.format('YYYY-MM-DD'),
        to: to.format('YYYY-MM-DD')
      },
      this.fetchEventReport
    );
  }

  componentWillReceiveProps() {
    this.props.navigate('reports');
  }

  fetchEventReport(from = this.state.from, to = this.state.to) {
    this.setState({ loadingReport: true });
    axios.get(`${API_ENDPOINT}/api/reports/${this.state.type}/from/${from}/to/${to}`).then(({ data }) => {
      const events = data;

      const staffEvents = {};
      events.forEach(event => {
        const { id, startdate, enddate, duration, name, customName, customerName, staff, travelCompensation } = event;

        staff.forEach(staff => {
          if (!staffEvents[staff.id]) {
            staffEvents[staff.id] = [];
          }
          staffEvents[staff.id].push({
            id,
            startdate,
            enddate,
            duration,
            name,
            customName,
            customerName,
            travelCompensation,
            staff
          });
        });
      });

      this.setState({
        events,
        staffEvents,
        loadingReport: false,
        fetchButtonActive: false
      });
    });
  }

  fetchStaffWorking(period = this.state.period) {
    return new Promise(resolve => {
      axios
        .get(
          `${API_ENDPOINT}/api/staff/working/${moment(period, 'YYYY-M')
            .startOf('month')
            .format('YYYY-MM-DD')}/${moment(period, 'YYYY-M')
            .endOf('month')
            .format('YYYY-MM-DD')}`
        )
        .then(({ data }) => {
          this.setState({ staffWorking: data });
          resolve();
        });
    });
  }

  renderInterval() {
    const start = { year: 2009, month: 1 };

    const options = [];

    let year = Number(moment().format('YYYY'));
    let month = Number(moment().format('M'));

    while (!(year === start.year && month === start.month)) {
      options.push({
        key: `${year}-${month}`,
        value: `${year}-${month}`,
        text: `${months[month - 1]} ${year}`
      });

      if (month === 1) {
        year -= 1;
        month = 12;
      } else {
        month -= 1;
      }
    }

    if (this.state.type === 'event') {
      options.unshift({
        key: 'custom',
        value: 'custom',
        text: 'Välj period',
        disabled: true
      });
    }

    return (
      <Dropdown
        scrolling
        selection
        options={options}
        value={this.state.period}
        onChange={(_, data) => {
          const [year, month] = data.value.split('-');

          const from = moment()
            .year(year)
            .month(month - 1)
            .date(1);
          const to = moment()
            .year(year)
            .month(month - 1)
            .date(from.daysInMonth());

          this.fetchEventReport(from.format('YYYY-MM-DD'), to.format('YYYY-MM-DD'));

          this.setState({
            period: data.value,
            from: from.format('YYYY-MM-DD'),
            to: to.format('YYYY-MM-DD'),
            staffSelected: this.state.staffSelected === 'all-listed' ? 'all-listed' : 'all-sorted',
            fetchButtonActive: false
          });
          if (this.state.type === 'staff') {
            this.fetchStaffWorking(data.value).then(() => this.fetchEventReport());
          }
        }}
      />
    );
  }

  renderEventReportMenu() {
    const { from, to } = this.state;

    return (
      <>
        <Menu.Item>
          <Form.Input
            style={{ width: 110 }}
            value={this.state.from}
            error={from.match(/^\d{4}-\d{2}-\d{2}$/) === null}
            onChange={input => this.setState({ period: 'custom', from: input.target.value, fetchButtonActive: true })}
          />
          <Icon name="minus" style={{ margin: 5 }} />
          <Form.Input
            style={{ width: 110 }}
            value={this.state.to}
            error={to.match(/^\d{4}-\d{2}-\d{2}$/) === null}
            onChange={input => this.setState({ period: 'custom', to: input.target.value, fetchButtonActive: true })}
          />
          <Button
            color="black"
            onClick={() => {
              this.fetchEventReport(from, to);
            }}
            style={{ marginLeft: 10 }}
            disabled={this.state.loadingReport || !this.state.fetchButtonActive}
            loading={this.state.loadingReport}
          >
            Hämta
          </Button>
        </Menu.Item>
      </>
    );
  }

  renderStaffReportMenu() {
    return (
      <Menu.Item>
        <Dropdown
          selection
          scrolling
          className="staff"
          style={{ width: 240 }}
          options={[
            {
              key: 'all-listed',
              value: 'all-listed',
              icon: 'list',
              text: 'All personal - Löpande'
            },
            {
              key: 'all-sorted',
              value: 'all-sorted',
              icon: 'address card outline',
              text: 'All personal - Sorterat'
            },
            ...this.state.staffWorking.reduce(
              (acc, next) => [
                ...acc,
                {
                  key: `staffworking-${next.id}`,
                  value: String(next.id),
                  icon: 'user',
                  text: `${next.name}`
                }
              ],
              []
            )
          ]}
          value={this.state.staffSelected}
          onChange={(event, data) => {
            this.setState({
              staffSelected: data.value
            });
          }}
        />
      </Menu.Item>
    );
  }

  renderReport() {
    const { type, staffSelected } = this.state;

    if (type === 'event') {
      return (
        <EventReport
          events={this.state.events.filter(event => {
            const search = this.state.search.toLowerCase();

            if (search.length < 2) {
              return true;
            }

            return (
              event.name.toLowerCase().includes(search) ||
              event.customName.toLowerCase().includes(search) ||
              event.customerName.toLowerCase().includes(search)
            );
          })}
          loading={this.state.loadingReport}
        />
      );
    } else if (staffSelected === 'all-listed') {
      return (
        <StaffReportListed
          events={this.state.events}
          staffEvents={this.state.staffEvents}
          loading={this.state.loadingReport}
        />
      );
    } else {
      return (
        <StaffReport
          events={this.state.events}
          staffEvents={this.state.staffEvents}
          staff={staffSelected}
          loading={this.state.loadingReport}
          from={this.state.from}
          to={this.state.to}
        />
      );
    }
  }

  render() {
    return (
      <div>
        <div
          style={{
            top: 51,
            position: 'fixed',
            zIndex: 10,
            width: '100%'
          }}
        >
          <Menu
            style={{
              background: '#fff',
              marginLeft: -22,
              paddingRight: 22,
              height: 50
            }}
          >
            <Menu.Item>
              <Button.Group>
                <Button
                  style={{ opacity: this.state.type === 'staff' ? 0.6 : 1 }}
                  color={this.state.type === 'event' ? 'black' : 'grey'}
                  onClick={() => this.setState({ type: 'event' })}
                >
                  Eventrapport
                </Button>
                <Button
                  style={{ opacity: this.state.type === 'event' ? 0.6 : 1 }}
                  color={this.state.type === 'staff' ? 'black' : 'grey'}
                  onClick={() => {
                    this.setState({
                      type: 'staff',
                      period: moment()
                        .subtract(1, 'month')
                        .format('YYYY-M')
                    });
                    this.fetchStaffWorking(
                      moment()
                        .subtract(1, 'month')
                        .format('YYYY-M')
                    );
                  }}
                >
                  Personalrapport
                </Button>
              </Button.Group>
            </Menu.Item>
            <Menu.Item>{this.renderInterval()}</Menu.Item>
            {this.state.type === 'event' ? this.renderEventReportMenu() : this.renderStaffReportMenu()}
            <Menu.Menu position="right">
              <Menu.Item>
                {this.state.type === 'event' ? (
                  <Input
                    icon="search"
                    placeholder="Filtrera..."
                    value={this.state.search}
                    onChange={input => this.setState({ search: input.target.value })}
                  />
                ) : (
                  <div />
                )}
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
        <div style={{ width: '100%' }}>{this.renderReport()}</div>
      </div>
    );
  }
}

const mapStateToProps = ({ app }) => {
  const { staff } = app;

  return { staff };
};

export default connect(
  mapStateToProps,
  {
    navigate
  }
)(Reports);
