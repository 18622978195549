import { LOGIN, LOGOUT } from '../actions/types';

const INITIAL_STATE = {
  loading: true,
  loggedIn: false,
  email: '',
  name: '',
  accessLevel: 0,
  error: ''
};

export default function user(state = INITIAL_STATE, action) {
  switch (action.type) {
    case LOGIN:
      if (!action.payload.error) {
        const { email, name, accessLevel } = action.payload;
        return {
          ...state,
          loading: false,
          loggedIn: true,
          email,
          name,
          accessLevel
        };
      } else {
        return {
          ...state,
          loading: false,
          loggedIn: false,
          error: action.payload.error
        };
      }
    case LOGOUT:
      return {
        ...INITIAL_STATE,
        loading: false
      };
    default:
      return state;
  }
}
