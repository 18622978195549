import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import moment from 'moment';
import { API_ENDPOINT } from '../../config';
import { formatPrice } from '../../utilities';
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Table,
  TextArea
} from 'semantic-ui-react';
import { fetchTickets } from '../../actions';

const INITIAL_STATE = {
  open: false,
  email: '',
  saved: true,
  notes: '',
  pickedUp: false,
  emailSent: false,
  pickedUpTime: null,
  sent: false,
  lap: 0,
  startgroup: 'A',
  shippingTime: null,
  sendReceiptConfirm: false,
  showSummary: false,
  refund: false,
  refundAmount: '',
  fullRefund: false
};

class ViewTicket extends React.Component {
  state = INITIAL_STATE;

  componentWillReceiveProps(nextProps) {
    if (!this.state.open && nextProps.ticket) {
      this.setState({
        email: nextProps.ticket.email,
        notes: nextProps.ticket.notes,
        pickedUp: nextProps.ticket.picked_up,
        pickedUpTime: nextProps.ticket.picked_up_time,
        sent: nextProps.ticket.sent,
        lap: nextProps.ticket.lap,
        startgroup: nextProps.ticket.startgroup,
        shippingTime: nextProps.ticket.shipping_time,
        refund: nextProps.ticket.refund,
        refundAmount: nextProps.ticket.refund_amount.toString(),
        fullRefund: nextProps.ticket.full_refund,
        emailSent: false
      });
    }
  }

  save() {
    const { id } = this.props.ticket;
    const {
      lap,
      startgroup,
      email,
      notes,
      pickedUp,
      pickedUpTime,
      sent,
      shippingTime,
      refund,
      fullRefund,
      refundAmount
    } = this.state;

    axios
      .patch(`${API_ENDPOINT}/api/tickets/editticket`, {
        id,
        lap,
        startgroup,
        email,
        notes,
        pickedUp,
        pickedUpTime: pickedUp && pickedUpTime ? moment(pickedUpTime).format('YYYY-MM-DD HH:mm:ss') : null,
        sent,
        shippingTime: sent && shippingTime ? moment(shippingTime).format('YYYY-MM-DD HH:mm:ss') : null,
        refund,
        fullRefund,
        refundAmount
      })
      .then(() => {
        this.props.fetchTickets();
        this.setState(INITIAL_STATE);
        this.props.close();
      });
  }

  renderParticipants() {
    return this.props.ticket.participants.map((participant, index) => {
      return (
        <Card fluid style={{ padding: 12 }} key={`participant${index}`}>
          <Grid>
            <Grid.Column width={1}>
              <Header as="h3">{index + 1}</Header>
            </Grid.Column>
            <Grid.Column width={3}>
              {participant.firstname} {participant.lastname}
            </Grid.Column>
            <Grid.Column width={3}>{participant.age} år</Grid.Column>
            <Grid.Column width={3}>{participant.phone ? `Tel: ${participant.phone}` : ''}</Grid.Column>
            <Grid.Column width={3}>
              {participant.tshirt === '' ? '' : `T-shirt ${participant.tshirt.toUpperCase()}`}
            </Grid.Column>
          </Grid>
        </Card>
      );
    });
  }

  renderSummary() {
    const {
      code,
      ticket_price: ticketPrice,
      registration_fee: registrationFee,
      tshirt_price: tshirtPrice,
      shipping_price: shippingPrice,
      shipping,
      total_price: totalPrice,
      token,
      created,
      stripe_charge_id: stripeChargeId,
      stripe_source: stripeSource,
      client_secret: clientSecret
    } = this.props.ticket;

    const participants = this.props.ticket.participants.length;
    const tshirts = this.props.ticket.participants.reduce((acc, next) => acc + (next.tshirt ? 1 : 0), 0);

    return (
      <Card fluid>
        <Card.Content>
          <Header>Kvitto</Header>
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>Biljetter</Table.Cell>
                <Table.Cell textAlign="right">{formatPrice(ticketPrice)} / st</Table.Cell>
                <Table.Cell textAlign="right">{participants} st</Table.Cell>
                <Table.Cell textAlign="right">{formatPrice(ticketPrice * participants)}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>Registreringsavgift</Table.Cell>
                <Table.Cell textAlign="right">{formatPrice(registrationFee)} / st</Table.Cell>
                <Table.Cell textAlign="right">{participants} st</Table.Cell>
                <Table.Cell textAlign="right">{formatPrice(registrationFee * participants)}</Table.Cell>
              </Table.Row>
              {shipping && (
                <Table.Row>
                  <Table.Cell style={{ fontWeight: 'bold' }}>Hemleverans</Table.Cell>
                  <Table.Cell textAlign="right">{formatPrice(shippingPrice)} / st</Table.Cell>
                  <Table.Cell textAlign="right">1 st</Table.Cell>
                  <Table.Cell textAlign="right">{formatPrice(shippingPrice)}</Table.Cell>
                </Table.Row>
              )}
              {tshirts > 0 && (
                <Table.Row>
                  <Table.Cell style={{ fontWeight: 'bold' }}>T-shirts</Table.Cell>
                  <Table.Cell textAlign="right">{formatPrice(tshirtPrice)} / st</Table.Cell>
                  <Table.Cell textAlign="right">{tshirts} st</Table.Cell>
                  <Table.Cell textAlign="right">{formatPrice(tshirtPrice * tshirts)}</Table.Cell>
                </Table.Row>
              )}
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>Summa</Table.Cell>
                <Table.Cell textAlign="right" />
                <Table.Cell textAlign="right" />
                <Table.Cell style={{ fontWeight: 'bold' }} textAlign="right">
                  {formatPrice(totalPrice)}
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
          <Divider />
          <Header>Övrigt</Header>
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>Datum</Table.Cell>
                <Table.Cell textAlign="center">{moment(created).format('YYYY-MM-DD HH:mm:ss')}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>Rabattkod</Table.Cell>
                <Table.Cell textAlign="center">{code ? code : '-'}</Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell style={{ fontWeight: 'bold' }}>Token</Table.Cell>
                <Table.Cell textAlign="center">{token}</Table.Cell>
              </Table.Row>
              {stripeChargeId ? (
                <>
                  <Table.Row>
                    <Table.Cell style={{ fontWeight: 'bold' }}>Betalning</Table.Cell>
                    <Table.Cell textAlign="center">Stripe</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ fontWeight: 'bold' }}>Stripe Charge ID</Table.Cell>
                    <Table.Cell textAlign="center">{stripeChargeId}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ fontWeight: 'bold' }}>Stripe Source</Table.Cell>
                    <Table.Cell textAlign="center">{stripeSource}</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell style={{ fontWeight: 'bold' }}>Stripe Client Secret</Table.Cell>
                    <Table.Cell textAlign="center">{clientSecret}</Table.Cell>
                  </Table.Row>
                </>
              ) : (
                <Table.Row>
                  <Table.Cell style={{ fontWeight: 'bold' }}>Betalning</Table.Cell>
                  <Table.Cell textAlign="center">Manuell</Table.Cell>
                </Table.Row>
              )}
            </Table.Body>
          </Table>
        </Card.Content>
      </Card>
    );
  }

  render() {
    if (!this.props.ticket) {
      return <div />;
    }

    return (
      <Modal
        open={this.props.open}
        onClose={() => {
          this.setState(INITIAL_STATE);
          this.props.close();
        }}
        closeOnDimmerClick={this.state.saved}
        size="large"
      >
        <Modal.Header>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Icon name="ticket" /> T0{this.props.ticket.ticket}
              </Grid.Column>
              <Grid.Column width={8} style={{ textAlign: 'right' }}>
                Grupp {this.state.startgroup}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={event => event.preventDefault()}>
            <Grid>
              <Grid.Row>
                <Grid.Column computer={8} mobile={16}>
                  <Table>
                    <Table.Body>
                      <Table.Row>
                        <Table.Cell textAlign="right" style={{ width: 120 }}>
                          <Header sub>Namn</Header>
                        </Table.Cell>
                        <Table.Cell>{this.props.ticket.name}</Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell textAlign="right" style={{ width: 120 }}>
                          <Header sub>Epost</Header>
                        </Table.Cell>
                        <Table.Cell>
                          <Input
                            fluid
                            placeholder=""
                            value={this.state.email}
                            onChange={input =>
                              this.setState({
                                email: input.target.value.replace(' ', ''),
                                saved: false
                              })
                            }
                          />
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell textAlign="right" style={{ width: 120 }}>
                          <Header sub>Lopp</Header>
                        </Table.Cell>
                        <Table.Cell>
                          {
                            <Dropdown
                              options={this.props.laps.map(({ id: value, city: text }) => ({ value, text }))}
                              value={this.state.lap}
                              onChange={(_, data) => this.setState({ lap: data.value, saved: false })}
                            />
                          }
                        </Table.Cell>
                      </Table.Row>
                      <Table.Row>
                        <Table.Cell textAlign="right" style={{ width: 120 }}>
                          <Header sub>Startgrupp</Header>
                        </Table.Cell>
                        <Table.Cell>
                          {
                            <Dropdown
                              options={['A', 'B', 'C', 'D', 'E'].map(startgroup => ({
                                value: startgroup,
                                text: startgroup
                              }))}
                              value={this.state.startgroup}
                              onChange={(_, data) => this.setState({ startgroup: data.value, saved: false })}
                            />
                          }
                        </Table.Cell>
                      </Table.Row>
                    </Table.Body>
                  </Table>
                </Grid.Column>
                <Grid.Column computer={8} mobile={16}>
                  <Button
                    style={{ height: '100%' }}
                    fluid
                    color={this.state.pickedUp ? 'green' : 'grey'}
                    size="huge"
                    onClick={() => {
                      this.setState({ pickedUp: !this.state.pickedUp, pickedUpTime: moment(), saved: false });
                    }}
                  >
                    <Icon name={this.state.pickedUp ? 'checkmark box' : 'square outline'} />
                    {this.state.pickedUp
                      ? `Uthämtad ${moment(this.state.pickedUpTime).format('YYYY-MM-DD HH:mm')}`
                      : 'Ej uthämtad'}
                  </Button>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            {this.props.ticket.shipping && (
              <Table>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub>Hemleverans</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <div
                        style={{ width: 300, float: 'left' }}
                        dangerouslySetInnerHTML={{
                          __html: `${this.props.ticket.name}<br />${this.props.ticket.address.replace('\n', '<br />')}`
                        }}
                      />
                      <Button
                        color={this.state.sent ? 'green' : 'grey'}
                        style={{ float: 'right' }}
                        onClick={() => this.setState({ sent: !this.state.sent, shippingTime: moment(), saved: false })}
                      >
                        {this.state.sent
                          ? `Skickad ${moment(this.state.shippingTime).format('YYYY-MM-DD')}`
                          : 'Ej skickad'}
                      </Button>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
            )}
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell textAlign="right" style={{ width: 120 }}>
                    <Header sub>Deltagare</Header>
                  </Table.Cell>
                  <Table.Cell>{this.renderParticipants()}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right" style={{ width: 120 }}>
                    <Header sub>Anteckningar</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <TextArea
                      rows={4}
                      autoHeight
                      value={this.state.notes}
                      onChange={input =>
                        this.setState({
                          notes: input.target.value,
                          saved: false
                        })
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                {this.props.accessLevel < 4 && (
                  <Table.Row>
                    <Table.Cell textAlign="right" style={{ width: 120 }}>
                      <Header sub />
                    </Table.Cell>
                    <Table.Cell>
                      {this.state.showSummary ? (
                        <>
                          <Card fluid>
                            <Card.Content>
                              <Grid columns="equal">
                                <Grid.Row>
                                  <Grid.Column verticalAlign="middle">
                                    <Header>Återbetalning</Header>
                                    <Checkbox
                                      toggle
                                      onChange={() =>
                                        this.setState({
                                          refund: !this.state.refund,
                                          refundAmount: '',
                                          fullRefund: false,
                                          saved: false
                                        })
                                      }
                                      checked={this.state.refund}
                                    />
                                  </Grid.Column>
                                  {this.state.refund && (
                                    <>
                                      <Grid.Column verticalAlign="middle">
                                        <Input
                                          disabled={this.state.fullRefund}
                                          fluid
                                          label={{ basic: true, content: 'kr' }}
                                          labelPosition="right"
                                          placeholder="0"
                                          onChange={input =>
                                            this.setState({
                                              refundAmount: input.target.value.replace(/[^0-9]/g, ''),
                                              saved: false
                                            })
                                          }
                                          value={this.state.refundAmount}
                                        />
                                      </Grid.Column>
                                      <Grid.Column verticalAlign="middle">
                                        <Checkbox
                                          label="Full återbetalning"
                                          onChange={() =>
                                            this.setState({
                                              fullRefund: !this.state.fullRefund,
                                              refundAmount: this.state.fullRefund ? '' : this.props.ticket.total_price,
                                              saved: false
                                            })
                                          }
                                          checked={this.state.fullRefund}
                                        />
                                      </Grid.Column>
                                    </>
                                  )}
                                </Grid.Row>
                              </Grid>
                            </Card.Content>
                          </Card>
                          {this.renderSummary()}
                        </>
                      ) : (
                        <Button fluid color="black" onClick={() => this.setState({ showSummary: true })}>
                          Detaljer
                        </Button>
                      )}
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Row>
              {this.props.accessLevel < 4 && (
                <Grid.Column width={8} textAlign="left">
                  {this.state.sendReceiptConfirm ? (
                    <>
                      {!this.state.emailSent && (
                        <Button color="grey" onClick={() => this.setState({ sendReceiptConfirm: false })}>
                          <Icon name="cancel" /> Avbryt
                        </Button>
                      )}
                      <Button
                        color="black"
                        disabled={this.state.emailSent}
                        onClick={() => {
                          axios
                            .post(`${API_ENDPOINT}/api/tickets/sendreceipt`, {
                              id: this.props.ticket.id,
                              email: this.state.email
                            })
                            .then(() => this.setState({ emailSent: true }));
                        }}
                      >
                        <Icon name="mail" /> {this.state.emailSent ? 'Kvitto skickat!' : 'Är du säker?'}
                      </Button>
                    </>
                  ) : (
                    <>
                      <Button
                        color="black"
                        icon="file outline"
                        as="a"
                        href={`https://2019.sommarkulbiljetter.se/order/${this.props.ticket.token}`}
                        target="_blank"
                      />
                      <Button color="black" onClick={() => this.setState({ sendReceiptConfirm: true })}>
                        <Icon name="mail" /> Skicka kvitto igen
                      </Button>
                    </>
                  )}
                </Grid.Column>
              )}
              <Grid.Column width={this.props.accessLevel < 4 ? 8 : 16} textAlign="right">
                <Button
                  color="grey"
                  onClick={() => {
                    this.setState(INITIAL_STATE);
                    this.props.close();
                  }}
                >
                  <Icon name="cancel" /> Avbryt
                </Button>
                <Button color="green" onClick={this.save.bind(this)} disabled={this.state.saved}>
                  <Icon name="save" />
                  Spara
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ tickets, user }) => {
  const { laps } = tickets;
  const { accessLevel } = user;

  return { laps, accessLevel };
};

export default connect(
  mapStateToProps,
  { fetchTickets }
)(ViewTicket);
