import React from 'react';
import { Button, Grid, Input, Loader, Icon, Segment, Header, Modal, Image } from 'semantic-ui-react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Dropzone from 'react-dropzone';

import PackingListDetails from './PackingListDetails';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';

const INITIAL_STATE = {
  selected: -1,
  selectedBeforeDelete: -1,
  deleteItem: -1,
  id: 'new',
  items: [],
  title: '',
  photos: [],
  uploading: false,
  loading: true,
  error: null,
  showSaveModal: false,
  fetchAfterModalClose: -1,
  hover: -1,
  shiftPressed: false,
};

class PackingList extends React.Component {
  state = INITIAL_STATE;

  constructor(props) {
    super(props);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  componentDidMount() {
    this.fetchPackingList(this.props.id);
  }

  componentWillReceiveProps(nextProps) {
    const { id } = nextProps;
    if (Number(id) === this.state.id || (id === 'new' && this.state.id === 'new')) {
      return;
    }

    window.scrollTo(0, 0);

    if (this.props.saved) {
      if (id === 'new') {
        this.setState({ ...INITIAL_STATE, loading: false });
      } else {
        this.fetchPackingList(Number(id));
      }
    } else {
      this.setState({ showSaveModal: true, fetchAfterModalClose: id });
    }
  }

  getItemStyle = (isDragging, draggableStyle, selected) => ({
    background: selected ? '#f5f5f5' : '#fff',
    borderRadius: 4,
    ...draggableStyle,
  });

  onDragEnd(result) {
    if (!result.destination) {
      return;
    }

    const {
      source: { index: from },
      destination: { index: to },
    } = result;
    const direction = from < to ? 'down' : 'up';
    const moves = Math.abs(from - to);
    let currentIndex = from;

    console.log(direction, moves);
    for (let i = moves; i > 0; i--) {
      this.onMoveItem(currentIndex, direction);
      currentIndex = direction === 'down' ? currentIndex + 1 : currentIndex - 1;
    }
  }

  onDelete() {
    const { id } = this.props;

    return new Promise((resolve) =>
      axios.delete(`${API_ENDPOINT}/api/packing/${id}`).then(({ data }) => {
        resolve();
      })
    );
  }

  onSave() {
    const { id, photos, title, items } = this.state;

    return new Promise((resolve) => {
      axios.put(`${API_ENDPOINT}/api/packing/${id}`, { title, photos, items }).then(({ data }) => {
        resolve();
        this.props.fetchLists();
      });
    });
  }

  fetchPackingList(id) {
    if (id === 'new') {
      this.setState({
        ...INITIAL_STATE,
        loading: false,
      });
    } else {
      axios.get(`${API_ENDPOINT}/api/packing/${id || this.state.id}`).then(({ data }) => {
        this.setState({
          ...INITIAL_STATE,
          ...data,
          loading: false,
        });
      });
    }
  }

  onChange(type, value) {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState({
      [type]: value,
    });
  }

  onChangeItem(index, type, value) {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    if (type === 'name') {
      this.setState(({ items }) => {
        items[index].name = value;

        return {
          items,
        };
      });
    } else if (type.substring(0, 3) === 'sub') {
      const subIndex = type.split('-')[1];
      this.setState(({ items }) => {
        items[index].sub[subIndex] = value;
        return { items, saved: false };
      });
    }
  }

  onChangeDetails = (index, type, value) => {
    this.setState(({ items, selected }) => {
      this.props.setSaved(false);
      this.props.setSaveButtonEnabled(this.state.title !== '');
      const selectedItemIndex = items.findIndex(({ id }) => id === selected);

      items[selectedItemIndex].details[index][type] = value;

      return {
        items,
      };
    });
  };

  onMoveItem = (index, direction) => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ items }) => {
      const temp = items[index];
      switch (direction) {
        case 'up':
          items[index] = items[index - 1];
          items[index - 1] = temp;
          break;
        case 'down':
          items[index] = items[index + 1];
          items[index + 1] = temp;
          break;
        default:
          break;
      }
      return { items };
    });
  };

  onMoveSubItem = (index, subIndex, direction) => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ items }) => {
      const temp = items[index].sub[subIndex];
      switch (direction) {
        case 'up':
          items[index].sub[subIndex] = items[index].sub[subIndex - 1];
          items[index].sub[subIndex - 1] = temp;
          break;
        case 'down':
          items[index].sub[subIndex] = items[index].sub[subIndex + 1];
          items[index].sub[subIndex + 1] = temp;
          break;
        default:
          break;
      }

      return { items };
    });
  };

  onMoveDetails = (detailIndex, direction) => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ selected, items }) => {
      const selectedItemIndex = items.findIndex(({ id }) => id === selected);

      const temp = items[selectedItemIndex].details[detailIndex];
      switch (direction) {
        case 'up':
          items[selectedItemIndex].details[detailIndex] = items[selectedItemIndex].details[detailIndex - 1];
          items[selectedItemIndex].details[detailIndex - 1] = temp;
          break;
        case 'down':
          items[selectedItemIndex].details[detailIndex] = items[selectedItemIndex].details[detailIndex + 1];
          items[selectedItemIndex].details[detailIndex + 1] = temp;
          break;
        default:
          break;
      }

      return { items };
    });
  };

  onDeleteItem = (itemIndex) => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ items, selected }) => ({
      items: items.filter((_, index) => itemIndex !== index),
      deleteItem: -1,
      selected: selected === items[itemIndex].id ? -1 : selected,
    }));
  };

  onDeleteSubItem = (itemIndex, subItemIndex) => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ items }) => ({
      items: items.map((item, index) =>
        index === itemIndex ? { ...item, sub: item.sub.filter((_, index) => subItemIndex !== index) } : item
      ),
    }));
  };

  onAddItem = () => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ items }) => {
      return {
        items: [
          ...items,
          {
            id: items.length === 0 ? 0 : items.reduce((acc, { id }) => (id >= acc ? id + 1 : acc), 0),
            name: '',
            sub: [],
            details: [],
          },
        ],
      };
    });
  };

  onAddSubItem = (itemIndex) => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ items }) => ({
      items: items.map((item, index) =>
        index === itemIndex ? { ...item, sub: item.sub ? [...item.sub, ''] : [''] } : item
      ),
    }));
  };

  onDeleteImage = (detailsIndex) => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ selected, items }) => ({
      items: items.map((item) =>
        item.id === selected
          ? {
              ...item,
              details: item.details.map((details, index) =>
                index === detailsIndex ? { ...details, image: '' } : details
              ),
            }
          : item
      ),
    }));
  };

  onAddImage = (detailsIndex, image) => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ selected, items }) => ({
      items: items.map((item) =>
        item.id === selected
          ? {
              ...item,
              details: item.details.map((details, index) => (index === detailsIndex ? { ...details, image } : details)),
            }
          : item
      ),
    }));
  };

  onAddDetails = () => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ selected, items }) => {
      const selectedItemIndex = items.findIndex(({ id }) => id === selected);
      return {
        items: items.map((item, index) =>
          index === selectedItemIndex ? { ...item, details: [...item.details, { image: '', text: '' }] } : item
        ),
      };
    });
  };

  onDeleteDetails = (detailIndex) => {
    this.props.setSaved(false);
    this.props.setSaveButtonEnabled(this.state.title !== '');
    this.setState(({ selected, items }) => {
      const selectedItemIndex = items.findIndex(({ id }) => id === selected);

      return {
        items: items.map((item, index) =>
          index === selectedItemIndex
            ? { ...item, details: item.details.filter((_, index) => detailIndex !== index) }
            : item
        ),
      };
    });
  };

  render() {
    const {
      error,
      title,
      deleteItem,
      items,
      selected,
      selectedBeforeDelete,
      showSaveModal,
      loading,
      hover,
    } = this.state;

    if (loading) {
      return <Loader size="huge" active />;
    }

    if (error) {
      return (
        <Segment textAlign="center" style={{ height: 600, paddingTop: 240 }}>
          <Icon name="find" size="huge" />
          <Header>Listan kunde ej hittas</Header>
        </Segment>
      );
    }

    if (showSaveModal) {
      const fetchLists = () => {
        this.props.setSaved(true);
        if (this.props.id === 'new') {
          this.setState({ ...INITIAL_STATE, loading: false });
        } else {
          this.fetchPackingList(Number(this.props.id));
        }
      };

      return (
        <Modal open size="small">
          <Header icon="warning sign" content="Du har osparade ändringar" />
          <Modal.Content>
            <p>Vill du spara ändringarna i "{title}"?</p>
          </Modal.Content>
          <Modal.Actions>
            <Button
              onClick={() => {
                fetchLists();
              }}
            >
              <Icon name="remove" /> Nej
            </Button>
            <Button
              color="green"
              onClick={() => {
                this.onSave();
                fetchLists();
              }}
            >
              <Icon name="save" /> Spara
            </Button>
          </Modal.Actions>
        </Modal>
      );
    }

    return (
      <Grid columns="equal" style={{ marginLeft: 18 }}>
        <Grid.Row>
          <Grid.Column>
            <Input
              fluid
              placeholder="Packlista..."
              style={{ fontSize: 24, paddingLeft: 3, marginBottom: 24 }}
              transparent
              value={title}
              onChange={(input) => {
                this.onChange('title', input.target.value);
                this.props.setSaveButtonEnabled(Boolean(input.target.value));
              }}
            />
            <Grid columns={3}>
              <Grid.Row>
                {this.state.photos.map((photo) => (
                  <Grid.Column key={`photo-${photo}`} style={{ position: 'relative', marginTop: 8 }}>
                    <Image src={`${API_ENDPOINT}/api/static/packing/${photo}?i=${Math.ceil(Math.random() * 10000)}`} />
                    {this.state.deletePhoto === photo ? (
                      <>
                        <Button
                          icon="trash"
                          circular
                          size="tiny"
                          color="red"
                          style={{ position: 'absolute', top: 5, right: 56, cursor: 'pointer' }}
                          onClick={() => {
                            this.setState((state) => ({
                              photos: state.photos.filter((p) => p !== photo),
                              deletePhoto: '',
                            }));
                            this.props.setSaved(false);
                          }}
                        />
                        <Button
                          icon="cancel"
                          circular
                          size="tiny"
                          style={{ position: 'absolute', top: 5, right: 16, cursor: 'pointer' }}
                          onClick={() => this.setState({ deletePhoto: '' })}
                        />
                      </>
                    ) : (
                      <Button
                        icon="trash"
                        circular
                        size="tiny"
                        color="red"
                        style={{ position: 'absolute', top: 5, right: 16, cursor: 'pointer' }}
                        onClick={() => this.setState({ deletePhoto: photo })}
                      />
                    )}
                  </Grid.Column>
                ))}
                <Grid.Column style={{ border: '3px dashed #aaa', marginTop: 12, marginBottom: 8 }}>
                  <Dropzone onDrop={(acceptedFiles) => console.log(acceptedFiles)}>
                    {({ getRootProps, getInputProps }) => (
                      <section>
                        <div {...getRootProps()} style={{ textAlign: 'center', paddingTop: 24, paddingBottom: 24 }}>
                          <input
                            {...getInputProps()}
                            onChange={(event) => {
                              this.setState({ uploading: true });
                              const formData = new FormData();
                              formData.append('file', event.target.files[0], event.target.files[0].name);
                              axios
                                .post(`${API_ENDPOINT}/api/packing/${this.props.id}/image`, formData)
                                .then(({ data }) => {
                                  this.setState((state) => ({
                                    photos: [...state.photos, data],
                                    uploading: false,
                                  }));
                                  this.props.setSaved(false);
                                });
                            }}
                          />
                          <Icon name="upload" />
                          <p>Ladda upp bild</p>
                        </div>
                      </section>
                    )}
                  </Dropzone>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <DragDropContext onDragEnd={this.onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {items.map(({ id, name, sub, details }, index) => (
                      <Draggable key={`item-${index}`} draggableId={`item-${index}`} index={index}>
                        {(provided, snapshot) => (
                          <div
                            className="item-row"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            style={this.getItemStyle(
                              snapshot.isDragging,
                              provided.draggableProps.style,
                              id === selected
                            )}
                          >
                            <Grid>
                              <Grid.Row
                                style={{
                                  paddingBottom: 4,
                                  paddingTop: 4,
                                  marginBottom: 10,
                                  marginTop: 10,
                                  marginLeft: 10,
                                  marginRight: 14,
                                  borderRadius: 4,
                                  opacity: deleteItem >= 0 && deleteItem !== index ? 0.2 : 1,
                                }}
                                className="highlight-on-hover"
                                onClick={() => this.setState({ selected: id, selectedBeforeDelete: id })}
                                onMouseOver={() => this.setState({ hover: id })}
                                onMouseOut={() => this.setState({ hover: -1 })}
                              >
                                <Grid.Column verticalAlign="middle" width={14}>
                                  <Icon
                                    name="circle outline"
                                    size="large"
                                    color="black"
                                    onClick={() => this.setState({ selected: id, selectedBeforeDelete: id })}
                                    style={{
                                      float: 'left',
                                      paddingTop: 0,
                                      paddingLeft: 0,
                                      paddingRight: 18,
                                      marginRight: 2,
                                      opacity: details.length ? 1 : 0.15,
                                      cursor: 'pointer',
                                    }}
                                  />
                                  <Input
                                    fluid
                                    className="no-border"
                                    autoFocus={name.length === 0}
                                    transparent
                                    style={{ margin: 0, padding: 0, fontSize: 17 }}
                                    value={name}
                                    onKeyDown={({ key }) => {
                                      if (key === 'Shift') {
                                        this.setState({ shiftPressed: true });
                                      } else if (key === 'Enter') {
                                        if (this.state.shiftPressed) {
                                          this.onAddItem();
                                        } else {
                                          this.onAddSubItem(index);
                                        }
                                      }
                                    }}
                                    onKeyUp={({ key }) => key === 'Shift' && this.setState({ shiftPressed: false })}
                                    onChange={(input) => {
                                      deleteItem === -1 && this.onChangeItem(index, 'name', input.target.value);
                                    }}
                                  >
                                    <input style={{ fontWeight: 'bold', marginLeft: 7 }} />
                                  </Input>
                                </Grid.Column>
                                <Grid.Column
                                  textAlign="right"
                                  width={2}
                                  style={{
                                    display: 'flex',
                                    justifyContent: 'flex-end',
                                    visibility: hover === id ? 'visible' : 'hidden',
                                  }}
                                >
                                  {deleteItem === index ? (
                                    <>
                                      <Button
                                        circular
                                        onClick={() =>
                                          this.setState({ selected: selectedBeforeDelete, deleteItem: -1 })
                                        }
                                        size="mini"
                                        style={{ margin: 1 }}
                                      >
                                        <Icon name="cancel" />
                                        Avbryt
                                      </Button>
                                      <Button
                                        color="red"
                                        circular
                                        onClick={() => this.onDeleteItem(index)}
                                        size="mini"
                                        style={{ margin: 1 }}
                                      >
                                        <Icon name="trash" />
                                        Radera
                                      </Button>
                                    </>
                                  ) : (
                                    <>
                                      <Button
                                        color="green"
                                        icon="add"
                                        circular
                                        onClick={() => this.onAddSubItem(index)}
                                        size="mini"
                                        style={{ margin: 1 }}
                                      />
                                      <Button
                                        color="red"
                                        icon="trash"
                                        circular
                                        onClick={() =>
                                          name === '' && sub.length === 0 && details.length === 0
                                            ? this.onDeleteItem(index)
                                            : this.setState({ deleteItem: index, selected: details.length ? id : -1 })
                                        }
                                        size="mini"
                                        style={{ margin: 1 }}
                                      />
                                    </>
                                  )}
                                </Grid.Column>
                              </Grid.Row>
                            </Grid>
                            {sub && sub.length
                              ? sub.map((subItem, subIndex) => (
                                  <div
                                    key={`item-${index}-subitem-${subIndex}`}
                                    className="highlight-on-hover"
                                    style={{
                                      marginLeft: 11,
                                      display: 'flex',
                                      borderRadius: 4,
                                      justifyContent: 'space-between',
                                      opacity: deleteItem >= 0 && deleteItem !== index ? 0.2 : 1,
                                    }}
                                    onMouseOver={() => this.setState({ hover: id })}
                                    onMouseOut={() => this.setState({ hover: -1 })}
                                  >
                                    <div style={{ marginLeft: 14, marginTop: 8, marginRight: -21 }}>&bull;</div>
                                    <Input
                                      fluid
                                      className="no-border"
                                      focus
                                      autoFocus={subItem.length === 0}
                                      transparent
                                      iconPosition="left"
                                      style={{ marginLeft: 6, fontSize: 15, flexGrow: 1 }}
                                      value={subItem}
                                      onKeyDown={({ key }) => {
                                        if (key === 'Shift') {
                                          this.setState({ shiftPressed: true });
                                        } else if (key === 'Enter') {
                                          if (this.state.shiftPressed) {
                                            this.onAddItem();
                                          } else {
                                            this.onAddSubItem(index);
                                          }
                                        }
                                      }}
                                      onKeyUp={(event) => this.setState({ shiftPressed: false })}
                                      onChange={(input) =>
                                        deleteItem === -1 &&
                                        this.onChangeItem(index, `sub-${subIndex}`, input.target.value)
                                      }
                                      onClick={() => this.setState({ selected: id, selectedBeforeDelete: id })}
                                    />
                                    <div
                                      className="show-on-hover"
                                      style={{
                                        marginRight: 13,
                                        paddingTop: 2,
                                        paddingBottom: 2,
                                        visibility: deleteItem === -1 ? 'visible' : 'hidden',
                                      }}
                                    >
                                      <Button
                                        color="black"
                                        icon="arrow up"
                                        circular
                                        disabled={subIndex === 0}
                                        onClick={() => this.onMoveSubItem(index, subIndex, 'up')}
                                        size="mini"
                                      />
                                      <Button
                                        color="black"
                                        icon="arrow down"
                                        circular
                                        disabled={subIndex + 1 === sub.length}
                                        onClick={() => this.onMoveSubItem(index, subIndex, 'down')}
                                        size="mini"
                                      />
                                      <Button
                                        color="red"
                                        icon="trash"
                                        circular
                                        onClick={() => this.onDeleteSubItem(index, subIndex)}
                                        size="mini"
                                      />
                                    </div>
                                  </div>
                                ))
                              : null}
                          </div>
                        )}
                      </Draggable>
                    ))}

                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button style={{ marginTop: 12 }} color="black" onClick={this.onAddItem}>
              <Icon name="add" /> Lägg till
            </Button>
          </Grid.Column>
          <Grid.Column>
            {selected >= 0 && (
              <PackingListDetails
                inactive={deleteItem >= 0 && items[deleteItem].id === selected}
                item={items.find(({ id }) => id === selected)}
                onDeleteImage={this.onDeleteImage}
                onAddImage={this.onAddImage}
                onChangeDetails={this.onChangeDetails}
                onMoveDetails={this.onMoveDetails}
                onAddDetails={this.onAddDetails}
                onDeleteDetails={this.onDeleteDetails}
              />
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default PackingList;
