import React from 'react';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { Icon, Image, Button, Message, Modal, Grid } from 'semantic-ui-react';

require('../../index.css');
require('./booking.css');

class BookingPhotos extends React.Component {
  state = {
    photos: [],
    show: '',
    error: '',
    deleteWarning: false,
  };

  constructor(props) {
    super(props);

    this.keyStroke = this.keyStroke.bind(this);
  }

  componentDidMount() {
    axios
      .get(`${API_ENDPOINT}/api/bookings/${this.props.id}/photos`)
      .then(({ data }) => this.setState({ photos: data }));

    document.addEventListener('keydown', this.keyStroke, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.keyStroke, false);
  }

  keyStroke({ keyCode }) {
    if (this.state.show) {
      switch (keyCode) {
        case 37:
          this.previousPhoto();
          break;
        case 39:
          this.nextPhoto();
          break;
        default:
          break;
      }
    }
  }

  previousPhoto = () => {
    const { photos, show } = this.state;
    if (photos.length < 2) {
      return;
    }

    if (show === photos[0]) {
      this.setState({ show: photos[photos.length - 1] });
    } else {
      this.setState({ show: photos[photos.indexOf(show) - 1] });
    }
  };

  nextPhoto = () => {
    const { photos, show } = this.state;
    if (photos.length < 2) {
      return;
    }

    if (show === photos[photos.length - 1]) {
      this.setState({ show: photos[0] });
    } else {
      this.setState({ show: photos[photos.indexOf(show) + 1] });
    }
  };

  render() {
    return (
      <>
        <Modal
          open={this.state.show.length > 0}
          onClose={() => this.setState({ show: '', deleteWarning: false })}
          basic
        >
          <Image
            src={`${API_ENDPOINT}/api/static/1600/${this.state.show}.jpg`}
            style={{ display: 'block', maxHeight: '80vh', margin: '0 auto' }}
          />
          <Modal.Actions style={{ width: 560, margin: '0 auto' }}>
            <Grid columns={2} style={{ padding: '0px 10px 0 10px' }}>
              <Grid.Row>
                <Grid.Column textAlign="left" width={9}>
                  {!this.state.deletePhotoWarning && (
                    <Button
                      color="green"
                      as="a"
                      target="_blank"
                      href={`${API_ENDPOINT}/api/static/full/${this.state.show}.jpg`}
                    >
                      <Icon name="download" /> Ladda ner
                    </Button>
                  )}
                  {this.state.deletePhotoWarning ? (
                    <>
                      <Button
                        color="red"
                        onClick={() =>
                          axios
                            .delete(`${API_ENDPOINT}/api/bookings/${this.props.id}/photos/${this.state.show}`)
                            .then(() => {
                              this.setState({
                                photos: this.state.photos.reduce(
                                  (acc, next) => (next === this.state.show ? acc : [...acc, next]),
                                  []
                                ),
                                show: '',
                                deletePhotoWarning: false,
                              });
                            })
                        }
                      >
                        <Icon name="warning" /> Är du säker?
                      </Button>
                      <Button onClick={() => this.setState({ deletePhotoWarning: false })}>
                        <Icon name="cancel" /> Avbryt
                      </Button>
                    </>
                  ) : (
                    <Button color="red" onClick={() => this.setState({ deletePhotoWarning: true })}>
                      <Icon name="trash" /> Radera
                    </Button>
                  )}
                </Grid.Column>
                <Grid.Column textAlign="right" width={7}>
                  {!this.state.deleteWarning && (
                    <>
                      <Button icon="arrow circle left" onClick={this.previousPhoto} />
                      <Button style={{ cursor: 'default' }}>
                        {this.state.photos.indexOf(this.state.show) + 1} / {this.state.photos.length}
                      </Button>
                      <Button icon="arrow circle right" onClick={this.nextPhoto} />
                    </>
                  )}
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Modal.Actions>
        </Modal>
        {this.state.photos.map((photo) => (
          <Image
            key={photo}
            src={`${API_ENDPOINT}/api/static/320/${photo}.jpg`}
            className="fade-in"
            style={{
              maxHeight: 134,
              marginRight: 10,
              marginBottom: 10,
              cursor: 'pointer',
              borderRadius: 3,
              border: '1px solid #eee',
            }}
            alt=""
            onClick={() => this.setState({ show: photo })}
          />
        ))}
        <div>
          {this.state.error.length > 0 && (
            <Message negative>
              <Message.Header>Uppladdning misslyckades</Message.Header>
              <p>{this.state.error}</p>
            </Message>
          )}
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Button
                  fluid
                  as="a"
                  target="_blank"
                  href={`${API_ENDPOINT}/api/bookings/${this.props.id}/photos/all`}
                  color="green"
                  disabled={this.state.photos.length === 0}
                >
                  <Icon name="download" /> Ladda ner alla
                </Button>
              </Grid.Column>
              <Grid.Column width={8}>
                <Button fluid color="black">
                  <Icon name="upload" /> Ladda upp bild
                </Button>
                <input
                  type="file"
                  accept="image/jpeg"
                  value=""
                  style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    opacity: 0,
                    height: 40,
                    width: '100%',
                    cursor: 'pointer',
                  }}
                  onChange={(event) => {
                    if (event.target.files[0].type !== 'image/jpeg') {
                      this.setState({ error: 'Filformat måste vara jpg' });
                    } else {
                      const formData = new FormData();
                      formData.append('file', event.target.files[0], event.target.files[0].name);

                      axios
                        .post(`${API_ENDPOINT}/api/bookings/${this.props.id}/photos`, formData, {
                          onUploadProgress: (progressEvent) => {
                            // console.log(
                            //   progressEvent.loaded / progressEvent.total
                            // );
                          },
                        })
                        .then(({ data }) => {
                          this.setState({
                            photos: [...this.state.photos, data],
                            error: '',
                          });
                        });
                    }
                  }}
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </div>
      </>
    );
  }
}

export default BookingPhotos;
