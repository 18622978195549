import React from 'react';
import { Table, Header } from 'semantic-ui-react';

import { formatPrice, formatDate } from '../../utilities';

class EventReport extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);
  }

  getEvents() {
    let sumTotalIn = 0;
    let sumTotalOut = 0;

    const rows = this.props.events
      .filter(({ confirmed }) => confirmed)
      .map(event => {
        const {
          id,
          startdate,
          enddate,
          duration,
          name,
          customName,
          customerName,
          travelPrice,
          distance,
          totalOut
        } = event;

        const totalIn = event.price - event.discount + event.hotelPrice + event.travelPrice * event.distance;

        sumTotalIn += totalIn;
        sumTotalOut += totalOut + travelPrice * distance;

        return (
          <Table.Row key={`event${id}`}>
            <Table.Cell>{formatDate(startdate, enddate, duration)}</Table.Cell>
            <Table.Cell>
              <Header>{customName === '' ? name : customName}</Header>
              {customerName}
            </Table.Cell>
            <Table.Cell textAlign="right">{formatPrice(Math.round(totalIn))}</Table.Cell>
            <Table.Cell textAlign="right">{formatPrice(Math.round(totalOut + travelPrice * distance))}</Table.Cell>
            <Table.Cell textAlign="right">
              {formatPrice(Math.round(totalIn - totalOut - travelPrice * distance))}
            </Table.Cell>
          </Table.Row>
        );
      });

    return { rows, sumTotalIn, sumTotalOut };
  }

  render() {
    const { rows, sumTotalIn, sumTotalOut } = this.getEvents();

    return (
      <Table singleLine unstackable basic="very" striped columns={8}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={1}>Datum</Table.HeaderCell>
            <Table.HeaderCell width={3}>Event</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="right">
              Inkomster
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="right">
              Utgifter
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="right">
              Vinst
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={2} />
            <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
              {formatPrice(Math.round(sumTotalIn))}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
              {formatPrice(Math.round(sumTotalOut))}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
              {formatPrice(Math.round(sumTotalIn - sumTotalOut))}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

export default EventReport;
