import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Card, Header, Table } from 'semantic-ui-react';

import { formatPrice, formatDate } from '../../utilities';
import { API_ENDPOINT } from '../../config';

class StaffReportSingle extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps() {
    window.scrollTo(0, 0);
  }

  getEvents() {
    let sumHours = 0;
    let sumSalary = 0;
    let sumTravelCompensation = 0;
    let sumPerDiem = 0;
    let sumDistance = 0;

    const rows = this.props.events.map(event => {
      const { id, startdate, enddate, duration, name, customName, customerName, staff, travelCompensation } = event;

      sumHours += Number(staff.hours);
      sumSalary += staff.hours * staff.salary;
      sumDistance += staff.distance;
      sumTravelCompensation += staff.distance * travelCompensation;
      sumPerDiem += staff.perDiem;

      return (
        <Table.Row key={`event${id}-staff${staff.id}`}>
          <Table.Cell>{formatDate(startdate, enddate, duration)}</Table.Cell>
          <Table.Cell>
            <Header>{customName === '' ? name : customName}</Header>
            {customerName}
          </Table.Cell>
          <Table.Cell textAlign="right">{Number(staff.hours)}</Table.Cell>
          <Table.Cell textAlign="right">{staff.salary}:-</Table.Cell>
          <Table.Cell textAlign="right">{formatPrice(Math.round(Number(staff.hours) * staff.salary))}</Table.Cell>
          <Table.Cell textAlign="right">{formatPrice(staff.distance, true)}</Table.Cell>
          <Table.Cell textAlign="right">{formatPrice(Math.round(staff.distance * travelCompensation))}</Table.Cell>
          <Table.Cell textAlign="right">{formatPrice(staff.perDiem)}</Table.Cell>
          <Table.Cell textAlign="right">
            {formatPrice(Number(staff.hours) * staff.salary + staff.distance * travelCompensation + staff.perDiem)}
          </Table.Cell>
        </Table.Row>
      );
    });

    return { rows, sumHours, sumSalary, sumDistance, sumTravelCompensation, sumPerDiem };
  }

  render() {
    if (!this.props.events) {
      return <div />;
    }

    const { rows, sumHours, sumSalary, sumDistance, sumTravelCompensation, sumPerDiem } = this.getEvents();

    return (
      <Card fluid>
        <Card.Content>
          <Card.Header>
            <Button
              basic
              color="black"
              icon="download"
              style={{ marginRight: 16 }}
              as="a"
              target="_blank"
              href={`${API_ENDPOINT}/api/reports/print/${this.props.from}/${this.props.to}/${this.props.events[0].staff.id}`}
            />
            <Link to={`/staff/${this.props.events[0].staff.id}`} style={{ color: '#000' }}>
              {this.props.events[0].staff.name}
            </Link>
          </Card.Header>
          <Table unstackable basic="very" striped width={13}>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell width={1}>Datum</Table.HeaderCell>
                <Table.HeaderCell width={3}>Event</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  Timmar
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  Timlön
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  Lön
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  Mil
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  Reseersättning
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  Traktamente
                </Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="right">
                  Totalt
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>{rows}</Table.Body>
            <Table.Footer>
              <Table.Row>
                <Table.HeaderCell colSpan={2} />
                <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
                  {sumHours}
                </Table.HeaderCell>
                <Table.HeaderCell />
                <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
                  {formatPrice(Math.round(sumSalary))}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
                  {sumDistance}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
                  {formatPrice(Math.round(sumTravelCompensation))}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
                  {formatPrice(Math.round(sumPerDiem))}
                </Table.HeaderCell>
                <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
                  {formatPrice(Math.round(sumSalary + sumTravelCompensation + sumPerDiem))}
                </Table.HeaderCell>
              </Table.Row>
            </Table.Footer>
          </Table>
        </Card.Content>
      </Card>
    );
  }
}

export default StaffReportSingle;
