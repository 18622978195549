import React from 'react';
import { connect } from 'react-redux'
import axios from 'axios';
import moment from 'moment';
import { API_ENDPOINT } from '../../config';
import { formatSize } from '../../utilities';
import { Header, List, Button, Icon, Card, Grid } from 'semantic-ui-react';

import { navigate } from '../../actions'

class Backups extends React.Component {
  state = {
    backups: [],
    loading: true
  }

  componentDidMount() {
    this.props.navigate('backups');

    window.scrollTo(0, 0);

    axios.get(`
      ${API_ENDPOINT}/api/backups
    `).then(({ data: backups }) => {
        this.setState({ backups, loading: false })
      })
  }

  render() {
    return (
      <div style={{ marginTop: -50 }}>
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Card fluid>
                <Card.Content>
                  <Header>Backuper</Header>
                  {this.state.loading ? 'Laddar backuper...' :
                    <List divided verticalAlign='middle' fluid>
                      {this.state.backups.reverse().map(({ Key, LastModified, Size }) => <List.Item key={Key}>
                        <List.Content floated='right'>
                          <Button icon="download" circular color="black" as="a" href={`${API_ENDPOINT}/api/backups/${Key}`} />
                        </List.Content>
                        <Icon name="file" size="big" />
                        <List.Content>
                          <List.Header>{moment(LastModified).format('YYYY-MM-DD HH:mm')}</List.Header>
                          <List.Description>{formatSize(Size)}</List.Description>
                        </List.Content>
                      </List.Item>)}
                    </List>}
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

export default connect(null, { navigate })(Backups);
