import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducers from './reducers';
import ReactGA from 'react-ga';
import { LIVE } from './config';
import 'semantic-ui-css/semantic.min.css';
import App from './App';

if (LIVE) {
  ReactGA.initialize('UA-129161136-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
}

// import registerServiceWorker from './registerServiceWorker';

const store = createStore(
  reducers,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
  applyMiddleware(thunk)
);

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
