import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { Checkbox, Header, Label, Icon, List, Button, Popup, Table, Card } from 'semantic-ui-react';
import moment from 'moment';

import { formatDate, days } from '../../utilities';

import StaffModal from './StaffModal';

class BookingRow extends React.Component {
  state = {
    notes: false,
    delete: false,
    booking: {},
    today: moment.utc().format('YYYY-MM-DD')
  };

  componentDidMount() {
    this.setState({ booking: this.props.booking });
  }

  renderHours() {
    const hours = [];
    if (Number(this.props.booking.hours.replace(/[^0-9]/g, '')) === 0) {
      return (
        <Table.Row>
          <Table.Cell textAlign="center" style={{ background: '#fff' }}>
            {this.props.booking.duration === 1
              ? `${days[moment.utc(this.props.booking.startdate).format('d')]}`
              : `${days[moment.utc(this.props.booking.startdate).format('d')]} – ${
                  days[moment.utc(this.props.booking.enddate).format('d')]
                }`}
          </Table.Cell>
        </Table.Row>
      );
    }
    for (let day = 0; day < this.props.booking.hours.length; day += 11) {
      hours.push(this.props.booking.hours.substring(day, day + 11));
    }

    return hours.map((day, index) => {
      return (
        <Table.Row key={`booking${this.props.booking.id}hours${index}`}>
          <Table.Cell style={{ width: 30 }}>
            {days[
              moment
                .utc(`${this.props.booking.startdate}`)
                .add(index, 'days')
                .format('d')
            ].substring(0, 3)}
          </Table.Cell>
          <Table.Cell singleLine style={{ width: 40 }}>
            {day.replace('-', ' – ')}
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  renderStaffNeeded() {
    const staffNeeded = this.props.booking.staff_needed;
    const staffBooked = this.props.booking.staff.filter(staff => staff.id !== 8).length;

    return (
      <Label color={staffNeeded > staffBooked ? 'red' : 'green'} style={{ fontColor: '#fff' }}>
        <Icon name="user" />
        {`${staffBooked} / ${staffNeeded}`}
      </Label>
    );
  }

  render() {
    if (this.state.delete) {
      return (
        <Table.Row>
          <Table.Cell colSpan={9} textAlign="center" style={{ background: '#CE1D23' }}>
            <Header style={{ color: '#fff', marginBottom: 0 }}>
              Är du säker på att du vill radera {this.props.booking.custom_name || this.props.booking.eventname}?
            </Header>
            <div
              style={{
                color: '#fff',
                fontSize: 18,
                fontWeight: '200',
                marginTop: 8
              }}
            >
              {this.props.booking.customer_name}, {this.props.booking.city}
            </div>
            <div
              style={{
                color: '#fff',
                fontSize: 18,
                fontWeight: '200',
                marginTop: 8
              }}
            >
              {formatDate(this.props.booking.startdate, this.props.booking.enddate, this.props.booking.duration)}
            </div>
            <br />
            <Button onClick={() => this.setState({ delete: false })}>
              <Icon name="remove" /> Avbryt
            </Button>
            <Button
              color="black"
              onClick={async () => {
                await axios.delete(`${API_ENDPOINT}/api/bookings/${this.props.booking.id}`);
                this.props.deleteBooking();
              }}
            >
              <Icon name="trash" /> Radera
            </Button>
          </Table.Cell>
        </Table.Row>
      );
    }

    return (
      <Table.Row>
        {!this.props.compact && (
          <Table.Cell
            style={{
              width: 40,
              backgroundColor: this.props.selected
                ? '#777'
                : this.props.booking.event === 112
                ? '#ffffdf'
                : this.props.index % 2 === 0
                ? '#fafafb'
                : '#fff',
              cursor: 'pointer'
            }}
            onClick={() => !this.props.compact && this.props.setSelected(this.props.booking.id)}
          >
            <Checkbox checked={this.props.selected} />
          </Table.Cell>
        )}
        <Table.Cell
          style={{
            borderLeft: 0,
            margin: 0,
            cursor: !this.props.compact ? 'pointer' : 'arrow',
            backgroundColor:
              this.props.booking.event === 112
                ? '#ffffdf'
                : this.props.compact
                ? this.props.index % 2 === 0
                  ? '#fafafb'
                  : '#fff'
                : '',
            width: 100
          }}
          onClick={() => !this.props.compact && this.props.setSelected(this.props.booking.id)}
        >
          <Header
            style={{
              width: 94,
              fontWeight: 'normal',
              fontSize: '1.2em'
            }}
          >
            <span
              style={{
                marginTop: -14,
                color: moment(this.state.today).isSameOrBefore(this.props.booking.enddate) ? '#000' : '#c23'
              }}
            >
              {formatDate(this.props.booking.startdate, this.props.booking.enddate, this.props.booking.duration)}
            </span>
          </Header>
        </Table.Cell>
        <Table.Cell
          style={{
            width: 280,
            borderLeft: 0,
            backgroundColor: this.props.booking.event === 112 ? '#ffffdf' : `#${this.props.booking.color}`,
            textAlign: 'center'
          }}
        >
          <Link
            style={{ color: '#000', fontSize: 16 }}
            to={`/bookings${this.props.booking.event === 112 ? '/mission/' : '/'}${this.props.booking.id}`}
            onClick={() => window.scrollTo(0, 0)}
          >
            {!this.props.booking.confirmed && this.props.booking.event !== 112 && (
              <Label
                style={{
                  fontSize: 14
                }}
                color="red"
              >
                Preliminär
              </Label>
            )}
            <Label
              size="huge"
              style={{
                backgroundColor: this.props.booking.event === 112 ? '#ffffdf' : `#${this.props.booking.color}`,
                width: '100%',
                fontSize: 18,
                textAlign: 'center'
              }}
            >
              {this.props.booking.custom_name || this.props.booking.eventname}
            </Label>
          </Link>
        </Table.Cell>
        {!this.props.compact ? (
          <Table.Cell style={{ width: 220, background: this.props.booking.event === 112 ? '#ffffdf' : '' }}>
            {this.props.booking.event === 112 ? (
              this.props.booking.confirmed ? (
                <Label
                  style={{
                    fontSize: 14,
                    background: '#393',
                    color: '#fff'
                  }}
                >
                  Slutförd
                </Label>
              ) : (
                <Label
                  style={{
                    fontSize: 14,
                    background: '#a33',
                    color: '#fff'
                  }}
                >
                  Ej slutförd
                </Label>
              )
            ) : (
              <>
                <Header as={Link} style={{ fontSize: 18 }} to={`/customers/${this.props.booking.customerid}`}>
                  {this.props.booking.customer_name}
                </Header>
                <br />
                {this.props.booking.city}
              </>
            )}
          </Table.Cell>
        ) : (
          <Table.Cell />
        )}
        <Table.Cell style={{ width: 200, background: this.props.booking.event === 112 ? '#ffffdf' : null }}>
          {this.props.booking.staff_needed > 0 && this.renderStaffNeeded()}
          <List relaxed>
            {this.props.booking.staff
              .filter(staff => staff.id !== 8)
              .map(staff => (
                <Popup
                  key={`booking${this.props.booking.id}staff${staff.id}`}
                  trigger={
                    <List.Item style={{ cursor: 'pointer' }}>
                      <Icon name="user circle" style={{ position: 'absolute' }} />
                      <div style={{ marginLeft: 20, fontWeight: staff.manager ? 'bold' : 'normal' }}>{staff.name}</div>
                    </List.Item>
                  }
                  on="click"
                  hideOnScroll
                  children={<StaffModal staff={staff.id} />}
                  size="small"
                />
              ))}
            <hr
              style={{
                opacity:
                  this.props.booking.cars &&
                  this.props.booking.cars.length &&
                  this.props.booking.staff.filter(staff => staff.id !== 8).length
                    ? 0.16
                    : 0,
                height: 0,
                margin: 5
              }}
            />
            {this.props.booking.cars &&
              this.props.booking.cars.map(car => (
                <List.Item key={`car${car.name}`} style={{ cursor: 'pointer' }}>
                  <Icon name="car" style={{ position: 'absolute' }} />
                  <div style={{ marginLeft: 20 }}>{car.name}</div>
                </List.Item>
              ))}
          </List>
        </Table.Cell>
        <Table.Cell style={this.props.booking.event === 112 ? { background: '#ffffdf', width: 160 } : { width: 160 }}>
          {this.props.booking.hours.length > 0 && (
            <Table compact style={{ width: 160 }}>
              <Table.Body>{this.renderHours()}</Table.Body>
            </Table>
          )}
        </Table.Cell>
        <Table.Cell verticalAlign="top" style={this.props.booking.event === 112 ? { background: '#ffffdf' } : null}>
          <div
            style={{ marginLeft: 24 }}
            dangerouslySetInnerHTML={{
              __html:
                this.props.booking.event === 112
                  ? this.props.booking.notes.replace(/\n/g, '<br>')
                  : this.props.booking.notes.replace(/<\/p><p>/g, '<br>').replace(/<br><br>/g, '<br>')
            }}
          />
          {this.state.booking.staff_follow_up && this.state.booking.staff_follow_up.length ? (
            <div style={{ marginRight: 20 }}>
              <Card fluid style={{ margin: 16 }}>
                <Card.Content>{this.state.booking.staff_follow_up}</Card.Content>
              </Card>
            </div>
          ) : null}
        </Table.Cell>
        {!this.props.compact && this.props.isAdmin && (
          <Table.Cell
            textAlign="center"
            style={this.props.booking.event === 112 ? { background: '#ffffdf', width: 72 } : { width: 72 }}
          >
            <Button
              style={{ margin: 2 }}
              circular
              color="red"
              icon="trash"
              onClick={() => this.setState({ delete: true })}
            />
            {moment(this.state.today).isAfter(this.props.booking.enddate) && (
              <Button
                style={{ margin: 2 }}
                circular
                color={this.state.booking.completed ? 'green' : 'grey'}
                icon={this.state.booking.completed ? 'checkmark box' : 'square outline'}
                onClick={() => {
                  axios.patch(`${API_ENDPOINT}/api/bookings/${this.state.booking.id}/completed`).then(({ data }) => {
                    const { completed } = data;
                    this.setState({
                      booking: { ...this.state.booking, completed }
                    });
                  });
                }}
              />
            )}
            {/* {this.state.booking.staff_follow_up && (
              <Link
                to={`/bookings${this.props.booking.event === 112 ? '/mission/' : '/'}${this.props.booking.id}`}
                onClick={() => window.scrollTo(0, 0)}
              >
                <Popup
                  position="left center"
                  wide="very"
                  trigger={<Button style={{ margin: 2 }} circular color="blue" icon="sticky note outline" />}
                  content={this.state.booking.staff_follow_up}
                />
              </Link>
            )} */}
            {this.props.booking.photos > 0 && (
              <Popup
                position="left center"
                wide="very"
                trigger={
                  <Button
                    style={{ margin: 2 }}
                    circular
                    color="black"
                    icon="photo"
                    href={`${API_ENDPOINT}/api/bookings/${this.props.booking.id}/photos/all`}
                  />
                }
                content={`${this.props.booking.photos} ${this.props.booking.photos === 1 ? 'bild' : 'bilder'}`}
              />
            )}
          </Table.Cell>
        )}
      </Table.Row>
    );
  }
}

export default BookingRow;
