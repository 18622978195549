import React from 'react';
import { Image } from 'semantic-ui-react';
import { API_ENDPOINT } from '../../config';

class PackingListImage extends React.Component {
  state = {
    uploading: false,
  };

  render() {
    const { image, onClick } = this.props;
    const { uploading } = this.state;

    return (
      <>
        {image && !uploading && (
          <Image
            src={`${API_ENDPOINT}/api/static/packing/${image}?i=${Math.ceil(Math.random() * 10000)}`}
            onClick={onClick}
            style={{ cursor: 'pointer' }}
            role="presentation"
          />
        )}
      </>
    );
  }
}

export default PackingListImage;
