import { FETCH_LAPS, FETCH_TICKETS, FETCH_2018_TICKETS } from '../actions/types';

const INITIAL_STATE = {
  laps: [],
  tickets2019: [],
  tickets2018: [],
  loading2019: true
};

export default function tickets(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_LAPS:
      return {
        ...state,
        laps: action.payload
      };
    case FETCH_TICKETS:
      return {
        ...state,
        tickets2019: action.payload,
        loading2019: false
      };
    case FETCH_2018_TICKETS:
      return {
        ...state,
        tickets2018: action.payload
      };
    default:
      return state;
  }
}
