import React from 'react';
import { connect } from 'react-redux';
import { RadialChart } from 'react-vis';
import { Card, Grid, Table, Header } from 'semantic-ui-react';

import { formatPrice } from '../../utilities';

class BookingBudget extends React.Component {
  colors = {
    price: '#40D03C',
    hotelPrice: '#30B01C',
    travelPrice: '#17A00C',
    misc: '#17800C',
    salary: '#D0403C',
    hotelCost: '#A0302C',
    rawMaterialCost: '#90302C',
    rentalCarCost: '#C03A30',
    perDiem: '#803032',
    travelCompensation: '#C4342F',
    travelCost: '#702429'
  };

  render() {
    let {
      price,
      hotelPrice,
      distance,
      travelPrice,
      salary,
      perDiem,
      hotelCost,
      rawMaterialCost,
      rentalCarCost,
      travelCompensation,
      tax
    } = this.props;

    const salaryIncludingTax = Math.round(salary + (salary * tax) / 100);
    const travelCompensationIncludingTax = Math.round(travelCompensation + (travelCompensation * tax) / 100);

    if (!(price || hotelPrice || distance || salary || perDiem || hotelCost || travelCompensation)) {
      return <div />;
    }

    if (this.props.sek && this.props.nok && this.props.dkk) {
      const currency = this.props.currency.toLowerCase();
      price = Math.round(price * this.props[currency]);
      hotelPrice = Math.round(hotelPrice * this.props[currency]);
      travelPrice = Math.round(travelPrice * this.props[currency]);
    }

    return (
      <Card fluid>
        <Card.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Header>Budget</Header>
                <RadialChart
                  animation
                  width={280}
                  height={280}
                  innerRadius={80}
                  radius={120}
                  data={[
                    {
                      amount: price,
                      color: this.colors.price
                    },
                    {
                      amount: hotelPrice,
                      color: this.colors.hotelPrice
                    },
                    {
                      amount: distance * travelPrice,
                      color: this.colors.travelPrice
                    },
                    {
                      amount: salary,
                      color: this.colors.salary
                    },
                    {
                      amount: travelCompensation,
                      color: this.colors.travelCompensation
                    },
                    {
                      amount: hotelCost,
                      color: this.colors.hotelCost
                    },
                    {
                      amount: rawMaterialCost,
                      color: this.colors.rawMaterialCost
                    },
                    {
                      amount: rentalCarCost,
                      color: this.colors.rentalCarCost
                    },
                    {
                      amount: perDiem,
                      color: this.colors.perDiem
                    },
                    {
                      amount: distance * travelPrice,
                      color: this.colors.travelCost
                    }
                  ]}
                  getAngle={d => d.amount}
                  colorType="literal"
                />
              </Grid.Column>
              <Grid.Column width={8}>
                <Table basic="very" size="small">
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell
                        style={{
                          background: this.colors.price,
                          width: 1,
                          padding: 5
                        }}
                      />
                      <Table.Cell>Pris</Table.Cell>
                      <Table.Cell textAlign="right">{formatPrice(price)}</Table.Cell>
                    </Table.Row>

                    {hotelPrice > 0 && (
                      <Table.Row>
                        <Table.Cell
                          style={{
                            background: this.colors.hotelPrice,
                            width: 1,
                            padding: 5
                          }}
                        />
                        <Table.Cell>Logitillägg</Table.Cell>
                        <Table.Cell textAlign="right">{formatPrice(hotelPrice)}</Table.Cell>
                      </Table.Row>
                    )}

                    {distance * travelPrice > 0 && (
                      <Table.Row>
                        <Table.Cell
                          style={{
                            background: this.colors.travelPrice,
                            width: 1,
                            padding: 5
                          }}
                        />
                        <Table.Cell>Resetillägg</Table.Cell>
                        <Table.Cell textAlign="right">{formatPrice(distance * travelPrice)}</Table.Cell>
                      </Table.Row>
                    )}

                    <Table.Row>
                      <Table.Cell style={{ paddingBottom: 30 }} />
                      <Table.Cell style={{ paddingBottom: 30, fontWeight: 'bold' }}>Inkomster</Table.Cell>
                      <Table.Cell style={{ fontWeight: 'bold', paddingBottom: 30 }} textAlign="right">
                        {formatPrice(price + hotelPrice + distance * travelPrice)}
                      </Table.Cell>
                    </Table.Row>

                    {salaryIncludingTax > 0 && (
                      <Table.Row>
                        <Table.Cell
                          style={{
                            background: this.colors.salary,
                            width: 1,
                            padding: 5
                          }}
                        />
                        <Table.Cell>
                          Löner
                          <br />
                          <span style={{ color: '#999', fontSize: 11 }}>inkl. sociala avgifter {tax}%</span>
                        </Table.Cell>
                        <Table.Cell textAlign="right">{formatPrice(salaryIncludingTax)}</Table.Cell>
                      </Table.Row>
                    )}

                    {travelCompensationIncludingTax > 0 && (
                      <Table.Row>
                        <Table.Cell
                          style={{
                            background: this.colors.travelCompensation,
                            width: 1,
                            padding: 5
                          }}
                        />
                        <Table.Cell>
                          Reseersättningar
                          <br />
                          <span style={{ color: '#999', fontSize: 11 }}>inkl. sociala avgifter {tax}%</span>
                        </Table.Cell>
                        <Table.Cell textAlign="right">{formatPrice(travelCompensationIncludingTax)}</Table.Cell>
                      </Table.Row>
                    )}

                    {hotelCost > 0 && (
                      <Table.Row>
                        <Table.Cell
                          style={{
                            background: this.colors.hotelCost,
                            width: 1,
                            padding: 5
                          }}
                        />
                        <Table.Cell>Hotell</Table.Cell>
                        <Table.Cell textAlign="right">{formatPrice(hotelCost)}</Table.Cell>
                      </Table.Row>
                    )}

                    {rawMaterialCost > 0 && (
                      <Table.Row>
                        <Table.Cell
                          style={{
                            background: this.colors.hotelCost,
                            width: 1,
                            padding: 5
                          }}
                        />
                        <Table.Cell>Råvaror</Table.Cell>
                        <Table.Cell textAlign="right">{formatPrice(rawMaterialCost)}</Table.Cell>
                      </Table.Row>
                    )}

                    {rentalCarCost > 0 && (
                      <Table.Row>
                        <Table.Cell
                          style={{
                            background: this.colors.hotelCost,
                            width: 1,
                            padding: 5
                          }}
                        />
                        <Table.Cell>Hyrbilar</Table.Cell>
                        <Table.Cell textAlign="right">{formatPrice(rentalCarCost)}</Table.Cell>
                      </Table.Row>
                    )}

                    {perDiem > 0 && (
                      <Table.Row>
                        <Table.Cell
                          style={{
                            background: this.colors.perDiem,
                            width: 1,
                            padding: 5
                          }}
                        />
                        <Table.Cell>Traktamenten</Table.Cell>
                        <Table.Cell textAlign="right">{formatPrice(perDiem)}</Table.Cell>
                      </Table.Row>
                    )}

                    {distance * travelPrice > 0 && (
                      <Table.Row>
                        <Table.Cell
                          style={{
                            background: this.colors.travelCost,
                            width: 1,
                            padding: 5
                          }}
                        />
                        <Table.Cell>Bilkostnad</Table.Cell>
                        <Table.Cell textAlign="right">{formatPrice(distance * travelPrice)}</Table.Cell>
                      </Table.Row>
                    )}

                    <Table.Row>
                      <Table.Cell style={{ paddingBottom: 30 }} />
                      <Table.Cell style={{ fontWeight: 'bold', paddingBottom: 30 }}>Utgifter</Table.Cell>
                      <Table.Cell style={{ fontWeight: 'bold', paddingBottom: 30 }} textAlign="right">
                        {formatPrice(
                          salaryIncludingTax +
                            perDiem +
                            hotelCost +
                            rawMaterialCost +
                            rentalCarCost +
                            travelCompensationIncludingTax +
                            distance * travelPrice
                        )}
                      </Table.Cell>
                    </Table.Row>

                    <Table.Row>
                      <Table.Cell style={{ borderTop: '1px solid #333' }} />
                      <Table.Cell style={{ borderTop: '1px solid #333' }}>
                        <Header>Vinst</Header>
                      </Table.Cell>
                      <Table.Cell style={{ borderTop: '1px solid #333' }} textAlign="right">
                        <Header>
                          {formatPrice(
                            price +
                              hotelPrice +
                              distance * travelPrice -
                              (salaryIncludingTax +
                                perDiem +
                                hotelCost +
                                rawMaterialCost +
                                rentalCarCost +
                                travelCompensationIncludingTax +
                                distance * travelPrice)
                          )}
                        </Header>
                      </Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { tax, nok, dkk } = settings;

  return {
    tax,
    sek: 1,
    nok,
    dkk
  };
};

export default connect(mapStateToProps)(BookingBudget);
