import React from 'react';
import { connect } from 'react-redux';
import { Tab, Header, Table, Icon, Label, Menu } from 'semantic-ui-react';

import ViewTicket from '../ViewTicket';

class Shipping extends React.Component {
  state = {
    activeIndex: 1,
    view: null
  };

  renderTickets(sent, all = false) {
    return (
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Biljett</Table.HeaderCell>
            <Table.HeaderCell>Beställare</Table.HeaderCell>
            <Table.HeaderCell>Adress</Table.HeaderCell>
            <Table.HeaderCell style={{ width: 60 }} />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.props.tickets2019
            .filter(t => (t.shipping && t.sent === sent) || (t.shipping && all))
            .map((t, index) => (
              <Table.Row
                style={{ cursor: 'pointer' }}
                key={`ticket${index}`}
                onClick={() => this.setState({ view: t })}
              >
                <Table.Cell style={{ width: 80 }}>T0{t.ticket}</Table.Cell>
                <Table.Cell style={{ width: 180 }}>{t.name}</Table.Cell>
                <Table.Cell>{t.address}</Table.Cell>
                <Table.Cell textAlign="right">
                  {t.shipping && (
                    <Icon color={t.sent ? 'green' : 'red'} size="large" name={`mail${t.sent ? ' outline' : ''}`} />
                  )}
                </Table.Cell>
              </Table.Row>
            ))}
        </Table.Body>
      </Table>
    );
  }

  render() {
    const notSent = this.props.tickets2019.filter(t => t.shipping && !t.sent).length;

    return (
      <>
        <ViewTicket
          open={this.state.view !== null}
          close={() => this.setState({ view: null })}
          ticket={this.state.view}
        />
        <Tab
          activeIndex={this.state.activeIndex}
          onTabChange={(_, { activeIndex }) => {
            if (activeIndex > 0) {
              this.setState({ activeIndex });
            }
          }}
          panes={[
            {
              menuItem: (
                <Menu.Item as={Header} style={{ marginLeft: -24 }} key="header">
                  Hemleverans
                </Menu.Item>
              ),
              render: () => {}
            },
            {
              menuItem: (
                <Menu.Item key="notsent">
                  Att behandla
                  <Label color={notSent ? 'red' : 'green'}>{notSent}</Label>
                </Menu.Item>
              ),
              render: () => this.renderTickets(false)
            },
            {
              menuItem: (
                <Menu.Item key="sent">
                  Skickade{' '}
                  <Label color="black">{this.props.tickets2019.filter(t => t.shipping).length - notSent}</Label>
                </Menu.Item>
              ),
              render: () => this.renderTickets(true)
            },
            {
              menuItem: <Menu.Item key="all">Alla</Menu.Item>,
              render: () => this.renderTickets(true, true)
            }
          ]}
        />
      </>
    );
  }
}

const mapStateToProps = ({ tickets }) => {
  const { tickets2019 } = tickets;

  return { tickets2019 };
};

export default connect(mapStateToProps)(Shipping);
