import moment from 'moment';
import {
  BOOKING_LIST_FILTER,
  SET_BOOKING_LIST_INTERVAL,
  FETCH_CARS,
  FETCH_CHAINS,
  FETCH_CUSTOMERS,
  FETCH_POSITIONS,
  FETCH_CUSTOMER_CATEGORIES,
  FETCH_EVENTS,
  FETCH_STAFF,
  LOGOUT
} from '../actions/types';

const INITIAL_STATE = {
  start: moment().format('YYYY-MM-DD'),
  end: '3000-01-01',
  activeInterval: 'upcoming',
  cars: [],
  categories: [],
  chains: [],
  customers: [],
  customerCategories: [],
  events: [],
  positions: [],
  staff: [],
  bookingListBackground: false,
  desc: false,
  eventsVisible: true,
  missionsOpen: true,
  missionsCompleted: true
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case SET_BOOKING_LIST_INTERVAL:
      return {
        ...state,
        start: action.payload.start,
        end: action.payload.end,
        activeInterval: action.payload.interval,
        desc: action.payload.desc
      };
    case FETCH_CARS:
      return {
        ...state,
        cars: action.payload
      };
    case FETCH_CHAINS:
      return {
        ...state,
        chains: action.payload
      };
    case FETCH_CUSTOMERS:
      return {
        ...state,
        customers: action.payload
      };
    case FETCH_CUSTOMER_CATEGORIES:
      return {
        ...state,
        customerCategories: action.payload
      };
    case FETCH_EVENTS:
      return {
        ...state,
        events: action.payload
      };
    case FETCH_POSITIONS:
      return {
        ...state,
        positions: action.payload
      };
    case FETCH_STAFF:
      return {
        ...state,
        staff: action.payload
      };
    case BOOKING_LIST_FILTER:
      return {
        ...state,
        [action.payload]: !state[action.payload]
      };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
