import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';

import { setLastStaff, fetchStaff } from '../../actions';

import { API_ENDPOINT } from '../../config.js';
import { Button, Checkbox, Form, Grid, Header, Icon, Input, Loader, Table, Dropdown } from 'semantic-ui-react';
import Textarea from 'react-textarea-autosize';

import StaffStatistics from './StaffStatistics';
import BookingList from '../bookings/BookingList';

const INITIAL_STATE = {
  loading: true,
  saved: true,
  id: 0,
  accessLevel: 0,
  username: '',
  name: '',
  ssn: '',
  email: '',
  phone: '',
  address: '',
  bank: '',
  accountNumber: '',
  notes: '',
  active: true,
  created: '',
  updated: '',
};

class Staff extends React.Component {
  state = INITIAL_STATE;

  componentDidMount() {
    this.fetchStaff(this.props.match.params.id);
  }

  componentWillReceiveProps(nextProps) {
    window.scrollTo(0, 0);

    const { id } = nextProps.match.params;

    if (id !== 'new' && id !== this.state.id) {
      this.props.setLastStaff(id);
    }
    this.fetchStaff(id);
  }

  componentWillUnmount() {
    this.setState(INITIAL_STATE);
  }

  async fetchStaff(id) {
    if (id === 'new') {
      this.setState({
        ...INITIAL_STATE,
        loading: false,
      });
    } else {
      const { data } = await axios.get(`${API_ENDPOINT}/api/staff/${id}`);

      this.setState({
        id,
        ...data,
        loading: false,
        saved: true,
      });
    }
  }

  onChange(type, value) {
    this.setState({
      [type]: value,
      saved: false,
    });
  }

  submit(event) {
    event.preventDefault();

    axios.put(`${API_ENDPOINT}/api/staff/${this.props.match.params.id}`, this.state).then(({ data }) => {
      this.props.fetchStaff();
      if (this.state.id === 0) {
        this.props.history.push(`/staff/${data}`);
      } else {
        this.fetchStaff(this.props.match.params.id);
      }
    });
  }

  render() {
    if (this.state.loading) {
      return <Loader style={{ marginLeft: 100 }} size="massive" active />;
    }

    return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={8}>
            <Form onSubmit={this.submit.bind(this)}>
              <Table basic="very">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell style={{ width: 160, textAlign: 'center' }}>
                      <Checkbox
                        toggle
                        checked={this.state.active}
                        onChange={() => {
                          if (this.state.new) {
                            this.setState({ active: !this.state.active });
                          } else {
                            axios
                              .patch(`${API_ENDPOINT}/api/staff/${this.state.id}/active`, {
                                active: !this.state.active,
                              })
                              .then(() => {
                                this.setState({ active: !this.state.active });
                              });
                          }
                        }}
                      />
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="Förnamn Efternamn"
                        style={{ fontSize: 24 }}
                        value={this.state.name}
                        onChange={(input) => this.onChange('name', input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Behörighet</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        fluid
                        selection
                        value={this.state.accessLevel}
                        onChange={(event, data) => {
                          this.setState({
                            accessLevel: data.value,
                            saved: false,
                          });
                          if (data.value > 0 && !this.state.username && this.state.name.length) {
                            this.setState({
                              username: this.state.name.substring(0, this.state.name.indexOf(' ')).toLowerCase(),
                            });
                          }
                          window.scrollTo(0, 0);
                        }}
                        options={[
                          [0, 'Personal (ingen inloggning)'],
                          [1, 'Administratör (Visa allt)'],
                          [2, 'Bokningsansvarig'],
                          [3, 'Waterslide Obstacle Rush'],
                          [4, 'Waterslide Obstacle Rush (Ej ekonomi)'],
                        ].reduce(
                          (acc, next) => [...acc, { key: `accessLevel${next[0]}`, value: next[0], text: next[1] }],
                          []
                        )}
                      />
                    </Table.Cell>
                  </Table.Row>
                  {this.state.accessLevel !== 0 && (
                    <Table.Row>
                      <Table.Cell textAlign="right">
                        <Header sub>Användarnamn</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Input
                          fluid
                          value={this.state.username}
                          onChange={(input) => this.onChange('username', input.target.value.toLowerCase().trim())}
                        />
                      </Table.Cell>
                    </Table.Row>
                  )}
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Epost</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="hej@exempel.se"
                        value={this.state.email}
                        onChange={(input) => this.onChange('email', input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Telefon</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="070-12 34 567"
                        value={this.state.phone}
                        onChange={(input) => this.onChange('phone', input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Personnummer</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        placeholder="YYYYYMMDD-XXXX"
                        value={this.state.ssn}
                        onChange={(input) => this.onChange('ssn', input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Adress</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Textarea
                        style={{ minHeight: 80 }}
                        value={this.state.address}
                        onChange={(input) => this.onChange('address', input.target.value)}
                        autoHeight
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Bank</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        value={this.state.bank}
                        onChange={(input) => this.onChange('bank', input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Kontonummer</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Input
                        fluid
                        value={this.state.accountNumber}
                        onChange={(input) => this.onChange('accountNumber', input.target.value)}
                      />
                    </Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Interna anteckningar</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Textarea
                        value={this.state.notes}
                        onChange={(input) => this.onChange('notes', input.target.value)}
                        autoHeight
                      />
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <Button color={this.state.saved ? 'grey' : 'green'} fluid>
                <Icon name="save" />
                {this.props.match.params.id === 'new' ? 'Lägg till personal' : this.state.saved ? 'Sparat!' : 'Spara'}
              </Button>
            </Form>
          </Grid.Column>
          <Grid.Column width={8} style={{ marginTop: 10 }}>
            {this.state.id !== 0 && (
              <StaffStatistics
                id={this.state.id}
                delete={() => {
                  this.props.fetchStaff();
                  this.props.history.push('/staff/new');
                }}
              />
            )}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>{this.state.id !== 0 && <BookingList staff={this.state.id} />}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default connect(null, { setLastStaff, fetchStaff })(Staff);
