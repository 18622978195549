import React from "react";
import { connect } from "react-redux";
import { Card, Button, Dropdown, Header } from "semantic-ui-react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { WEB_ENDPOINT } from "../../config";

class BookingMail extends React.Component {
  state = {
    reference: "",
    email: "",
    copyPopup: false,
  };

  componentDidMount() {
    const contact = this.props.contacts.find((contact) => contact.reference);
    if (contact) {
      this.setState({ reference: String(contact.id), email: contact.email });
    } else if (this.props.contacts.length > 0) {
      this.setState({
        reference: String(this.props.contacts[0].id),
        email: this.props.contacts[0].email,
      });
    }
  }

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Header>Bekräftelse</Header>
          <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1 }}>
              <Dropdown
                fluid
                style={{ borderBottomRightRadius: 0, borderTopRightRadius: 0 }}
                placeholder="Välj referens"
                selection
                value={this.state.reference}
                onChange={(event, data) => {
                  this.setState({
                    reference: data.value,
                    email: this.props.contacts.find(
                      (contact) => String(contact.id) === data.value
                    ).email,
                  });
                }}
                options={this.props.contacts.reduce((acc, next) => {
                  return [
                    ...acc,
                    {
                      key: String(next.id),
                      value: String(next.id),
                      text: `${next.name} (${next.email || "ingen epost"})`,
                    },
                  ];
                }, [])}
              />
            </div>
            <Button
              color="black"
              as="a"
              style={{ borderBottomLeftRadius: 0, borderTopLeftRadius: 0 }}
              href={`mailto:${
                this.state.email
              }?subject=Eventbekräftelse${encodeURI(
                ` – ${
                  this.props.customName
                    ? this.props.customName
                    : this.props.event
                }`
              )}&body=${encodeURI(
                this.props.mailTemplate
                  .replace("LÄNK", `${WEB_ENDPOINT}/e/${this.props.token}`)
                  .replace("EVENT", this.props.event)
              )}`}
              icon="mail"
            >
              {/* <Icon name="mail" /> Bekräftelsemail */}
            </Button>
            {/* <Popup
              trigger={
                <Button fluid>
                  <Icon name="copy"  {`${WEB_ENDPOINT}/e/${this.props.token}`}
                </Button>
              }
              content={`Kopierad!`}
              on="click"
              open={this.state.copyPopup}
              onClose={() => {
                this.setState({ copyPopup: false });
                clearTimeout(this.timeout);
              }}
              onOpen={() => {
                this.setState({ copyPopup: true });

                this.timeout = setTimeout(() => {
                  this.setState({ copyPopup: false });
                }, 1000);
              }}
              position="left center"
            /> */}
            <CopyToClipboard
              text={`${WEB_ENDPOINT}/e/${this.props.token}`}
              onCopy={() => this.setState({ copied: true })}
            >
              <Button icon="copy">
                {/* <Icon name="copy" /> {`${WEB_ENDPOINT}/e/${this.props.token}`} */}
              </Button>
            </CopyToClipboard>
          </div>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { mailTemplate } = settings;

  return {
    mailTemplate,
  };
};

export default connect(mapStateToProps)(BookingMail);
