import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { Header, Table, Loader, Button } from 'semantic-ui-react';
import { navigate, setTicketCity, fetch2018Tickets } from '../../actions';

class Tickets2018 extends React.Component {
  state = { loading: 0 };

  componentDidMount() {
    this.props.setTicketCity('tickets2018');
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({ loading: 0 });
  }

  render() {
    if (this.props.tickets2018.length === 0) {
      return (
        <div style={{ height: '100vh' }}>
          <Loader size="massive" active />
        </div>
      );
    }

    return (
      <>
        <Header>Biljetter 2018</Header>
        <Table>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Biljett</Table.HeaderCell>
              <Table.HeaderCell>Epost</Table.HeaderCell>
              <Table.HeaderCell>Deltagare</Table.HeaderCell>
              <Table.HeaderCell>Anteckningar</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.tickets2018
              .filter(
                t =>
                  !this.props.search ||
                  t.ticket.toLowerCase().includes(this.props.search) ||
                  t.email.toLowerCase().includes(this.props.search) ||
                  t.participants.toLowerCase().includes(this.props.search)
              )
              .map(t => (
                <Table.Row key={t.ticket}>
                  <Table.Cell>{t.ticket}</Table.Cell>
                  <Table.Cell>{t.email}</Table.Cell>
                  <Table.Cell style={{ width: 400 }}>
                    <div dangerouslySetInnerHTML={{ __html: `${t.participants.replace(/\n/g, '<br />')}` }} />
                  </Table.Cell>
                  <Table.Cell>{t.notes}</Table.Cell>
                  <Table.Cell textAlign="right">
                    <Button
                      style={{ margin: 2 }}
                      circular
                      color={t.picked_up ? 'green' : 'grey'}
                      icon={this.state.loading === t.id ? 'wait' : t.picked_up ? 'checkmark box' : 'square outline'}
                      onClick={() => {
                        this.setState({ loading: t.id });
                        axios.patch(`${API_ENDPOINT}/api/tickets/pickedup2018`, { id: t.id }).then(() => {
                          this.props.fetch2018Tickets();
                        });
                      }}
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </>
    );
  }
}

const mapStateToProps = ({ tickets }) => {
  const { tickets2018 } = tickets;

  return { tickets2018 };
};

export default connect(
  mapStateToProps,
  { navigate, setTicketCity, fetch2018Tickets }
)(Tickets2018);
