import React from 'react';
import { connect } from 'react-redux';
import { Grid, Statistic, Loader } from 'semantic-ui-react';
import moment from 'moment';

class TicketsSold extends React.Component {
  renderDaysLeft(diff) {
    if (diff > 1) {
      return `${diff} dagar kvar`;
    } else if (diff === 1) {
      return 'Imorgon';
    } else if (diff === 0) {
      return 'Idag!';
    } else {
      return '- KLART -';
    }
  }

  render() {
    return (
      <Grid verticalAlign="middle" style={{ marginTop: 5 }} columns="equal">
        <Grid.Row style={{ minHeight: 158 }}>
          {this.props.laps.map((lap, index) => {
            const today = moment(moment().format('YYYY-MM-DD'));
            const raceDay = moment(lap.date);
            const diff = raceDay.diff(today, 'days');

            return (
              <Grid.Column key={`lap${index}`} textAlign="center">
                {this.props.loading2019 ? (
                  <Loader active size="large" />
                ) : (
                  <Statistic style={{ marginBottom: 24 }}>
                    <Statistic.Value>
                      {this.props.tickets2019
                        .filter(ticket => ticket.total_price > 0)
                        .reduce((acc, next) => acc + (next.lap === lap.id ? next.participants.length : 0), 0)}
                    </Statistic.Value>
                    <Statistic.Label style={{ marginTop: 8 }}>
                      <span style={{ fontSize: 18 }}>{lap.city}</span>
                      <br />
                      <p style={{ marginTop: 4, fontSize: 14, color: '#777' }}>{this.renderDaysLeft(diff)}</p>
                    </Statistic.Label>
                  </Statistic>
                )}
              </Grid.Column>
            );
          })}
          <Grid.Column textAlign="center">
            {this.props.loading2019 ? (
              <Loader active size="large" />
            ) : (
              <Statistic style={{ marginBottom: 24 }}>
                <Statistic.Value>
                  {this.props.tickets2019
                    .filter(ticket => ticket.total_price === 0)
                    .reduce((acc, next) => acc + next.participants.length, 0)}
                </Statistic.Value>
                <Statistic.Label>Gratis</Statistic.Label>
              </Statistic>
            )}
          </Grid.Column>
          <Grid.Column textAlign="center">
            {this.props.loading2019 ? (
              <Loader active size="large" />
            ) : (
              <Statistic style={{ marginBottom: 24 }}>
                <Statistic.Value>
                  {this.props.tickets2019.reduce((acc, next) => acc + next.participants.length, 0)}
                </Statistic.Value>
                <Statistic.Label>Totalt</Statistic.Label>
              </Statistic>
            )}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = ({ tickets }) => {
  const { laps, tickets2019, loading2019 } = tickets;

  return { laps, tickets2019, loading2019 };
};

export default connect(mapStateToProps)(TicketsSold);
