import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { Card, Rating, Form, Grid, Icon, Dropdown, Checkbox, Input, Label, Button } from 'semantic-ui-react';

const INITIAL_STATE = {
  id: 0,
  name: '',
  phone: '',
  mobile: '',
  email: '',
  position: 0,
  relation: 0,
  reference: false,
  new: false,
  edit: false,
  delete: false,
  loading: true
};

class ContactCard extends React.Component {
  state = INITIAL_STATE;

  componentDidMount() {
    if (this.props.contact) {
      axios.get(`${API_ENDPOINT}/api/contacts/${this.props.contact || this.state.id}`).then(({ data }) => {
        this.setState({ ...data, loading: false });
      });
    } else {
      this.setState({ position: 1, edit: true, new: true, loading: false });
    }
  }

  save() {
    if (this.state.new) {
      axios
        .post(`${API_ENDPOINT}/api/contacts`, {
          customer: this.props.customer,
          ...this.state
        })
        .then(() => {
          this.setState(INITIAL_STATE);
          this.props.saveNewContact();
        });
    } else {
      axios.patch(`${API_ENDPOINT}/api/contacts/${this.props.contact || this.state.id}`, this.state).then(() => {
        this.setState({ edit: false });
      });
    }
  }

  renderButtons() {
    if (this.state.new) {
      return (
        <>
          <Button
            disabled={this.state.name === ''}
            color="green"
            style={{
              border: 0,
              position: 'absolute',
              top: 15,
              right: 14,
              width: 145
            }}
            onClick={this.save.bind(this)}
          >
            <Icon name="save" />
            Lägg till
          </Button>
          <Button
            style={{
              border: 0,
              position: 'absolute',
              top: 64,
              right: 14,
              width: 145
            }}
            onClick={this.props.cancelNewContact}
          >
            <Icon name="cancel" />
            Avbryt
          </Button>
        </>
      );
    } else if (this.state.delete) {
      return (
        <>
          <Button
            color="red"
            style={{
              border: 0,
              position: 'absolute',
              top: 15,
              right: 14,
              width: 145
            }}
            onClick={() => {
              axios.delete(`${API_ENDPOINT}/api/contacts/${this.state.id}`).then(() => {
                this.setState({ loading: true });
              });
            }}
          >
            Är du säker?
          </Button>
          <Button
            style={{
              border: 0,
              position: 'absolute',
              top: 64,
              right: 14,
              width: 145
            }}
            onClick={() => this.setState({ delete: false })}
          >
            <Icon name="cancel" />
            Avbryt
          </Button>
        </>
      );
    } else if (this.state.edit) {
      return (
        <>
          <Button
            color="green"
            style={{
              border: 0,
              position: 'absolute',
              top: 15,
              right: 14
            }}
            onClick={this.save.bind(this)}
          >
            <Icon name="save" />
            Spara
          </Button>
          <Button
            icon="cancel"
            style={{
              border: 0,
              position: 'absolute',
              top: 15,
              right: 120
            }}
            onClick={() => {
              axios.get(`${API_ENDPOINT}/api/contacts/${this.state.id}`).then(({ data }) => {
                this.setState({ ...data, edit: false });
              });
            }}
          />
          <Button
            color="red"
            style={{
              border: 0,
              position: 'absolute',
              top: 64,
              right: 14,
              width: 145
            }}
            onClick={() => this.setState({ delete: true })}
          >
            <Icon name="trash" />
            Radera
          </Button>
        </>
      );
    } else {
      return (
        <Button
          style={{
            border: 0,
            position: 'absolute',
            top: 15,
            right: 14
          }}
          onClick={() => this.setState({ edit: true })}
          icon="edit"
        />
      );
    }
  }

  render() {
    if (this.state.loading) {
      return <div />;
    }

    const { name, phone, mobile, email, position, relation, reference, edit } = this.state;

    return (
      <Card fluid raised={edit} style={{ padding: 5, background: '#fffff2' }}>
        <Card.Content>
          {this.renderButtons()}
          <Card.Header>
            {edit ? (
              <Input
                style={{ width: 300 }}
                value={name}
                onChange={input => this.setState({ name: input.target.value })}
              />
            ) : (
              <h3>{name}</h3>
            )}
          </Card.Header>
          <Dropdown
            style={{ width: 300 }}
            selection={edit}
            disabled={!edit}
            icon={edit ? 'dropdown' : ''}
            value={position}
            options={this.props.positions.reduce((acc, next) => {
              return [...acc, { value: next.id, text: next.position }];
            }, [])}
            onChange={(event, data) => this.setState({ position: data.value })}
          />
        </Card.Content>
        <Card.Content extra style={{ color: '#000' }}>
          <Form>
            <Form.Group widths="equal">
              <Form.Field>
                {(phone || edit) && (
                  <>
                    <Label
                      style={{
                        fontSize: 16,
                        fontWeight: '100',
                        color: '#000',
                        marginLeft: -8,
                        marginBottom: -1,
                        background: '#fffff2',
                        border: 'none'
                      }}
                    >
                      Telefon
                    </Label>
                    <Input
                      fluid
                      value={phone}
                      onChange={input => edit && this.setState({ phone: input.target.value })}
                    />
                  </>
                )}
              </Form.Field>
              <Form.Field>
                {(mobile || edit) && (
                  <>
                    <Label
                      style={{
                        fontSize: 16,
                        fontWeight: '100',
                        color: '#000',
                        marginLeft: -8,
                        marginBottom: -1,
                        background: '#fffff2',
                        border: 'none'
                      }}
                    >
                      Mobil
                    </Label>
                    <Input
                      fluid
                      value={mobile}
                      onChange={input => edit && this.setState({ mobile: input.target.value })}
                    />
                  </>
                )}
              </Form.Field>
            </Form.Group>
            <Form.Field>
              {(email || edit) && (
                <>
                  <Label
                    style={{
                      fontSize: 16,
                      fontWeight: '100',
                      color: '#000',
                      marginLeft: -8,
                      marginBottom: -1,
                      background: '#fffff2',
                      border: 'none'
                    }}
                  >
                    Epost
                  </Label>
                  <Input
                    fluid
                    size="large"
                    value={email}
                    onChange={input =>
                      edit &&
                      this.setState({
                        email: input.target.value.toLowerCase()
                      })
                    }
                  />
                </>
              )}
            </Form.Field>
          </Form>
        </Card.Content>
        <Card.Content extra>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8}>
                <Rating
                  maxRating={edit ? 5 : relation}
                  rating={relation}
                  disabled={!edit}
                  onRate={(event, data) => this.setState({ relation: data.rating })}
                  icon="star"
                  size="huge"
                  clearable
                />
              </Grid.Column>
              <Grid.Column width={8}>
                {(reference || edit) && (
                  <Checkbox
                    checked={reference}
                    label="Referens"
                    onChange={() => edit && this.setState({ reference: !reference })}
                  />
                )}
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = ({ app }) => {
  const { positions } = app;

  return { positions };
};

export default connect(mapStateToProps)(ContactCard);
