import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Form,
  Header,
  Icon,
  Input,
  Item,
  Message,
  Popup,
  Table,
  TextArea,
} from "semantic-ui-react";
import moment from "moment";

import { fetchSettings } from "../../actions";
import { API_ENDPOINT } from "../../config";

import { SingleDatePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

class BookingInvoice extends React.Component {
  static defaultProps = {
    contacts: [],
  };

  state = {
    type: "invoice",
    creating: "",
    date: moment(),
    contacts: [],
    invoiceNr: 0,
    proposalNr: moment().format("YYYY.MM.DD-"),
    invoiceDateFocused: false,
    mountingNotes: "",
    defaultMountingNotes: "",
    notesToCustomer: "",
    defaultNotesToCustomer: "",
    reference: 0,
    invoiceNotes: "",
  };

  componentDidMount() {
    this.setState({
      invoiceNr: this.props.invoiceNr + 1,
      defaultNotesToCustomer: this.props.defaultNotesToCustomer,
      invoiceEmail: this.props.invoiceEmail,
      notesToCustomer: this.props.notesToCustomer,
      defaultMountingNotes: this.props.defaultMountingNotes,
      mountingNotes: this.props.mountingNotes,
    });

    this.props.contacts.forEach((contact) => {
      if (contact.reference) {
        this.setState({
          reference: contact.id,
        });
      }
    });
  }

  async createInvoice() {
    this.setState({ creating: this.state.type });

    await this.props.save();

    const reference = this.props.contacts.find(
      (c) => c.id === this.state.reference
    );

    const { type, invoiceNr, proposalNr, date } = this.state;
    const { invoiceNotes } = this.props;

    const notesToCustomer =
      this.state.notesToCustomer.length > 0
        ? this.state.notesToCustomer
        : this.state.defaultNotesToCustomer;
    const mountingNotes =
      this.state.mountingNotes.length > 0
        ? this.state.mountingNotes
        : this.state.defaultMountingNotes;

    axios
      .post(
        `${API_ENDPOINT}/api/bookings/${this.props.id}/pdf/${this.state.type}`,
        {
          invoiceNr,
          proposalNr,
          notesToCustomer,
          mountingNotes,
          invoiceNotes,
          date: moment(date).format("YYYY-MM-DD"),
          reference: reference ? reference.name : "",
        }
      )
      .then(({ data }) => {
        this.props.pdfCreated(type, data);
        this.setState({ creating: "" });
        this.props.fetchSettings();
        if (type === "invoice") {
          this.setState({
            invoiceNr: String(Number(this.state.invoiceNr) + 1),
            mountingNotes,
            notesToCustomer,
          });
        }
      });
  }

  renderDetails() {
    const { type } = this.state;

    switch (type) {
      case "invoice":
      case "reminder":
        return (
          <Table.Row>
            <Table.Cell textAlign="right">
              <Header>
                {type === "invoice" ? "Faktura #" : "Påminnelse #"}
              </Header>
            </Table.Cell>
            <Table.Cell>
              <Input
                fluid
                style={{ fontSize: 15 }}
                value={this.state.invoiceNr}
                onChange={(input) =>
                  this.setState({ invoiceNr: input.target.value })
                }
              />
            </Table.Cell>
          </Table.Row>
        );
      case "proposal":
      case "confirmation":
        return (
          <>
            <Table.Row>
              <Table.Cell textAlign="right">
                <Header>
                  {type === "proposal" ? "Offert #" : "Bekräftelse #"}
                </Header>
              </Table.Cell>
              <Table.Cell>
                <Input
                  fluid
                  style={{ fontSize: 15 }}
                  value={this.state.proposalNr}
                  onChange={(input) =>
                    this.setState({ proposalNr: input.target.value })
                  }
                />
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="right">
                <Header style={{ marginBottom: 0 }}>Upplysningar</Header>
                {this.state.notesToCustomer &&
                  this.state.notesToCustomer.length > 0 && (
                    <span style={{ color: "#999", fontStyle: "italic" }}>
                      redigerad
                    </span>
                  )}
              </Table.Cell>
              <Table.Cell>
                <Form>
                  <TextArea
                    autoHeight
                    value={
                      this.state.notesToCustomer === ""
                        ? this.state.defaultNotesToCustomer
                        : this.state.notesToCustomer
                    }
                    onChange={(input) =>
                      this.setState({ notesToCustomer: input.target.value })
                    }
                  />
                </Form>
              </Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell textAlign="right">
                <Header style={{ marginBottom: 0 }}>Montering</Header>
                {this.state.mountingNotes &&
                  this.state.mountingNotes.length > 0 && (
                    <span style={{ color: "#999", fontStyle: "italic" }}>
                      redigerad
                    </span>
                  )}
              </Table.Cell>
              <Table.Cell>
                <Input
                  fluid
                  style={{ fontSize: 15 }}
                  value={
                    this.state.mountingNotes === ""
                      ? this.state.defaultMountingNotes
                      : this.state.mountingNotes
                  }
                  onChange={(input) =>
                    this.setState({ mountingNotes: input.target.value })
                  }
                />
              </Table.Cell>
            </Table.Row>
          </>
        );
      default:
        return <div />;
    }
  }

  render() {
    if (this.props.positions.length === 0 || this.state.invoiceNr === 0) {
      return <div />;
    }

    const types = {
      invoice: "Faktura",
      proposal: "Offert",
      confirmation: "Bekräftelse",
      reminder: "Påminnelse",
    };

    return (
      <Card fluid>
        <Card.Content>
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell style={{ width: 100 }}>
                  <Header textAlign="right">Typ</Header>
                </Table.Cell>
                <Table.Cell>
                  <Dropdown
                    fluid
                    selection
                    style={{ fontSize: 16 }}
                    value={this.state.type}
                    onChange={(event, data) =>
                      this.setState({
                        type: data.value,
                      })
                    }
                    options={Object.keys(types).reduce(
                      (acc, next) => [
                        ...acc,
                        { key: next, value: next, text: types[next] },
                      ],
                      []
                    )}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="right">
                  <Header>Datum</Header>
                </Table.Cell>
                <Table.Cell>
                  <SingleDatePicker
                    noBorder
                    date={this.state.date}
                    onDateChange={(date) => this.setState({ date })}
                    focused={this.state.invoiceDateFocused}
                    onFocusChange={({ focused }) =>
                      this.setState({ invoiceDateFocused: focused })
                    }
                    numberOfMonths={1}
                    displayFormat="YYYY-MM-DD"
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="right">
                  <Header>Kund</Header>
                </Table.Cell>
                <Table.Cell>
                  {this.props.customerName ? (
                    <Link
                      style={{ color: "#000" }}
                      to={`/customers/${this.props.customer}`}
                    >
                      {this.props.customerName}
                    </Link>
                  ) : (
                    <i>Ingen kund vald</i>
                  )}
                </Table.Cell>
              </Table.Row>
              {this.state.invoiceEmail ? (
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>E-post</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <a
                      style={{ color: "#000" }}
                      href={`mailto:${this.props.invoiceEmail}`}
                    >
                      {this.props.invoiceEmail}
                    </a>
                  </Table.Cell>
                </Table.Row>
              ) : null}
              <Table.Row>
                <Table.Cell textAlign="right">
                  <Header>Referens</Header>
                </Table.Cell>
                <Table.Cell>
                  <Form>
                    {this.props.contacts.map((contact) => {
                      return (
                        <Item key={`contact${contact.id}`}>
                          <Checkbox
                            radio
                            name="reference"
                            value={contact.id}
                            checked={this.state.reference === contact.id}
                            label={`${contact.name}, ${
                              this.props.positions.find(
                                (position) => position.id === contact.position
                              ).position
                            }`}
                            style={{ marginTop: 5, marginRight: 12 }}
                            onChange={(event, data) =>
                              this.setState({ reference: data.value })
                            }
                          />
                        </Item>
                      );
                    })}
                  </Form>
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell textAlign="right">
                  <Header>Fritext</Header>
                </Table.Cell>
                <Table.Cell>
                  <Form>
                    <TextArea
                      fluid
                      style={{ fontSize: 15 }}
                      value={this.props.invoiceNotes}
                      onChange={(input) =>
                        this.props.onChange("invoiceNotes", input.target.value)
                      }
                    />
                  </Form>
                </Table.Cell>
              </Table.Row>
              {this.renderDetails()}
            </Table.Body>
          </Table>
          {this.props.customer ? (
            <Button fluid onClick={this.createInvoice.bind(this)} color="black">
              Skapa {types[this.state.type]}
            </Button>
          ) : (
            <Button fluid disabled color="black">
              Välj en kund...
            </Button>
          )}

          {this.state.creating.length > 0 && (
            <Message icon>
              <Icon name="circle notched" loading />
              <Message.Content>
                <Message.Header>Skapar PDF</Message.Header>
                Snart klar...
              </Message.Content>
            </Message>
          )}

          {this.props[this.state.type] && this.state.creating.length === 0 && (
            <Message
              as="a"
              href={`${API_ENDPOINT}/api/print/${this.props.id}/${
                this.state.type
              }/${this.props[this.state.type]}`}
              target="_blank"
              icon="inbox"
              onDismiss={(event) => {
                event.preventDefault();
                axios
                  .delete(
                    `${API_ENDPOINT}/api/bookings/${this.props.id}/pdf/${this.state.type}`
                  )
                  .then(() => this.props.pdfCreated(this.state.type, ""));
              }}
              header={`${this.props[this.state.type]}.pdf`}
            />
          )}
        </Card.Content>
      </Card>
    );
  }
}

const mapStateToProps = ({ app, settings }) => {
  const { positions } = app;
  const { invoiceNr } = settings;

  return {
    positions,
    invoiceNr,
  };
};

export default connect(mapStateToProps, { fetchSettings })(BookingInvoice);
