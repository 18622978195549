import moment from 'moment';

export const months = [
  'Januari',
  'Februari',
  'Mars',
  'April',
  'Maj',
  'Juni',
  'Juli',
  'Augusti',
  'September',
  'Oktober',
  'November',
  'December'
];

export const days = ['Söndag', 'Måndag', 'Tisdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lördag'];

export const formatPrice = (price, isNumber) => {
  price = String(price);
  let cents;
  [price, cents] = price.split('.');

  price = price
    .split('')
    .reverse()
    .reduce((acc, next, index) => acc + next + ((index + 1) % 3 === 0 ? ' ' : ''), '')
    .split('')
    .reverse()
    .join('');

  if (cents) {
    price = `${price}:${cents}`;
    if (cents.length === 1) {
      price = price + '0';
    }
  } else if (!isNumber) {
    price = `${price}:-`;
  }
  return price;
};

export const formatDate = (startdate, enddate, duration) => {
  const startMonth = months[moment(startdate).format('M') - 1].substring(0, 3).toLowerCase();
  const endMonth = months[moment(enddate).format('M') - 1].substring(0, 3).toLowerCase();

  if (duration === 1) {
    return `${moment(startdate).format('D')} ${startMonth}`;
  }

  return startMonth === endMonth
    ? `${moment(startdate).format('D')} - ${moment(enddate).format('D')} ${startMonth}`
    : `${moment(startdate).format('D')} ${startMonth} - ${moment(enddate).format('D')} ${endMonth}`;
};

export const formatSize = size => {
  if (size > 100000) {
    return `${(size / 1000000).toPrecision(3)} MB`;
  } else {
    return `${(size / 1000).toPrecision(3)} KB`;
  }
};
