import { LOGIN, LOGOUT } from './types';
import md5 from 'md5';
import sha256 from 'sha256';
import axios from 'axios';

import { API_ENDPOINT } from '../config';

export function login(username, password) {
  return dispatch => {
    axios
      .post(`${API_ENDPOINT}/api/login`, {
        username,
        password: sha256(md5(password))
      })
      .then(({ data }) => {
        const { error, name, email, accessLevel, token } = data;
        localStorage.setItem('magnet', token);
        axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
        dispatch({
          type: LOGIN,
          payload: {
            username,
            name,
            email,
            accessLevel,
            error: error || ''
          }
        });
      })
      .catch(error => {
        console.error(error);
      });
  };
}

export function logout() {
  localStorage.removeItem('magnet');
  return {
    type: LOGOUT
  };
}

export function setUserOnStartup() {
  return dispatch => {
    const token = localStorage.getItem('magnet');
    if (token) {
      axios
        .post(
          `${API_ENDPOINT}/api/verify`,
          {},
          {
            headers: { Authorization: `Bearer ${token}` }
          }
        )
        .then(({ data }) => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
          dispatch({
            type: LOGIN,
            payload: data
          });
        })
        .catch(error => {
          console.error(error);
          dispatch({
            type: LOGIN,
            payload: { error: 'User not logged in' }
          });
        });
    } else {
      dispatch({
        type: LOGIN,
        payload: { error: 'User not logged in' }
      });
    }
  };
}
