import React, { Component } from 'react';
import 'react-dates/initialize';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Loader } from 'semantic-ui-react';
import { connect } from 'react-redux';
import moment from 'moment';

import { setUserOnStartup, fetchCars } from './actions';

import Mail from './components/mail/Mail';
import PublicPackingList from './components/packing/PublicPackingList';
import Login from './components/Login';
import AppRouter from './components/AppRouter';

import './App.css';

class App extends Component {
  componentDidMount() {
    moment.locale('sv');
    this.props.setUserOnStartup();
  }

  render() {
    if (this.props.loading) {
      return <Loader size="massive" active />;
    }
    return (
      <BrowserRouter>
        <div style={{ minHeight: '100vh' }}>
          <Switch>
            <Route path="/e/:token" component={Mail} />
            <Route path="/p/:id" component={PublicPackingList} />
            {this.props.loggedIn ? <AppRouter /> : <Login />}
          </Switch>
        </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = ({ user }) => {
  return {
    loggedIn: user.loggedIn,
    loading: user.loading,
  };
};

export default connect(mapStateToProps, { setUserOnStartup, fetchCars })(App);
