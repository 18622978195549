import React from "react";
import { connect } from "react-redux";
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Image,
  Input,
  Loader,
  Menu,
  Popup,
  Table,
} from "semantic-ui-react";
import Textarea from "react-textarea-autosize";
import { API_ENDPOINT } from "../../config";
import { days, months, formatPrice } from "../../utilities";
import { navigate } from "../../actions";
import moment from "moment-timezone";
import axios from "axios";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

import { DateRangePicker } from "react-dates";
import "react-dates/lib/css/_datepicker.css";

import BookingInvoice from "./BookingInvoice";
import BookingMail from "./BookingMail";
import BookingBudget from "./BookingBudget";
import BookingPhotos from "./BookingPhotos";
import BookingFiles from "./BookingFiles";
import BookingStaff from "./BookingStaff";
import BookingPackingLists from "./BookingPackingLists";
import BookingSMS from "./BookingSMS";
import TechnicalInfo from "./TechnicalInfo";

import "./datepicker.css";
import "./booking.css";
import "../utilities/quill.css";
import { modules } from "../utilities/quill";

class Booking extends React.Component {
  state = {
    arrival: "",
    completed: false,
    confirmation: "",
    confirmed: false,
    contacts: [],
    currency: "SEK",
    customer: 0,
    customerContact: "",
    customerName: "",
    customerNotesToStaff: "",
    customName: "",
    defaultMountingNotes: "",
    defaultNotesToCustomer: "",
    discount: 0,
    discountDescription: "",
    distance: 0,
    distancePerCar: 0,
    duration: 1,
    enddate: moment.utc(),
    event: 36,
    eventInserted: false,
    files: [],
    focusedInput: null,
    hotelCost: 0,
    hotelInfo: "",
    hotelPrice: 0,
    hours: ["00:00-00:00"],
    id: 0,
    internalFollowUp: "",
    internalNotes: "",
    invoice: "",
    invoiceEmail: "",
    invoiceNotes: "",
    loadingBooking: true,
    location: "",
    map: "",
    mapVisible: false,
    mountingNotes: "",
    newEvent: false,
    newStaff: null,
    notesFromCustomer: "",
    notesToCustomer: "",
    notesToStaff: "",
    nrOfCars: 1,
    price: 0,
    privateInternalNotes: "",
    proposal: "",
    quillEnabled: false,
    rawMaterialCost: 0,
    reminder: "",
    rentalCarCost: 0,
    saved: true,
    selectedCars: [],
    selectedStaff: [],
    sendSMS: true,
    smsBeforeText: "",
    smsBeforeTextTemplate: "",
    staff: [],
    staffFollowUp: "",
    staffNeeded: 0,
    startdate: moment.utc(),
    technicalInfo: "",
    technicalInfoVisible: false,
    token: "",
    travelCompensation: "-1",
    travelPrice: "-1",
  };

  componentDidMount() {
    moment.locale("sv");
    this.props.navigate("bookings", this.props.match.params.id || "new");

    if (!this.props.match.params.id) {
      this.setState({ loadingBooking: false, newEvent: true });
    } else {
      this.fetchBooking();
    }
  }

  componentWillReceiveProps(nextProps) {
    this.props.navigate("bookings", nextProps.match.params.id || "new");
    if (this.state.travelPrice === "-1" && nextProps.milage !== 0) {
      this.setState({ travelPrice: String(nextProps.milage) });
    }

    if (this.state.travelCompensation === "-1" && nextProps.travelCompensation !== 0) {
      this.setState({
        travelCompensation: String(nextProps.travelCompensation),
      });
    }

    if (this.state.smsBeforeTextTemplate === "" && nextProps.smsBefore) {
      this.setState({ smsBeforeTextTemplate: nextProps.smsBefore });
    }
  }

  componentDidUpdate() {
    if (this.state.travelPrice === "-1" && this.props.milage !== 0) {
      this.setState({ travelPrice: String(this.props.milage) });
    }

    if (this.state.travelCompensation === "-1" && this.props.travelCompensation !== 0) {
      this.setState({
        travelCompensation: String(this.props.travelCompensation),
      });
    }

    if (this.state.smsBeforeTextTemplate === "" && this.props.smsBefore) {
      this.setState({ smsBeforeTextTemplate: this.props.smsBefore });
    }
  }

  fetchBooking() {
    axios.get(`${API_ENDPOINT}/api/bookings/${this.props.match.params.id}`).then(({ data }) => {
      const {
        arrival,
        cars: nrOfCars,
        completed,
        confirmation,
        confirmed,
        contacts,
        currency,
        custom_name: customName,
        customer,
        customer_contact: customerContact,
        customer_name: customerName,
        customer_notes_to_staff: customerNotesToStaff,
        default_mounting_notes: defaultMountingNotes,
        default_notes_to_customer: defaultNotesToCustomer,
        discount,
        discount_description: discountDescription,
        distance,
        dkk,
        duration,
        enddate,
        event,
        files,
        hotel_cost: hotelCost,
        hotel_info: hotelInfo,
        hotel_price: hotelPrice,
        hours,
        internal_follow_up: internalFollowUp,
        internal_notes: internalNotes,
        invoice,
        invoice_email: invoiceEmail,
        invoice_notes: invoiceNotes,
        location,
        map,
        mounting_notes: mountingNotes,
        nok,
        notes_from_customer: notesFromCustomer,
        notes_to_customer: notesToCustomer,
        notes_to_staff: notesToStaff,
        price,
        private_internal_notes: privateInternalNotes,
        proposal,
        raw_material_cost: rawMaterialCost,
        reminder,
        rental_car_cost: rentalCarCost,
        sek,
        selectedCars,
        selectedStaff,
        send_sms: sendSMS,
        staff_needed: staffNeeded,
        sms_before_text: smsBeforeText,
        staff_follow_up: staffFollowUp,
        startdate,
        technical_info: technicalInfo,
        token,
        travel_compensation: travelCompensation,
        travel_price: travelPrice,
      } = data;

      const hourArray = hours.match(/.{11}/g);

      this.setState({
        arrival,
        completed,
        confirmation,
        confirmed,
        contacts,
        currency,
        customName,
        customer,
        customerContact,
        customerName,
        customerNotesToStaff,
        defaultMountingNotes,
        defaultNotesToCustomer,
        discount,
        discountDescription,
        distance,
        distancePerCar: distance / nrOfCars,
        dkk,
        duration,
        enddate: moment.utc(enddate.substring(0, 10)),
        event,
        files,
        hotelCost,
        hotelInfo,
        hotelPrice,
        hours: hourArray,
        id: this.props.match.params.id,
        internalFollowUp,
        internalNotes,
        invoice,
        invoiceEmail,
        invoiceNotes,
        loadingBooking: false,
        location,
        map,
        mountingNotes,
        nrOfCars,
        nok,
        notesFromCustomer,
        notesToCustomer,
        notesToStaff,
        price,
        privateInternalNotes,
        proposal,
        rawMaterialCost,
        reminder,
        rentalCarCost,
        sek,
        selectedCars,
        selectedStaff,
        sendSMS,
        smsBeforeText: smsBeforeText || "",
        staffFollowUp,
        staffNeeded,
        startdate: moment.utc(startdate.substring(0, 10)),
        technicalInfo,
        token,
        travelCompensation,
        travelPrice,
      });
    });
  }

  onChangeCustomer(customer) {
    const { name, notes_to_staff, distance, google_maps } = this.props.customers.find(
      (c) => c.id === customer
    );

    axios.get(`${API_ENDPOINT}/api/customers/${customer}/contacts`).then(({ data }) => {
      this.setState({
        customer,
        contacts: data,
        customerName: name,
        customerNotesToStaff: notes_to_staff,
        distance: Math.ceil((distance * 2) / 10) * this.state.nrOfCars,
        distancePerCar: Math.ceil((distance * 2) / 10),
        map: google_maps,
        saved: false,
      });
    });
  }

  save() {
    return new Promise((resolve) => {
      axios
        .put(`${API_ENDPOINT}/api/bookings/${this.props.match.params.id}`, {
          ...this.state,
          files: this.state.files.reduce(
            (acc, next) => [...acc, { id: next.id, title: next.title }],
            []
          ),
        })
        .then(({ data }) => {
          if (this.state.newEvent) {
            this.setState({
              id: data.id,
              token: data.token,
              newEvent: false,
              eventInserted: true,
              saved: true,
            });
            this.props.history.push(`/bookings/${data.id}`);
          } else {
            this.setState({ saved: true });
          }
          resolve();
        });
    });
  }

  onChange(type, value) {
    this.setState({
      [type]: value,
      saved: false,
    });
  }

  renderMap() {
    const { map, mapVisible } = this.state;

    if (!map || map.includes("goo.gl")) {
      return;
    }

    return (
      <>
        {mapVisible && (
          <iframe
            title="Google Maps"
            frameBorder="0"
            style={{ width: "100%", height: 450 }}
            src={`https://www.google.com/maps/embed/v1/directions?key=AIzaSyCob1WQhV6iL7eSPhtfcJxpHePxFXcBYgM&origin=Uddevalla+Sweden&destination=${
              map.split("q=")[1].split("&")[0]
            }`}
            allowFullScreen
          />
        )}
        <Button
          style={{ zIndex: -10, marginTop: mapVisible ? -8 : -2 }}
          fluid
          color="black"
          onClick={() => {
            this.setState({ mapVisible: !mapVisible });
          }}
        >
          <Icon name={mapVisible ? "chevron up" : "chevron down"} />
          {mapVisible ? "Göm karta" : "Visa karta"}
        </Button>
      </>
    );
  }

  renderHours() {
    return this.state.hours.map((day, index) => {
      const weekday = moment.utc(this.state.startdate).add(index, "days");

      return (
        <Card key={`hours${index}`} style={{ textAlign: "center", width: 152 }}>
          <Header style={{ marginTop: 5, marginBottom: -2 }}>{days[weekday.day()]}</Header>
          {weekday.format("D")} {months[weekday.format("M") - 1].toLowerCase()}
          <Card.Content>
            <Input
              error={day.match(/^\d{2}:\d{2}-\d{2}:\d{2}$/) === null}
              fluid
              value={day}
              onChange={(input) => {
                const { hours } = this.state;
                hours[index] = input.target.value.replace(/[^\-:0-9]/g, "");
                this.props.isAdmin && this.onChange("hours", hours);
              }}
            />
          </Card.Content>
        </Card>
      );
    });
  }

  render() {
    if (this.state.loadingBooking) {
      return <Loader active size="massive" />;
    }

    return (
      <div>
        <div
          style={{
            top: 51,
            position: "fixed",
            zIndex: 20,
            width: "100%",
          }}
        >
          <TechnicalInfo
            open={this.state.technicalInfoVisible}
            handleClose={() => this.setState({ technicalInfoVisible: false })}
            technicalInfo={this.state.technicalInfo}
          />
          <Menu
            style={{
              background: "#fff",
              marginLeft: -22,
              paddingRight: 22,
              height: 50,
            }}
          >
            {this.props.history.length > 2 && (
              <Menu.Item>
                <Button
                  color="black"
                  onClick={() =>
                    this.state.eventInserted
                      ? this.props.history.go(-2)
                      : this.props.history.goBack()
                  }
                >
                  <Icon name="arrow circle left" />
                  Tillbaka
                </Button>
              </Menu.Item>
            )}
            <Menu.Menu position="right">
              {!this.state.newEvent && (
                <Menu.Item>
                  <Button
                    style={{ margin: 2 }}
                    circular
                    color={this.state.completed ? "green" : "grey"}
                    icon={this.state.completed ? "checkmark box" : "square outline"}
                    onClick={() => {
                      axios
                        .patch(`${API_ENDPOINT}/api/bookings/${this.state.id}/completed`)
                        .then(({ data }) => {
                          const { completed } = data;
                          this.setState({ completed });
                        });
                    }}
                  />
                </Menu.Item>
              )}
              <Menu.Item>
                <Button
                  color="green"
                  disabled={this.state.saved || this.state.customer === 0}
                  onClick={this.save.bind(this)}
                >
                  <Icon name="save" />
                  Spara
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
        <Grid>
          <Grid.Column tablet={16} computer={8} largeScreen={8} widescreen={8}>
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell style={{ width: 140 }} />
                  <Table.Cell>
                    <Button.Group widths={2} fluid>
                      <Button
                        style={{
                          color: "#fff",
                          backgroundColor: this.state.confirmed ? "#ddd" : "#333",
                        }}
                        onClick={() => this.props.isAdmin && this.onChange("confirmed", false)}
                      >
                        Preliminär
                      </Button>
                      <Button
                        style={{
                          color: "#fff",
                          backgroundColor: !this.state.confirmed ? "#ddd" : "#333",
                        }}
                        onClick={() => this.props.isAdmin && this.onChange("confirmed", true)}
                      >
                        Bokad
                      </Button>
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Event</Header>
                    {this.state.technicalInfo && (
                      <Button
                        circular
                        style={{ background: "#fff", border: "1px solid #aaa" }}
                        icon="settings"
                        onClick={() => this.setState({ technicalInfoVisible: true })}
                      />
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      fluid
                      disabled={!this.props.isAdmin}
                      style={{
                        fontSize: 18,
                        borderBottomLeftRadius: 0,
                        borderBottomRightRadius: 0,
                      }}
                      placeholder="Event"
                      search
                      selection
                      noResultsMessage="Inga event funna..."
                      value={this.state.event}
                      onChange={(event, data) => {
                        this.onChange("event", data.value);
                        const { technicalInfo, notesToStaff, sek, nok, dkk } =
                          this.props.events.find((e) => e.id === data.value);
                        this.setState({
                          technicalInfo,
                          sek,
                          nok,
                          dkk,
                        });
                        if (
                          !this.state.notesToStaff ||
                          this.state.notesToStaff === "<p><br></p>" ||
                          this.state.notesToStaff === "<p></p>"
                        ) {
                          this.setState({
                            notesToStaff,
                          });
                        }
                      }}
                      options={this.props.events
                        .filter((event) => event.active || event.id === this.state.event)
                        .reduce((acc, next) => {
                          return [
                            ...acc,
                            {
                              key: next.id,
                              value: next.id,
                              text: next.name,
                            },
                          ];
                        }, [])}
                    />
                    <Input fluid>
                      <input
                        value={this.state.customName}
                        placeholder="Ange anpassat namn..."
                        style={{
                          marginTop: -1,
                          borderTopLeftRadius: 0,
                          borderTopRightRadius: 0,
                        }}
                        onChange={(input) =>
                          this.props.isAdmin && this.onChange("customName", input.target.value)
                        }
                      />
                    </Input>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Kund</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      fluid
                      disabled={!this.props.isAdmin}
                      style={{ fontSize: 16 }}
                      search
                      selection
                      noResultsMessage="Inga kunder funna..."
                      value={this.state.customer}
                      onChange={(event, data) => this.onChangeCustomer(data.value)}
                      options={this.props.customers.reduce((acc, next) => {
                        return [
                          ...acc,
                          {
                            key: next.id,
                            value: next.id,
                            text: `${next.name}${next.city && `, ${next.city}`}`,
                          },
                        ];
                      }, [])}
                    />
                    {this.renderMap()}
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Datum</Header>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <DateRangePicker
                      noBorder
                      disabled={!this.props.isAdmin}
                      startDate={this.state.startdate}
                      startDateId="start-date"
                      endDate={this.state.enddate}
                      endDateId="end-date"
                      onDatesChange={({ startDate, endDate }) => {
                        startDate.set({
                          hour: 12,
                          minute: 0,
                          second: 0,
                          millisecond: 0,
                        });

                        this.setState({
                          startdate: startDate,
                          saved: false,
                        });

                        if (endDate) {
                          endDate.set({
                            hour: 12,
                            minute: 0,
                            second: 0,
                            millisecond: 0,
                          });

                          const duration =
                            Math.abs(startDate.utc().diff(endDate.utc(), "days")) + 1;

                          let hours = this.state.hours.slice(0);

                          if (hours.length > duration) {
                            hours = this.state.hours.slice(0, duration);
                          } else {
                            for (let i = hours.length; i < duration; i++) {
                              hours.push("00:00-00:00");
                            }
                          }

                          this.setState({
                            duration,
                            startdate: startDate,
                            enddate: endDate,
                            hours,
                            saved: false,
                          });
                        }
                      }}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={(focusedInput) => this.setState({ focusedInput })}
                      firstDayOfWeek={1}
                      startDatePlaceholderText="-"
                      endDatePlaceholderText="-"
                      displayFormat="YYYY-MM-DD"
                      isOutsideRange={() => false}
                      minimumNights={0}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Tider</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Card.Group>{this.renderHours()}</Card.Group>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Ankomsttid</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      fluid
                      value={this.state.arrival}
                      onChange={(input) =>
                        this.props.isAdmin && this.onChange("arrival", input.target.value)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                {this.props.isAdmin && (
                  <>
                    <Table.Row>
                      <Table.Cell textAlign="right" style={{ borderTop: "2px solid #ccc" }}>
                        <Header>
                          {!(
                            this.state.sek === 0 &&
                            this.state.nok === 0 &&
                            this.state.dkk === 0
                          ) && (
                            <Popup
                              trigger={
                                <Button icon="info" circular style={{ background: "#fff" }} />
                              }
                            >
                              <h3>Priser</h3>
                              {this.state.sek !== 0 && (
                                <Grid.Column width={16}>
                                  {formatPrice(this.state.sek)} SEK
                                </Grid.Column>
                              )}
                              {this.state.nok !== 0 && (
                                <Grid.Column width={16}>
                                  {formatPrice(this.state.nok)} NOK
                                </Grid.Column>
                              )}
                              {this.state.dkk !== 0 && (
                                <Grid.Column width={16}>
                                  {formatPrice(this.state.dkk)} DKK
                                </Grid.Column>
                              )}
                            </Popup>
                          )}
                          Pris
                        </Header>
                      </Table.Cell>
                      <Table.Cell style={{ borderTop: "2px solid #ccc" }}>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={6}>
                              <Input
                                label={
                                  <Dropdown
                                    placeholder="Valuta"
                                    style={{
                                      background: "white",
                                      color: "black",
                                      border: "1px solid #d3d3d3",
                                    }}
                                    value={this.state.currency}
                                    onChange={(event, data) =>
                                      this.onChange("currency", data.value)
                                    }
                                    options={[
                                      {
                                        key: "SEK",
                                        value: "SEK",
                                        text: "SEK",
                                      },
                                      {
                                        key: "NOK",
                                        value: "NOK",
                                        text: "NOK",
                                      },
                                      {
                                        key: "DKK",
                                        value: "DKK",
                                        text: "DKK",
                                      },
                                    ]}
                                  />
                                }
                                labelPosition="right"
                                style={{ width: 176 }}
                                fluid
                                value={this.state.price}
                                onChange={(input) =>
                                  this.onChange("price", input.target.value.replace(/[^0-9]/g, ""))
                                }
                              />
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <Input
                                fluid
                                placeholder="Rabatt"
                                value={this.state.discountDescription}
                                onChange={(input) =>
                                  this.onChange("discountDescription", input.target.value)
                                }
                              />
                            </Grid.Column>
                            <Grid.Column width={4}>
                              <Input
                                fluid
                                value={this.state.discount}
                                onChange={(input) =>
                                  this.onChange(
                                    "discount",
                                    input.target.value.replace(/[^0-9]/g, "")
                                  )
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="right">
                        <Header>Logi</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={6}>
                              <Input
                                fluid
                                value={this.state.hotelPrice}
                                onChange={(input) =>
                                  this.onChange(
                                    "hotelPrice",
                                    input.target.value.replace(/[^0-9]/g, "")
                                  )
                                }
                              />
                            </Grid.Column>
                            <Grid.Column width={4} verticalAlign="middle">
                              <Header>Faktisk logikostnad</Header>
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <Input
                                fluid
                                value={this.state.hotelCost}
                                onChange={(input) =>
                                  this.onChange(
                                    "hotelCost",
                                    input.target.value.replace(/[^0-9]/g, "")
                                  )
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="right">
                        <Header>Råvaror</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Grid>
                          <Grid.Row>
                            <Grid.Column width={6}>
                              <Input
                                fluid
                                value={this.state.rawMaterialCost}
                                onChange={(input) =>
                                  this.onChange(
                                    "rawMaterialCost",
                                    input.target.value.replace(/[^0-9]/g, "")
                                  )
                                }
                              />
                            </Grid.Column>
                            <Grid.Column width={4} verticalAlign="middle">
                              <Header>Hyrbilskostnad</Header>
                            </Grid.Column>
                            <Grid.Column width={6}>
                              <Input
                                fluid
                                value={this.state.rentalCarCost}
                                onChange={(input) =>
                                  this.onChange(
                                    "rentalCarCost",
                                    input.target.value.replace(/[^0-9]/g, "")
                                  )
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Hotellinfo</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Form>
                      <Textarea
                        style={{ minHeight: 80 }}
                        value={this.state.hotelInfo || ""}
                        onChange={(input) => this.onChange("hotelInfo", input.target.value)}
                      />
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right" style={{ borderBottom: "2px solid #ccc" }}>
                    <Header>Resa</Header>
                  </Table.Cell>
                  <Table.Cell style={{ borderBottom: "2px solid #ccc" }}>
                    <Grid>
                      {this.props.isAdmin && (
                        <Grid.Row>
                          <Grid.Column width={2}>
                            <Dropdown
                              fluid
                              selection
                              value={this.state.nrOfCars}
                              options={[1, 2, 3, 4, 5, 6, 7, 8].reduce((acc, next) => {
                                return [
                                  ...acc,
                                  {
                                    key: next,
                                    text: next,
                                    value: next,
                                  },
                                ];
                              }, [])}
                              onChange={(_, data) =>
                                this.setState({
                                  saved: false,
                                  nrOfCars: data.value,
                                  distance: this.state.distancePerCar * data.value,
                                })
                              }
                            />
                          </Grid.Column>
                          <Grid.Column width={4}>
                            <Input
                              label="mil"
                              labelPosition="right"
                              fluid
                              value={this.state.distancePerCar}
                              onChange={(input) => {
                                const distancePerCar = Number(
                                  input.target.value.replace(/[^0-9]/g, "")
                                );
                                this.onChange("distance", distancePerCar * this.state.nrOfCars);
                                this.onChange("distancePerCar", distancePerCar);
                              }}
                            />
                          </Grid.Column>
                          <Grid.Column width={5}>
                            <Input
                              fluid
                              label={`${this.state.currency}/mil`}
                              labelPosition="right"
                              value={this.state.travelPrice}
                              onChange={(input) =>
                                this.onChange(
                                  "travelPrice",
                                  input.target.value.replace(/[^0-9]/g, "")
                                )
                              }
                            />
                          </Grid.Column>
                          <Grid.Column width={5}>
                            <Input
                              fluid
                              label="Milersättning"
                              labelPosition="right"
                              value={this.state.travelCompensation}
                              onChange={(input) =>
                                this.onChange(
                                  "travelCompensation",
                                  input.target.value.replace(",", ".").replace(/[^.0-9]/g, "")
                                )
                              }
                            />
                          </Grid.Column>
                        </Grid.Row>
                      )}
                      <Grid.Row>
                        <Grid.Column width={16}>
                          <Card.Group itemsPerRow={this.props.cars.length}>
                            {this.props.cars.map((car) => {
                              return (
                                <Card
                                  key={`car${car.id}`}
                                  className="disable-selection"
                                  style={{
                                    background: this.state.selectedCars.includes(car.id)
                                      ? "#333"
                                      : "#fff",
                                    color: !this.state.selectedCars.includes(car.id)
                                      ? "#333"
                                      : "#fff",
                                    fontSize: "1em",
                                    textAlign: "center",
                                    cursor: "pointer",
                                    paddingTop: 5,
                                    paddingBottom: 5,
                                  }}
                                  onClick={() => {
                                    const selectedCars = this.props.cars
                                      .filter((c) => {
                                        if (c.id === car.id) {
                                          return !this.state.selectedCars.includes(car.id);
                                        }
                                        return this.state.selectedCars.includes(c.id);
                                      })
                                      .map((c) => c.id);

                                    this.onChange("selectedCars", selectedCars);
                                  }}
                                >
                                  <Image
                                    src={`/cars/${car.type}.png`}
                                    centered
                                    size="mini"
                                    style={{
                                      background: "#fff",
                                      marginBottom: 2,
                                      padding: 2,
                                    }}
                                  />
                                  {car.name.replace("bil", "")}
                                </Card>
                              );
                            })}
                          </Card.Group>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                  </Table.Cell>
                </Table.Row>
                {this.props.isAdmin ? (
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header>Noteringar</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <ReactQuill
                        style={{ background: "#ffffdf" }}
                        theme="bubble"
                        modules={modules}
                        value={this.state.privateInternalNotes}
                        onFocus={() => this.setState({ quillEnabled: true })}
                        onChange={(input) =>
                          this.state.quillEnabled && this.onChange("privateInternalNotes", input)
                        }
                      />
                    </Table.Cell>
                  </Table.Row>
                ) : null}
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>
                      Interna
                      <br />
                      anteckningar
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <ReactQuill
                      style={{ background: "#ffeeee" }}
                      theme="bubble"
                      modules={modules}
                      value={this.state.internalNotes}
                      onFocus={() => this.setState({ quillEnabled: true })}
                      onChange={(input) =>
                        this.state.quillEnabled && this.onChange("internalNotes", input)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>
                      Instruktioner
                      <br />
                      till personal
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <ReactQuill
                      theme="bubble"
                      modules={modules}
                      value={this.state.notesToStaff}
                      onFocus={() => this.setState({ quillEnabled: true })}
                      onChange={(input) =>
                        this.state.quillEnabled && this.onChange("notesToStaff", input)
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>
                      Instruktioner
                      <br />
                      från köpcentrat
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Form>
                      <Textarea
                        value={this.state.notesFromCustomer || ""}
                        onChange={(input) => this.onChange("notesFromCustomer", input.target.value)}
                      />
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Kontaktperson</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Form>
                      <Textarea
                        style={{ minHeight: 80 }}
                        value={this.state.customerContact || ""}
                        onChange={(input) => this.onChange("customerContact", input.target.value)}
                      />
                    </Form>
                  </Table.Cell>
                </Table.Row>
                {this.state.customerNotesToStaff ? (
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header>Info om kunden</Header>
                    </Table.Cell>
                    <Table.Cell>{this.state.customerNotesToStaff}</Table.Cell>
                  </Table.Row>
                ) : null}
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Eventplats</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Form>
                      <Textarea
                        style={{ minHeight: 80 }}
                        value={this.state.location || ""}
                        onChange={(input) => this.onChange("location", input.target.value)}
                      />
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>SMS</Header>
                    <Checkbox
                      checked={this.state.sendSMS}
                      style={{ marginTop: 5, marginRight: 12 }}
                      onChange={() =>
                        this.setState({
                          sendSMS: !this.state.sendSMS,
                          saved: false,
                        })
                      }
                    />
                  </Table.Cell>
                  <Table.Cell>
                    <Grid>
                      <Grid.Row>
                        <Grid.Column width={8}>
                          <Header disabled={!this.state.sendSMS}>Text före event</Header>
                        </Grid.Column>
                        <Grid.Column width={8}>
                          <span
                            style={{
                              fontSize: 16,
                              marginBottom: 6,
                              marginRight: 2,
                              float: "right",
                              color: this.state.sendSMS ? "#000" : "#888",
                            }}
                          >
                            {this.state.smsBeforeText.length ||
                              this.state.smsBeforeTextTemplate.length}{" "}
                            (
                            {Math.ceil(
                              (this.state.smsBeforeText.length ||
                                this.state.smsBeforeTextTemplate.length) / 160
                            )}{" "}
                            sms)
                          </span>
                        </Grid.Column>
                      </Grid.Row>
                    </Grid>
                    <Form>
                      <Textarea
                        disabled={!this.state.sendSMS}
                        style={{ color: this.state.sendSMS ? "#000" : "#888" }}
                        value={this.state.smsBeforeText || this.state.smsBeforeTextTemplate || ""}
                        onChange={(input) => this.onChange("smsBeforeText", input.target.value)}
                      />
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Antal personal</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      placeholder="Antal personal"
                      fluid
                      selection
                      value={this.state.staffNeeded}
                      options={[
                        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
                      ].reduce((acc, next) => {
                        return [
                          ...acc,
                          {
                            key: next,
                            text: next,
                            value: next,
                          },
                        ];
                      }, [])}
                      onChange={(_, data) =>
                        this.setState({ saved: false, staffNeeded: data.value })
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                {this.props.isAdmin && (
                  <Table.Row>
                    <Table.Cell colSpan={2}>
                      <BookingStaff
                        selectedStaff={this.state.selectedStaff}
                        newStaff={this.state.newStaff}
                        selectNewStaff={(newStaff) =>
                          this.setState({
                            newStaff,
                          })
                        }
                        addNewStaff={(newStaff) =>
                          this.setState({
                            selectedStaff: [...this.state.selectedStaff, newStaff],
                            newStaff: null,
                            saved: false,
                          })
                        }
                        editStaff={(type, value, index) => {
                          this.setState({
                            selectedStaff: [
                              ...this.state.selectedStaff.slice(0, index),
                              {
                                ...this.state.selectedStaff[index],
                                [type]: value,
                              },
                              ...this.state.selectedStaff.slice(index + 1),
                            ],
                            saved: false,
                          });
                        }}
                        remove={(staff) =>
                          this.onChange(
                            "selectedStaff",
                            this.state.selectedStaff.filter((s) => s.id !== staff)
                          )
                        }
                        save={this.save.bind(this)}
                        saveDisabled={this.state.saved || this.state.customer === 0}
                      />
                    </Table.Cell>
                  </Table.Row>
                )}
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column
            tablet={16}
            computer={8}
            largeScreen={8}
            widescreen={8}
            style={{ backgroundColor: "#fff" }}
          >
            {!this.props.isAdmin && (
              <Card fluid>
                <Card.Content>
                  <Header>Personal</Header>
                  <BookingStaff
                    selectedStaff={this.state.selectedStaff}
                    newStaff={this.state.newStaff}
                    selectNewStaff={(newStaff) =>
                      this.setState({
                        newStaff,
                      })
                    }
                    addNewStaff={(newStaff) =>
                      this.setState({
                        selectedStaff: [...this.state.selectedStaff, newStaff],
                        newStaff: null,
                        saved: false,
                      })
                    }
                    editStaff={(type, value, index) => {
                      this.setState({
                        selectedStaff: [
                          ...this.state.selectedStaff.slice(0, index),
                          {
                            ...this.state.selectedStaff[index],
                            [type]: value,
                          },
                          ...this.state.selectedStaff.slice(index + 1),
                        ],
                        saved: false,
                      });
                    }}
                    remove={(staff) =>
                      this.onChange(
                        "selectedStaff",
                        this.state.selectedStaff.filter((s) => s.id !== staff)
                      )
                    }
                    save={this.save.bind(this)}
                    saveDisabled={this.state.saved || this.state.customer === 0}
                  />
                </Card.Content>
              </Card>
            )}
            {!this.state.newEvent && this.props.isAdmin && (
              <BookingInvoice
                save={this.save.bind(this)}
                saved={this.state.saved}
                contacts={this.state.contacts}
                customer={this.state.customer}
                customerName={this.state.customerName}
                invoice={this.state.invoice}
                invoiceEmail={this.state.invoiceEmail}
                proposal={this.state.proposal}
                confirmation={this.state.confirmation}
                reminder={this.state.reminder}
                defaultNotesToCustomer={this.state.defaultNotesToCustomer}
                notesToCustomer={this.state.notesToCustomer}
                defaultMountingNotes={this.state.defaultMountingNotes}
                mountingNotes={this.state.mountingNotes}
                invoiceNotes={this.state.invoiceNotes}
                onChange={this.onChange.bind(this)}
                pdfCreated={(type, file) => this.setState({ [type]: file })}
                id={this.state.id}
              />
            )}
            {!this.state.newEvent && this.props.events.length > 0 && this.props.isAdmin && (
              <BookingMail
                token={this.state.token}
                event={this.props.events.find((e) => e.id === this.state.event).name}
                customName={this.state.customName}
                contacts={this.state.contacts}
              />
            )}
            {this.props.isAdmin && (
              <BookingBudget
                price={Number(this.state.price) - Number(this.state.discount)}
                hotelPrice={Number(this.state.hotelPrice)}
                hotelCost={Number(this.state.hotelCost)}
                distance={Number(this.state.distance)}
                travelPrice={Number(this.state.travelPrice)}
                rawMaterialCost={Number(this.state.rawMaterialCost)}
                rentalCarCost={Number(this.state.rentalCarCost)}
                salary={this.state.selectedStaff.reduce(
                  (acc, next) => acc + Number(next.hours) * next.salary,
                  0
                )}
                perDiem={this.state.selectedStaff.reduce(
                  (acc, next) => acc + Number(next.perDiem),
                  0
                )}
                currency={this.state.currency}
                travelCompensation={this.state.selectedStaff.reduce(
                  (acc, next) => acc + next.distance * this.state.travelCompensation,
                  0
                )}
              />
            )}
            {!this.state.newEvent && (
              <>
                <BookingPackingLists bookingId={this.state.id} />
                <Card fluid>
                  <Card.Content>
                    <Header>Filer till personal</Header>
                    <BookingFiles
                      id={this.state.id}
                      files={this.state.files}
                      addFile={(file) => this.setState({ files: [...this.state.files, file] })}
                      changeFile={(index, value) => {
                        const { files } = this.state;
                        files[index].title = value;
                        this.setState({ files, saved: false });
                      }}
                      deleteFile={(id) =>
                        this.setState({
                          files: this.state.files.filter((file) => file.id !== id),
                        })
                      }
                    />
                  </Card.Content>
                </Card>
                <Card fluid>
                  <Card.Content>
                    <Form>
                      <Header>Eventrapport från personal</Header>
                      <Textarea
                        value={this.state.staffFollowUp || ""}
                        onChange={(input) => this.onChange("staffFollowUp", input.target.value)}
                      />
                      <Header>Intern uppföljning</Header>
                      <Textarea
                        value={this.state.internalFollowUp || ""}
                        onChange={(input) => this.onChange("internalFollowUp", input.target.value)}
                      />
                    </Form>
                    <br />
                    <BookingPhotos id={this.state.id} />
                  </Card.Content>
                </Card>
                <BookingSMS
                  id={this.state.id}
                  nr={this.state.selectedStaff.map((staff) => staff.phone)}
                />
              </>
            )}
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ app, settings, user }) => {
  const { cars, customers, events } = app;
  const { travelCompensation, milage, smsBefore } = settings;
  const isAdmin = user.accessLevel === 1;

  return {
    cars,
    customers,
    events,
    travelCompensation,
    smsBefore,
    milage,
    isAdmin,
  };
};

export default connect(mapStateToProps, { navigate })(Booking);
