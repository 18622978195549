import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { months, formatPrice } from '../../utilities';
import windowSize from 'react-window-size';
import { ComposedChart, Area, XAxis, YAxis, Tooltip } from 'recharts';
import { Range } from 'rc-slider';
import 'rc-slider/assets/index.css';

import { navigate } from '../../actions';
import moment from 'moment';

class Graphs extends React.Component {
  state = {
    data: [],
    start: 2017,
    end: 2018
  };

  componentDidMount() {
    this.props.navigate('graphs');

    window.scrollTo(0, 0);

    axios
      .get(
        `
      ${API_ENDPOINT}/api/graphs/earnings
    `
      )
      .then(({ data }) => {
        this.setState({
          data: data.reduce(
            (acc, next, index) => [
              ...acc,
              { name: `${next.month}/${String(next.year).substring(2)}`, in: Number(next.price), out: Number(next.out) }
            ],
            []
          )
        });
      });
  }

  render() {
    return (
      <div style={{ marginTop: -50 }}>
        <ComposedChart
          width={this.props.windowWidth - 26}
          height={400}
          data={this.state.data.filter(d => {
            const year = parseInt(`20${d.name.slice(-2)}`, 10);
            return year > this.state.start && year <= this.state.end;
          })}
          margin={{ top: 10, right: 10, left: 0, bottom: 0 }}
        >
          <defs>
            <linearGradient id="colorIn" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#8884d8" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#8884d8" stopOpacity={0} />
            </linearGradient>
            <linearGradient id="colorOut" x1="0" y1="0" x2="0" y2="1">
              <stop offset="5%" stopColor="#ca829d" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#ca829d" stopOpacity={0} />
            </linearGradient>
          </defs>
          <XAxis dataKey="name" type="category" />
          <YAxis type="number" mirror tickFormatter={t => (t === 0 ? '' : `${formatPrice(parseInt(t, 10))}`)} />
          <Tooltip
            content={props => {
              if (!props.payload || !props.payload[0]) {
                return <div />;
              }

              const month = props.payload[0].payload.name.split('/')[0];
              const year = props.payload[0].payload.name.split('/')[1];

              return (
                <div style={{ background: '#fafafa', padding: 10, borderRadius: 3, border: '1px solid #f3f3f3' }}>
                  <h3>
                    {months[month - 1]} 20{year}
                  </h3>
                  Intäkter: {formatPrice(props.payload[0].payload.in)}
                  <br />
                  Utgifter: {formatPrice(props.payload[0].payload.out)}
                </div>
              );
            }}
          />
          <Area type="monotone" dataKey="in" fillOpacity={1} barSize={18} fill="url(#colorIn)" />
          <Area type="monotone" dataKey="out" fillOpacity={1} barSize={18} fill="url(#colorOut)" />
        </ComposedChart>
        <Range
          min={2008}
          max={parseInt(
            moment()
              .add(1, 'year')
              .format('Y'),
            10
          )}
          dots
          value={[this.state.start, this.state.end]}
          onChange={event => this.setState({ start: event[0], end: event[1] })}
          allowCross={false}
        />
      </div>
    );
  }
}

export default connect(
  null,
  { navigate }
)(windowSize(Graphs));
