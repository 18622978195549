import React from 'react';
import StaffReportSingle from './StaffReportSingle';

class StaffReport extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps() {
    window.scrollTo(0, 0);
  }

  render() {
    if (this.props.staff !== 'all-sorted') {
      return (
        <div style={{ marginTop: 130 }}>
          <StaffReportSingle
            events={this.props.staffEvents[this.props.staff]}
            from={this.props.from}
            to={this.props.to}
          />
        </div>
      );
    } else {
      return (
        <div style={{ marginTop: 130 }}>
          {Object.keys(this.props.staffEvents)
            .filter(event => this.props.staffEvents[event][0].staff.id !== 8)
            .sort((a, b) => {
              const nameA = this.props.staffEvents[a][0].staff.name.toUpperCase();
              const nameB = this.props.staffEvents[b][0].staff.name.toUpperCase();

              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              return 0;
            })
            .map(staff => (
              <StaffReportSingle
                key={`staffreport-${staff}`}
                events={this.props.staffEvents[staff]}
                from={this.props.from}
                to={this.props.to}
              />
            ))}
        </div>
      );
    }
  }
}

export default StaffReport;
