import React from 'react';
import { Label, Statistic, Icon, Grid, Button } from 'semantic-ui-react';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { formatPrice } from '../../utilities';

class StaffStatistics extends React.Component {
  state = {
    events: [],
    eventsdone: 0,
    eventdays: 0,
    hours: 0,
    distance: 0,
    per_diem: 0,
    places: 0,
    delete: false
  };

  componentDidMount() {
    const { id } = this.props;

    axios.get(`${API_ENDPOINT}/api/staff/${id}/statistics`).then(({ data }) => {
      this.setState(data);
    });
  }

  componentWillReceiveProps(nextProps) {
    const { id } = nextProps;

    axios.get(`${API_ENDPOINT}/api/staff/${id}/statistics`).then(({ data }) => {
      this.setState(data);
    });
  }

  render() {
    if (this.state.events.length === 0) {
      if (this.state.delete) {
        return (
          <>
            <Button
              color="red"
              style={{ float: 'right' }}
              onClick={async () => {
                await axios.delete(`${API_ENDPOINT}/api/staff/${this.props.id}`);
                this.props.delete();
              }}
            >
              <Icon name="trash" /> Är du säker?
            </Button>
            <Button style={{ float: 'right' }} onClick={() => this.setState({ delete: false })}>
              <Icon name="cancel" /> Avbryt
            </Button>
          </>
        );
      }

      return (
        <Button color="red" style={{ float: 'right' }} onClick={() => this.setState({ delete: true })}>
          <Icon name="trash" /> Radera
        </Button>
      );
    }

    return (
      <Grid>
        <Grid.Row style={{ marginBottom: 10 }}>
          <Grid.Column width={16}>
            <Label.Group size="medium">
              {this.state.events.map(event => (
                <Label key={event.name} style={{ background: `#${event.color}` }}>
                  {event.name}
                  <Label.Detail>{event.count}</Label.Detail>
                </Label>
              ))}
            </Label.Group>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ marginBottom: 20 }}>
          <Grid.Column width={8} textAlign="center">
            <Statistic size="small">
              <Statistic.Value>
                <Icon name="building outline" /> {formatPrice(this.state.eventsdone, true)}
              </Statistic.Value>
              <Statistic.Label>event gjorda</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column width={8} textAlign="center">
            <Statistic size="small">
              <Statistic.Value>
                <Icon name="calendar check" /> {this.state.eventdays ? formatPrice(this.state.eventdays, true) : '0'}
              </Statistic.Value>
              <Statistic.Label>eventdagar</Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row style={{ marginBottom: 20 }}>
          <Grid.Column width={8} textAlign="center">
            <Statistic size="small">
              <Statistic.Value>
                <Icon name="clock" style={{ width: 32 }} />{' '}
                {!this.state.hours ? '0' : formatPrice(Number(this.state.hours), true)}
              </Statistic.Value>
              <Statistic.Label>timmar arbetade</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column width={8} textAlign="center">
            <Statistic size="small">
              <Statistic.Value>
                <Icon name="hotel" /> {!this.state.per_diem ? '0' : formatPrice(this.state.per_diem)}
              </Statistic.Value>
              <Statistic.Label>Intjänat traktamente</Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={8} textAlign="center">
            <Statistic size="small">
              <Statistic.Value>
                <Icon name="car" /> {!this.state.distance ? '0' : formatPrice(this.state.distance, true)}
              </Statistic.Value>
              <Statistic.Label>Mil körda</Statistic.Label>
            </Statistic>
          </Grid.Column>
          <Grid.Column width={8} textAlign="center">
            <Statistic size="small">
              <Statistic.Value>
                <Icon name="globe" /> {formatPrice(this.state.places, true)}
              </Statistic.Value>
              <Statistic.Label>Olika platser</Statistic.Label>
            </Statistic>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

export default StaffStatistics;
