import React from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Table, Dropdown } from 'semantic-ui-react';
import { formatPrice } from '../../../utilities';

class Revenue extends React.Component {
  state = { selectedLap: 0 };
  render() {
    const tickets2019 = this.props.tickets2019.filter(
      ticket => this.state.selectedLap === 0 || ticket.lap === this.state.selectedLap
    );

    const tickets = tickets2019.reduce((acc, next) => {
      if (!next.refund) {
        return acc + next.ticket_price * next.participants.length;
      } else if (next.full_refund) {
        return acc;
      } else {
        return acc + next.ticket_price * next.participants.length - next.refund_amount;
      }
    }, 0);

    const registrationFees = tickets2019.reduce(
      (acc, next) => acc + (next.full_refund ? 0 : next.registration_fee * next.participants.length),
      0
    );

    const shipping = tickets2019.reduce(
      (acc, next) => acc + next.shipping_price * (next.shipping && !next.full_refund ? 1 : 0),
      0
    );

    const tshirts = tickets2019.reduce(
      (acc, next) =>
        acc +
        next.tshirt_price *
          next.participants.reduce((acc, next) => acc + (next.tshirt && !next.full_refund ? 1 : 0), 0),
      0
    );

    return (
      <Grid verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={7}>
            <Header style={{ marginTop: 5 }}>Försäljning</Header>
          </Grid.Column>
          <Grid.Column width={9}>
            <Dropdown
              fluid
              selection
              value={this.state.selectedLap}
              onChange={(event, data) => {
                this.setState({
                  selectedLap: data.value
                });
              }}
              options={this.props.laps.reduce(
                (acc, next) => {
                  return [
                    ...acc,
                    {
                      key: `lap${next.id}`,
                      value: next.id,
                      text: next.city
                    }
                  ];
                },
                [
                  {
                    key: `lap0`,
                    value: 0,
                    text: 'Sammanlagt'
                  }
                ]
              )}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Table basic="very" textAlign="right">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ borderBottom: '1px solid #000' }} />
                  <Table.HeaderCell style={{ borderBottom: '1px solid #000' }}>Summa</Table.HeaderCell>
                  <Table.HeaderCell style={{ borderBottom: '1px solid #000' }}>Moms</Table.HeaderCell>
                  <Table.HeaderCell style={{ borderBottom: '1px solid #000' }}>Total</Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                <Table.Row>
                  <Table.Cell textAlign="left">Biljetter</Table.Cell>
                  <Table.Cell>{formatPrice(Math.round(tickets * 0.9434))}</Table.Cell>
                  <Table.Cell>{formatPrice(Math.round(tickets * 0.0566))}</Table.Cell>
                  <Table.Cell>{formatPrice(tickets)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="left">Hemleverans</Table.Cell>
                  <Table.Cell>{formatPrice(Math.round(shipping * 0.8))}</Table.Cell>
                  <Table.Cell>{formatPrice(Math.round(shipping * 0.2))}</Table.Cell>
                  <Table.Cell>{formatPrice(shipping)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="left">Registrering</Table.Cell>
                  <Table.Cell>{formatPrice(Math.round(registrationFees * 0.8))}</Table.Cell>
                  <Table.Cell>{formatPrice(Math.round(registrationFees * 0.2))}</Table.Cell>
                  <Table.Cell>{formatPrice(registrationFees)}</Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="left">T-shirts</Table.Cell>
                  <Table.Cell>{formatPrice(Math.round(tshirts * 0.8))}</Table.Cell>
                  <Table.Cell>{formatPrice(Math.round(tshirts * 0.2))}</Table.Cell>
                  <Table.Cell>{formatPrice(tshirts)}</Table.Cell>
                </Table.Row>
              </Table.Body>
              <Table.Footer>
                <Table.Row>
                  <Table.HeaderCell style={{ borderTop: '1px solid #000' }} />
                  <Table.HeaderCell style={{ fontWeight: 'bold', borderTop: '1px solid #000' }}>
                    {formatPrice(Math.round(tickets * 0.9434 + (registrationFees + tshirts + shipping) * 0.8))}
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ fontWeight: 'bold', borderTop: '1px solid #000' }}>
                    {formatPrice(Math.round(tickets * 0.0566 + (registrationFees + tshirts + shipping) * 0.2))}
                  </Table.HeaderCell>
                  <Table.HeaderCell style={{ fontWeight: 'bold', borderTop: '1px solid #000' }}>
                    {formatPrice(Math.round(tickets + registrationFees + tshirts + shipping))}
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Footer>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = ({ tickets }) => {
  const { tickets2019, laps } = tickets;

  return { tickets2019, laps };
};

export default connect(mapStateToProps)(Revenue);
