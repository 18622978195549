import React from 'react';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { formatSize } from '../../utilities';
import { Button, List, Input } from 'semantic-ui-react';
import moment from 'moment';

require('../../index.css');
require('./booking.css');

class BookingFiles extends React.Component {
  state = {
    file: null,
    deleteWarning: 0,
    title: '',
    saved: true
  };

  onSubmit() {
    const formData = new FormData();
    formData.append('file', this.state.file, this.state.file.name);

    formData.append('title', this.state.title);

    formData.append('size', this.state.file.size);

    axios
      .post(`${API_ENDPOINT}/api/bookings/${this.props.id}/files`, formData, {
        onUploadProgress: progressEvent => {
          // console.log(
          //   progressEvent.loaded / progressEvent.total
          // );
        }
      })
      .then(({ data }) => {
        this.props.addFile(data);

        this.setState({
          file: null,
          title: ''
        });
      });
  }

  deleteFile(id) {
    axios.delete(`${API_ENDPOINT}/api/bookings/${this.props.id}/files/${id}`).then(() => {
      this.props.deleteFile(id);
    });
  }

  render() {
    return (
      <>
        <List>
          {this.props.files.map((file, index) => (
            <List.Item key={file.id} className="fade-in" style={{ marginTop: 10, marginBottom: 20 }}>
              <List.Content verticalAlign="middle">
                <Input fluid type="text" placeholder="Titel" action>
                  <Button
                    color="black"
                    style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
                    icon="download"
                    as="a"
                    target="_blank"
                    href={`${API_ENDPOINT}/api/uploads/bookingfiles/${file.id}`}
                  />
                  <input
                    style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                    onChange={input => this.props.changeFile(index, input.target.value)}
                    value={file.title || file.filename}
                  />
                  {this.state.deleteWarning && this.state.deleteWarning === file.id ? (
                    <>
                      <Button color="black" onClick={() => this.setState({ deleteWarning: 0 })}>
                        Avbryt
                      </Button>
                      <Button color="red" icon="trash" onClick={() => this.deleteFile(file.id)} />
                    </>
                  ) : (
                    <Button color="black" icon="trash" onClick={() => this.setState({ deleteWarning: file.id })} />
                  )}
                </Input>
                <List.Description style={{ color: '#999' }}>
                  <span style={{ float: 'left' }}>
                    {file.filename} ({formatSize(file.size)})
                  </span>
                  <span style={{ float: 'right' }}>Uppladdad {moment(file.created).format('YYYY-MM-DD HH:mm')}</span>
                </List.Description>
              </List.Content>
            </List.Item>
          ))}
        </List>
        <div>
          <Input fluid type="text" placeholder="Titel" action>
            <Button color="black" icon="upload" style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }} />
            <input
              type="file"
              style={{
                position: 'absolute',
                bottom: 0,
                left: 0,
                opacity: 0,
                height: 40,
                width: 40,
                cursor: 'pointer'
              }}
              onChange={event => {
                this.setState({ file: event.target.files[0] });
              }}
            />
            <input
              onChange={input => this.setState({ title: input.target.value })}
              value={this.state.title}
              style={{
                zIndex: 10,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0
              }}
            />
            <Button color="green" disabled={!this.state.file} onClick={this.onSubmit.bind(this)}>
              Ladda upp
            </Button>
          </Input>
          {this.state.file && (
            <span style={{ color: '#999' }}>
              {this.state.file.name} ({formatSize(this.state.file.size)})
            </span>
          )}
        </div>
      </>
    );
  }
}

export default BookingFiles;
