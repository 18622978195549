import { FETCH_LAPS, FETCH_TICKETS, FETCH_2018_TICKETS } from './types';
import { API_ENDPOINT } from '../config';

import axios from 'axios';

export function fetchLaps() {
  return dispatch => {
    axios.get(`${API_ENDPOINT}/api/tickets/laps`).then(({ data: laps }) => {
      dispatch({
        type: FETCH_LAPS,
        payload: laps
      });
    });
  };
}

export function fetchTickets() {
  return dispatch => {
    axios.get(`${API_ENDPOINT}/api/tickets`).then(({ data: tickets }) => {
      dispatch({
        type: FETCH_TICKETS,
        payload: tickets
      });
    });
  };
}

export function fetch2018Tickets() {
  return dispatch => {
    axios.get(`${API_ENDPOINT}/api/tickets/2018`).then(({ data: tickets }) => {
      dispatch({
        type: FETCH_2018_TICKETS,
        payload: tickets
      });
    });
  };
}
