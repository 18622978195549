export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const SET_USER_ON_STARTUP = "set_user_on_startup";

export const SET_BOOKING_LIST_INTERVAL = "set_booking_list_interval";
export const SET_LAST_CUSTOMER = "set_last_customer";
export const SET_CUSTOMER_FILTER = "set_customer_filter";
export const SET_LAST_STAFF = "set_last_staff";
export const FETCH_CARS = "fetch_cars";
export const FETCH_CHAINS = "fetch_chains";
export const FETCH_CUSTOMERS = "fetch_customers";
export const FETCH_EVENTS = "fetch_events";
export const FETCH_POSITIONS = "fetch_positions";
export const FETCH_STAFF = "fetch_staff";
export const FETCH_CUSTOMER_CATEGORIES = "fetch_customer_categories";

export const FETCH_SETTINGS = "fetch_settings";

export const NAVIGATE = "navigate";
export const BOOKING_LIST_FILTER = "booking_list_filter";

export const SET_TICKET_CITY = "set_ticket_city";
export const FETCH_LAPS = "fetch_laps";
export const FETCH_TICKETS = "fetch_tickets";
export const FETCH_2018_TICKETS = "fetch_2018_tickets";
