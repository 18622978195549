import React from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { Header, Loader, Icon } from 'semantic-ui-react';

class StaffModal extends React.Component {
  state = {
    id: 0,
    name: '',
    phone: '',
    loading: true
  };

  componentDidMount() {
    axios
      .get(`${API_ENDPOINT}/api/staff/${this.props.staff}`)
      .then(({ data }) => {
        const { id, name, phone, email } = data;

        this.setState({
          id,
          name,
          phone,
          email,
          loading: false
        });
      });
  }

  render() {
    if (this.state.loading) {
      return (
        <div style={{ minWidth: 200, minHeight: 60 }}>
          <Loader active />
        </div>
      );
    }

    const { id, name, phone, email } = this.state;

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: 220,
          paddingTop: 10,
        }}
      >
        <Header style={{ cursor: 'pointer' }} as={Link} to={`/staff/${id}`}>
          {name}
          <Icon style={{ position: 'absolute', right: 0 }} name='edit' />
        </Header>
        {phone && <span style={{ fontSize: 14 }}><Icon name='phone' />{phone}</span>}
        {email && <span style={{ fontSize: 14 }}><Icon name='mail' /><a href={`mailto:${email}`}>{email}</a></span>}
      </div>
    );
  }
}

export default StaffModal;
