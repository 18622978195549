import React from 'react';
import { Link, Route, Redirect } from 'react-router-dom';
import { Input, Menu, Dropdown, Button, Icon } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { navigate } from '../../actions';

import Staff from './Staff';

class StaffList extends React.Component {
  state = { search: '', view: 'active' };

  componentDidMount() {
    this.props.navigate('staff', 'list');
  }

  componentWillReceiveProps(nextProps) {
    this.props.navigate('staff', 'list');
  }

  renderHeader() {
    return (
      <div style={{ top: 51, position: 'fixed', zIndex: 10, width: '100%' }}>
        <Menu style={{ background: '#fff', marginLeft: -22, paddingRight: 22 }}>
          <Menu.Item>
            <Button color="green" style={{ cursor: 'pointer' }} as={Link} to="/staff/new">
              <Icon name="plus" />
              Ny personal
            </Button>
          </Menu.Item>
        </Menu>
      </div>
    );
  }

  renderStaffMenu() {
    let { staff } = this.props;
    if (this.state.search !== '') {
      staff = staff.filter(staff => {
        return staff.name.toLowerCase().includes(this.state.search.toLowerCase());
      });
    }
    return staff
      .filter(staff => {
        if (staff.id === 8) {
          return false;
        }
        if (this.state.view === 'active') {
          return staff.active;
        } else if (this.state.view === 'inactive') {
          return !staff.active;
        }
        return true;
      })
      .map(staff => (
        <Menu.Item as={Link} to={`/staff/${staff.id}`} key={staff.id} style={{ paddingTop: 12, paddingBottom: 12 }}>
          {staff.name}
        </Menu.Item>
      ));
  }

  render() {
    if (this.props.loading) {
      return <div />;
    }

    if (this.props.location.pathname === '/staff') {
      return <Redirect to={`staff/${this.props.lastStaff}`} />;
    }

    return (
      <div style={{ display: 'flex' }}>
        {this.renderHeader()}
        <div style={{ width: 200, float: 'left', marginRight: 20 }}>
          <Menu vertical>
            <Menu.Item>
              <Input>
                <input
                  placeholder="Sök..."
                  value={this.state.search}
                  style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, zIndex: 2 }}
                  onChange={input => this.setState({ search: input.target.value })}
                />
              </Input>
              <Dropdown
                selection
                fluid
                style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: -1 }}
                options={[
                  { key: 'all', value: 'all', text: 'Visar alla' },
                  { key: 'active', value: 'active', text: 'Visar aktiva' },
                  { key: 'inactive', value: 'inactive', text: 'Visar inaktiva' }
                ]}
                value={this.state.view}
                onChange={(event, data) => this.setState({ view: data.value })}
              />
            </Menu.Item>
            {this.renderStaffMenu()}
          </Menu>
        </div>
        <div
          style={{
            width: '100%',
            float: 'right'
          }}
        >
          <Route path="/staff/:id" component={Staff} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app, settings }) => {
  const { staff } = app;
  const { lastStaff, loading } = settings;

  return { staff, lastStaff, loading };
};

export default connect(
  mapStateToProps,
  {
    navigate
  }
)(StaffList);
