import React from 'react';
import { Card, Form, Header, Button, Icon, Loader, Modal, Grid, Image } from 'semantic-ui-react';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';
import '../utilities/quill.css';
import { modules } from '../utilities/quill';

import PackingListImage from './PackingListImage';

class PackingListDetails extends React.Component {
  state = {
    quillEnabled: false,
    uploading: -1,
    deleteDetails: -1,
    showImage: -1,
    deleteImageWarning: false,
  };

  render() {
    if (!this.props.item) {
      return null;
    }

    const {
      item: { name, details },
      inactive,
      onChangeDetails,
      onMoveDetails,
      onAddDetails,
      onAddImage,
      onDeleteImage,
      onDeleteDetails,
    } = this.props;
    const { quillEnabled, uploading, deleteDetails, showImage, deleteImageWarning } = this.state;

    return (
      <>
        <Modal
          open={this.state.showImage >= 0}
          onClose={() => this.setState({ showImage: -1, deleteImageWarning: false })}
          basic
        >
          {details[showImage] && (
            <Image
              src={`${API_ENDPOINT}/api/static/packing/${details[showImage].image}?i=${Math.ceil(
                Math.random() * 10000
              )}`}
              style={{ display: 'block', maxHeight: '80vh', margin: '0 auto' }}
            />
          )}
          <Modal.Actions style={{ margin: '0 auto' }}>
            <Grid style={{ padding: '0px 10px 0 10px' }}>
              <Grid.Row>
                {deleteImageWarning ? (
                  <Grid.Column textAlign="right" width={16}>
                    <Button
                      color="red"
                      onClick={() => {
                        this.setState({ showImage: -1 });
                        onDeleteImage(showImage);
                      }}
                    >
                      <Icon name="warning" /> Är du säker?
                    </Button>
                    <Button onClick={() => this.setState({ deleteImageWarning: false })}>
                      <Icon name="cancel" /> Avbryt
                    </Button>
                  </Grid.Column>
                ) : (
                  <>
                    <Grid.Column textAlign="left" width={8}>
                      <Button
                        color="green"
                        as="a"
                        target="_blank"
                        href={`${API_ENDPOINT}/api/static/packing/${details[showImage] && details[showImage].image}`}
                      >
                        <Icon name="download" /> Ladda ner
                      </Button>
                    </Grid.Column>
                    <Grid.Column textAlign="right" width={8}>
                      <Button color="red" onClick={() => this.setState({ deleteImageWarning: true })}>
                        <Icon name="trash" /> Radera
                      </Button>
                    </Grid.Column>
                  </>
                )}
              </Grid.Row>
            </Grid>
          </Modal.Actions>
        </Modal>
        <Header>{name}</Header>
        <Form>
          {details &&
            details.map(({ image, text }, index) => {
              return (
                <Card key={`packing-list-detail-${index}`} fluid>
                  {deleteDetails === index ? (
                    <div style={{ margin: 24, textAlign: 'center' }}>
                      <Header>Är du säker?</Header>
                      <Button color="grey" onClick={() => this.setState({ deleteDetails: -1 })}>
                        <Icon name="cancel" />
                        Avbryt
                      </Button>
                      <Button
                        color="red"
                        onClick={() => this.setState({ deleteDetails: -1 }, onDeleteDetails(index))}
                        title="Radera"
                      >
                        <Icon name="trash" />
                        Radera
                      </Button>
                    </div>
                  ) : (
                    <div style={{ display: 'flex', flexBasis: 'column', opacity: deleteDetails > -1 ? 0.3 : 1 }}>
                      <div style={{ flexGrow: 1 }}>
                        <PackingListImage image={image} onClick={() => this.setState({ showImage: index })} />
                        <ReactQuill
                          style={{
                            borderTopLeftRadius: 0,
                            borderTopRightRadius: 0,
                            borderBottomRightRadius: 0,
                            backgroundColor: '#fafafa',
                          }}
                          theme="bubble"
                          modules={modules}
                          value={text}
                          readOnly={inactive}
                          onFocus={() => this.setState({ quillEnabled: true })}
                          onChange={(input) => quillEnabled && onChangeDetails(index, 'text', input)}
                        />
                      </div>
                      <div
                        style={{
                          width: 40,
                          minWidth: 40,
                          paddingRight: 1,
                          textAlign: 'right',
                          justifyContent: 'center',
                          zIndex: 100,
                          background: '#eee',
                        }}
                      >
                        <div
                          style={{
                            textAlign: 'center',
                            background: '#eee',
                            height: 34,
                            color: '#333',
                          }}
                        >
                          {uploading === index && <Loader active />}
                          <Button
                            icon="camera"
                            size="tiny"
                            color="black"
                            disabled={inactive || deleteDetails > -1 || uploading === index}
                            circular
                            style={{ position: 'absolute', marginTop: 6, right: 1 }}
                          />

                          <input
                            type="file"
                            accept="image/jpeg"
                            style={{
                              position: 'absolute',
                              width: 24,
                              top: 0,
                              right: 6,
                              opacity: 0,
                              cursor: 'pointer',
                              zIndex: 50,
                            }}
                            onChange={(event) => {
                              if (!event.target.files) {
                                return;
                              }

                              this.setState({ uploading: index });

                              const formData = new FormData();
                              formData.append('file', event.target.files[0], event.target.files[0].name);

                              axios
                                .post(`${API_ENDPOINT}/api/packing/${this.props.id}/image`, formData)
                                .then(({ data }) => {
                                  onAddImage(index, data);

                                  this.setState({
                                    image: data,
                                    hasImage: true,
                                    saved: false,
                                    uploading: -1,
                                  });
                                });
                            }}
                          />
                        </div>
                        <Button
                          color="black"
                          icon="arrow up"
                          circular
                          size="tiny"
                          style={{ marginTop: 6 }}
                          disabled={index === 0 || deleteDetails > -1 || inactive}
                          onClick={() => onMoveDetails(index, 'up')}
                        />
                        <Button
                          color="black"
                          icon="arrow down"
                          circular
                          size="tiny"
                          style={{ marginTop: 2 }}
                          disabled={index + 1 === details.length || deleteDetails > -1 || inactive}
                          onClick={() => onMoveDetails(index, 'down')}
                        />
                        <Button
                          color="red"
                          icon="trash"
                          circular
                          size="tiny"
                          disabled={deleteDetails > -1 || inactive}
                          onClick={() => this.setState({ deleteDetails: index })}
                          style={{ marginTop: 2 }}
                        />
                      </div>
                    </div>
                  )}
                </Card>
              );
            })}
        </Form>
        <Button style={{ marginTop: 12 }} fluid color="black" onClick={onAddDetails} disabled={inactive}>
          <Icon name="file text outline" /> Lägg till beskrivning
        </Button>
      </>
    );
  }
}

export default PackingListDetails;
