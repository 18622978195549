import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import { Menu, Button, Dropdown, Input, Flag, Icon } from 'semantic-ui-react';

import { navigate, fetchCustomerCategories } from '../../actions';

class Campaigns extends React.Component {
  state = {
    search: '',
    category: 0,
    contacts: [],
    distance: 1000,
    countries: { se: true, no: true, dk: true },
    excludePositions: new Set(),
    copyPopup: false
  };

  constructor(props) {
    super(props);

    this.contacts = React.createRef();
  }

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.navigate('campaigns');
    this.props.fetchCustomerCategories();

    axios.get(`${API_ENDPOINT}/api/contacts`).then(({ data }) => {
      this.setState({
        contacts: data.filter(contact => contact.email.length > 0)
      });
    });
  }

  componentWillReceiveProps(nextProps) {
    this.props.navigate('campaigns');
  }

  renderHeader() {
    return (
      <div style={{ top: 51, position: 'fixed', zIndex: 10, width: '100%' }}>
        <Menu style={{ background: '#fff', marginLeft: -22, marginRight: 20, height: 50 }}>
          <Menu.Item>
            <Input
              type="range"
              value={this.state.distance / 10}
              onChange={input => this.setState({ distance: input.target.value * 10 })}
            />
            <span style={{ width: 140, textAlign: 'right' }}>
              {this.state.distance === 1000 ? 'Alla avstånd' : `Inom ${this.state.distance} km`}
            </span>
          </Menu.Item>
          <Menu.Item>
            {Object.keys(this.state.countries).map(country => (
              <Button
                key={country}
                style={{
                  width: 60,
                  marginLeft: 5,
                  marginRight: 5,
                  border: '1px solid #999',
                  background: '#fff',
                  opacity: this.state.countries[country] ? 1 : 0.25
                }}
                onClick={() =>
                  this.setState({
                    countries: {
                      ...this.state.countries,
                      [country]: !this.state.countries[country]
                    }
                  })
                }
              >
                <Flag name={country} />
              </Button>
            ))}
          </Menu.Item>
          <Menu.Item>
            {this.props.positions
              .filter(p => p.id < 3)
              .map(({ id, position }) => (
                <Button
                  key={position}
                  style={{
                    marginLeft: 5,
                    marginRight: 5,
                    border: '1px solid #999',
                    background: '#fff',
                    opacity: !this.state.excludePositions.has(id) ? 1 : 0.25
                  }}
                  onClick={() => {
                    const { excludePositions } = this.state;
                    if (excludePositions.has(id)) {
                      excludePositions.delete(id);
                    } else {
                      excludePositions.add(id);
                    }
                    this.setState({ excludePositions });
                  }}
                >
                  {position}
                </Button>
              ))}
          </Menu.Item>
          <Menu.Item>
            <Dropdown
              selection
              style={{ width: 200 }}
              value={this.state.category}
              onChange={(event, data) => {
                this.setState({
                  category: data.value
                });
                window.scrollTo(0, 0);
              }}
              options={this.props.customerCategories.reduce(
                (acc, next) => [...acc, { key: `category${next.id}`, value: next.id, text: next.name }],
                [{ key: `category0`, value: 0, text: 'Alla kategorier' }]
              )}
            />
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Input
                icon={
                  <Icon
                    name={this.state.search.length === 0 ? 'search' : 'remove'}
                    style={{ boxShadow: 'none' }}
                    circular
                    link
                    onClick={() => this.setState({ search: '' })}
                  />
                }
                placeholder="Sök epost..."
                value={this.state.search}
                onChange={input => {
                  this.setState({ search: input.target.value });
                  window.scrollTo(0, 0);
                }}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }

  renderContacts() {
    const contacts = this.state.contacts
      .filter(contact => this.state.category === 0 || contact.category === this.state.category)
      .filter(contact => this.state.distance === 1500 || contact.distance <= this.state.distance)
      .filter(contact => this.state.countries[contact.country])
      .filter(
        contact => contact.position > 0 && contact.position < 3 && !this.state.excludePositions.has(contact.position)
      )
      .filter(contact => this.state.search.length < 3 || contact.email.includes(this.state.search.toLowerCase()))
      .reduce((acc, next) => `${acc}${next.email}, `, '');

    return contacts.substring(0, contacts.length - 2);
  }

  render() {
    return (
      <>
        {this.renderHeader()}
        {this.renderContacts()}
      </>
    );
  }
}

const mapStateToProps = ({ app }) => {
  const { customerCategories, customers, chains, positions } = app;

  return { customerCategories, customers, chains, positions };
};

export default connect(
  mapStateToProps,
  { navigate, fetchCustomerCategories }
)(Campaigns);
