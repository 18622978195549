import React from 'react';
import { Modal, Button, Icon, Header, Table, Input } from 'semantic-ui-react';
import axios from 'axios';
import md5 from 'md5';
import sha256 from 'sha256';
import { API_ENDPOINT } from '../../config';

class Profile extends React.Component {
  state = {
    currentPassword: '',
    newPassword: '',
    newPasswordConfirm: ''
  };

  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.handleClose} size="small">
        <Header icon="user" content="Redigera profil" />
        <Modal.Content>
          <Table basic="very">
            <Table.Body>
              <Table.Row>
                <Table.Cell width={5}>
                  <Header>Nuvarande lösenord</Header>
                </Table.Cell>
                <Table.Cell width={11}>
                  <Input
                    fluid
                    type="password"
                    value={this.state.currentPassword}
                    onChange={input => this.setState({ currentPassword: input.target.value })}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={5}>
                  <Header>Nytt lösenord</Header>
                </Table.Cell>
                <Table.Cell width={11}>
                  <Input
                    fluid
                    type="password"
                    value={this.state.newPassword}
                    onChange={input => this.setState({ newPassword: input.target.value })}
                  />
                </Table.Cell>
              </Table.Row>
              <Table.Row>
                <Table.Cell width={5}>
                  <Header>Nytt lösenord igen</Header>
                </Table.Cell>
                <Table.Cell width={11}>
                  <Input
                    fluid
                    type="password"
                    value={this.state.newPasswordConfirm}
                    onChange={input => this.setState({ newPasswordConfirm: input.target.value })}
                  />
                </Table.Cell>
              </Table.Row>
            </Table.Body>
          </Table>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="grey"
            onClick={() => {
              this.props.handleClose();
            }}
          >
            <Icon name="close" /> Avbryt
          </Button>
          <Button
            color="green"
            onClick={() => {
              const currentPassword = sha256(md5(this.state.currentPassword));
              const newPassword = sha256(md5(this.state.newPassword));
              const newPasswordConfirm = sha256(md5(this.state.newPasswordConfirm));

              axios
                .patch(`${API_ENDPOINT}/api/profile/password`, {
                  currentPassword,
                  newPassword,
                  newPasswordConfirm
                })
                .then(() => {
                  this.props.handleClose();
                })
                .catch(error => {
                  console.error(error);
                });
            }}
            disabled={this.state.newPassword !== this.state.newPasswordConfirm || this.state.newPassword.length < 6}
          >
            <Icon name="save" /> Spara
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

export default Profile;
