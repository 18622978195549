import React from 'react';
import axios from 'axios';
import moment from 'moment';
import { API_ENDPOINT } from '../../config';
import { Button, Form, Card, Icon, Header, Grid, Message } from 'semantic-ui-react';
import Textarea from 'react-textarea-autosize';

require('../../index.css');
require('./booking.css');

class BookingSMS extends React.Component {
  state = {
    text: '',
    sending: false,
    sent: false,
    timeout: 5,
    countdown: false,
    messages: []
  };

  componentDidMount() {
    axios.get(`${API_ENDPOINT}/api/bookings/${this.props.id}/sms`).then(({ data }) => {
      this.setState({ messages: data });
    });
  }

  onSubmit() {
    if (this.state.countdown) {
      clearInterval(this.countdown);
      clearTimeout(this.timeout);
      this.setState({ sending: false, timeout: 5, countdown: false });
    } else {
      this.countdown = setInterval(() => {
        this.setState({ timeout: this.state.timeout - 1 });
      }, 1000);

      this.setState({ sending: true, countdown: true });

      this.timeout = setTimeout(() => {
        if (this.state.sending) {
          this.setState({ sending: false, sent: true, timeout: 5, countdown: false });
        }
        clearTimeout(this.countdown);

        axios
          .post(`${API_ENDPOINT}/api/bookings/${this.props.id}/sms`, { text: this.state.text, nr: this.props.nr })
          .then(({ data }) => {
            this.setState({
              text: '',
              messages: [{ text: this.state.text, sent: moment() }, ...this.state.messages]
            });
          });
      }, 6000);
    }
  }

  render() {
    return (
      <Card fluid>
        <Card.Content>
          <Form>
            <Grid>
              <Grid.Row>
                <Grid.Column width={8}>
                  <Header>Skicka SMS</Header>
                </Grid.Column>
                <Grid.Column width={8}>
                  <span style={{ fontSize: 16, marginBottom: 6, marginRight: 2, float: 'right' }}>
                    {this.state.text.length}
                  </span>
                </Grid.Column>
              </Grid.Row>
            </Grid>
            <Textarea
              style={{ minHeight: 80 }}
              value={this.state.text}
              onChange={input => this.setState({ text: input.target.value })}
            />
          </Form>
          <br />
          {this.state.sending ? (
            <Button fluid onClick={this.onSubmit.bind(this)}>
              ({this.state.timeout}) Avbryt
            </Button>
          ) : (
            <Button fluid color="green" disabled={!this.state.text.length} onClick={this.onSubmit.bind(this)}>
              <Icon name="send" /> Skicka
            </Button>
          )}
          {this.state.messages.map((message, index) => (
            <div className="fade-in" key={`sms${index}`} style={{ marginTop: 16 }}>
              <Message attached style={{ background: '#fff' }} content={message.text} />
              <Message attached="bottom" style={{ textAlign: 'right' }}>
                Skickat:{' '}
                {moment(message.sent)
                  .subtract(1, 'minute')
                  .format('YYYY-MM-DD HH:mm')}
              </Message>
            </div>
          ))}
        </Card.Content>
      </Card>
    );
  }
}

export default BookingSMS;
