import {
  SET_BOOKING_LIST_INTERVAL,
  FETCH_CARS,
  FETCH_CHAINS,
  FETCH_CUSTOMERS,
  FETCH_CUSTOMER_CATEGORIES,
  FETCH_EVENTS,
  FETCH_POSITIONS,
  FETCH_STAFF,
  BOOKING_LIST_FILTER
} from './types';
import { API_ENDPOINT } from '../config';

import axios from 'axios';

export function setBookingListInterval(start, end, interval, desc) {
  return {
    type: SET_BOOKING_LIST_INTERVAL,
    payload: { start, end, interval, desc }
  };
}

export function fetchCars() {
  return dispatch => {
    axios.get(`${API_ENDPOINT}/api/cars`).then(({ data }) => {
      dispatch({
        type: FETCH_CARS,
        payload: data
      });
    });
  };
}

export function fetchChains() {
  return dispatch => {
    axios.get(`${API_ENDPOINT}/api/chains`).then(({ data }) => {
      dispatch({
        type: FETCH_CHAINS,
        payload: data
      });
    });
  };
}

export function fetchCustomers() {
  return dispatch => {
    axios.get(`${API_ENDPOINT}/api/customers`).then(({ data }) => {
      dispatch({
        type: FETCH_CUSTOMERS,
        payload: data
      });
    });
  };
}

export function fetchCustomerCategories() {
  return dispatch => {
    axios.get(`${API_ENDPOINT}/api/customers/categories`).then(({ data }) => {
      dispatch({
        type: FETCH_CUSTOMER_CATEGORIES,
        payload: data
      });
    });
  };
}

export function fetchEvents() {
  return dispatch => {
    axios.get(`${API_ENDPOINT}/api/events`).then(({ data }) => {
      dispatch({
        type: FETCH_EVENTS,
        payload: data
      });
    });
  };
}

export function fetchPositions() {
  return dispatch => {
    axios.get(`${API_ENDPOINT}/api/contacts/positions`).then(({ data }) => {
      dispatch({
        type: FETCH_POSITIONS,
        payload: data
      });
    });
  };
}

export function fetchStaff() {
  return dispatch => {
    axios.get(`${API_ENDPOINT}/api/staff`).then(({ data }) => {
      dispatch({
        type: FETCH_STAFF,
        payload: data
      });
    });
  };
}

export function bookingListFilter(payload) {
  return {
    type: BOOKING_LIST_FILTER,
    payload
  };
}
