import { FETCH_SETTINGS, SET_LAST_CUSTOMER, SET_CUSTOMER_FILTER, SET_LAST_STAFF, LOGOUT } from "../actions/types";

const INITIAL_STATE = {
  travelCompensation: 0,
  customerFilters: { search: "", country: "all", category: 1, chain: "all", marked: "all" },
  customerCategory: 1,
  salary: 0,
  tax: 0,
  milage: 0,
  invoiceNr: 0,
  nok: 0,
  dkk: 0,
  lastCustomer: 0,
  lastStaff: 0,
  mailTemplate: "",
  mobileInfo: "",
  coc: "",
  smsBefore: "",
  smsDuring: "",
  smsAfter: "",
  loading: true,
};

export default function app(state = INITIAL_STATE, action) {
  switch (action.type) {
    case FETCH_SETTINGS:
      return { ...state, ...action.payload, loading: false };
    case SET_LAST_CUSTOMER:
      return { ...state, lastCustomer: action.payload };
    case SET_CUSTOMER_FILTER:
      return { ...state, customerFilters: { ...state.customerFilters, ...action.payload } };
    case SET_LAST_STAFF:
      return { ...state, lastStaff: action.payload };
    case LOGOUT:
      return INITIAL_STATE;
    default:
      return state;
  }
}
