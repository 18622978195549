import React from 'react';
import { connect } from 'react-redux';
import { Table, Button, Input, Checkbox, Header, Dropdown, Icon } from 'semantic-ui-react';

require('../../index.css');

class BookingStaff extends React.Component {
  renderStaff() {
    return this.props.selectedStaff.map((staff, index) => {
      const { editStaff, remove } = this.props;

      if (!this.props.isAdmin) {
        return (
          <Table.Row key={`staff${staff.id}`}>
            <Table.Cell style={{ marginLeft: 20 }}>
              <Header>{staff.name}</Header>
            </Table.Cell>
            <Table.Cell style={{ fontSize: 16 }}>{staff.phone}</Table.Cell>
            {this.props.mission ? (
              <Table.Cell style={{ width: 80 }}>
                <Button circular icon="remove" size="tiny" color="red" onClick={() => remove(staff.id)} />
              </Table.Cell>
            ) : null}
          </Table.Row>
        );
      }

      return (
        <Table.Row key={`staff${staff.id}`} className="fade-in" style={{ textAlign: 'center' }}>
          <Table.Cell textAlign="left" colSpan={this.props.mission ? 6 : 1}>
            <Header style={{ marginLeft: 5, marginBottom: 0 }}>{staff.name}</Header>
            <span style={{ color: '#999', marginLeft: 5 }}>{staff.phone}</span>
          </Table.Cell>
          {!this.props.mission ? (
            <>
              <Table.Cell>
                <Input
                  style={{ width: 60 }}
                  value={String(staff.hours).replace('.00', '')}
                  onChange={input => {
                    input = input.target.value.replace(/,/g, '.').replace(/[^.0-9]/g, '');
                    editStaff('hours', input, index);
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: 60 }}
                  value={staff.salary}
                  onChange={input => {
                    input = input.target.value.replace(/[^0-9]/g, '');
                    editStaff('salary', input, index);
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: 60 }}
                  value={staff.perDiem}
                  onChange={input => {
                    input = input.target.value.replace(/[^0-9]/g, '');
                    editStaff('perDiem', input, index);
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <Input
                  style={{ width: 60 }}
                  value={staff.distance}
                  onChange={input => {
                    input = input.target.value.replace(/[^0-9]/g, '');
                    editStaff('distance', input, index);
                  }}
                />
              </Table.Cell>
              <Table.Cell>
                <Checkbox checked={staff.manager} onChange={(_, data) => editStaff('manager', data.checked, index)} />
              </Table.Cell>
            </>
          ) : null}
          <Table.Cell>
            <Button circular icon="remove" size="tiny" color="red" onClick={() => remove(staff.id)} />
          </Table.Cell>
        </Table.Row>
      );
    });
  }

  render() {
    return (
      <Table
        striped
        basic="very"
        size="small"
        style={{ marginBottom: this.props.isAdmin && !this.props.mission ? 500 : 0 }}
      >
        <Table.Header
          style={{
            textAlign: 'center',
            fontSize: 18
          }}
        >
          {this.props.isAdmin && !this.props.mission && (
            <Table.Row
              style={{
                height: 60,
                marginBottom: -20,
                background: '#eee'
              }}
            >
              <Table.HeaderCell />
              <Table.HeaderCell>Timmar</Table.HeaderCell>
              <Table.HeaderCell>Lön</Table.HeaderCell>
              <Table.HeaderCell>Trakt.</Table.HeaderCell>
              <Table.HeaderCell>Mil</Table.HeaderCell>
              <Table.HeaderCell>Ansv.</Table.HeaderCell>
              <Table.HeaderCell />
            </Table.Row>
          )}
        </Table.Header>
        <Table.Body>
          {this.renderStaff()}
          {this.props.isManager && (
            <>
              <Table.Row
                style={{
                  textAlign: 'center'
                }}
              >
                <Table.Cell colSpan={6}>
                  <Dropdown
                    fluid
                    style={{
                      fontSize: 16,
                      marginLeft: 10
                    }}
                    placeholder="Lägg till personal..."
                    search
                    selection
                    noResultsMessage="Inga träffar..."
                    value={this.props.newStaff}
                    onChange={(_, data) => this.props.selectNewStaff(data.value)}
                    options={this.props.staff
                      .filter(staff => staff.active && staff.id !== 8)
                      .reduce(
                        (acc, next) => {
                          if (this.props.selectedStaff.reduce((acc, next) => [...acc, next.id], []).includes(next.id)) {
                            return acc;
                          }
                          return [
                            ...acc,
                            {
                              key: next.id,
                              value: next.id,
                              text: next.name
                            }
                          ];
                        },
                        [{ key: 8, value: 8, text: 'Budget' }]
                      )}
                  />
                </Table.Cell>
                <Table.Cell style={{ width: 80 }}>
                  <Button
                    disabled={!this.props.newStaff}
                    icon="add"
                    size="small"
                    circular
                    color="green"
                    onClick={() => {
                      const newStaff = this.props.staff.find(staff => staff.id === this.props.newStaff);
                      newStaff.hours = 0;
                      newStaff.salary = this.props.salary;
                      newStaff.perDiem = 0;
                      newStaff.distance = 0;
                      newStaff.manager = false;

                      this.props.addNewStaff(newStaff);
                    }}
                  />
                </Table.Cell>
              </Table.Row>
              {!this.props.mission ? (
                <Table.Row>
                  <Table.Cell style={{ background: '#fff' }} colSpan={7}>
                    <Button fluid color="green" onClick={this.props.save} disabled={this.props.saveDisabled}>
                      <Icon name="save" />
                      Spara
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ) : null}
            </>
          )}
        </Table.Body>
      </Table>
    );
  }
}

const mapStateToProps = ({ app, settings, user }) => {
  const { staff } = app;
  const { salary } = settings;
  const isAdmin = user.accessLevel === 1;
  const isManager = user.accessLevel <= 2;

  return { salary, staff, isAdmin, isManager };
};

export default connect(mapStateToProps)(BookingStaff);
