import React from 'react';
import axios from 'axios';
import { Card, List, Table, Grid, Button, Input, Checkbox, Header, Dropdown, Icon } from 'semantic-ui-react';
import { API_ENDPOINT } from '../../config';

require('../../index.css');

class BookingPackingLists extends React.Component {
  state = {
    packingLists: [],
    selectedPackingLists: [],
  };

  componentDidMount() {
    axios.get(`${API_ENDPOINT}/api/packing`).then(({ data: packingLists }) => this.setState({ packingLists }));
    axios
      .get(`${API_ENDPOINT}/api/bookings/${this.props.bookingId}/packing`)
      .then(({ data: selectedPackingLists }) => this.setState({ selectedPackingLists }));
  }

  render() {
    const { packingLists, selectedPackingLists } = this.state;
    const { bookingId } = this.props;

    if (packingLists.length === 0) {
      return <div />;
    }

    return (
      <Card fluid>
        <Card.Content>
          <Header>Packlistor</Header>
          <List divided relaxed="very" verticalAlign="middle">
            {selectedPackingLists.map((id) => (
              <List.Item className="highlight-on-hover" key={`packing-list-${id}`}>
                <Grid>
                  <Grid.Row>
                    <Grid.Column width={12}>
                      <Header style={{ fontWeight: 'normal', fontSize: 16, marginTop: 8, marginLeft: 8 }}>
                        {packingLists.find((list) => list.id === id).title}
                      </Header>
                    </Grid.Column>
                    <Grid.Column width={4}>
                      <Button
                        style={{ float: 'right', marginRight: 8 }}
                        color="red"
                        icon="trash"
                        circular
                        onClick={() => {
                          this.setState({ selectedPackingLists: selectedPackingLists.filter((list) => list !== id) });
                          axios.delete(`${API_ENDPOINT}/api/bookings/${bookingId}/packing/${id}`);
                        }}
                      />
                      <a target="_blank" href={`${API_ENDPOINT}/api/packing/${id}/print`}>
                        <Button style={{ float: 'right', marginRight: 8 }} color="black" icon="print" circular />
                      </a>
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </List.Item>
            ))}
          </List>
          <Dropdown
            fluid
            style={{
              fontSize: 16,
              zIndex: 100,
            }}
            placeholder="Lägg till packlista..."
            search
            selection
            noResultsMessage="Inga träffar..."
            onChange={(_, { value }) => {
              axios.post(`${API_ENDPOINT}/api/bookings/${bookingId}/packing/${value}`);
              this.setState({ selectedPackingLists: [...selectedPackingLists, value] });
            }}
            options={packingLists
              .filter(({ id }) => !selectedPackingLists.includes(id))
              .map(({ id, title }) => ({
                key: id,
                value: id,
                text: title,
              }))}
          />
        </Card.Content>
      </Card>
    );
  }
}

export default BookingPackingLists;
