import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';
import {
  Button,
  Card,
  Checkbox,
  Dropdown,
  Form,
  Grid,
  Header,
  Icon,
  Input,
  Modal,
  Table,
  TextArea
} from 'semantic-ui-react';
import { fetchTickets } from '../../actions';

const INITIAL_STATE = {
  name: '',
  email: '',
  shipping: false,
  shippingAdress: '',
  saved: true,
  lap: 0,
  startgroup: '',
  participants: [{ firstname: '', lastname: '', age: '', phone: '', tshirt: '' }],
  ticketPrice: 0,
  registrationFee: 0,
  shippingPrice: 0,
  tshirtPrice: 0,
  notes: '',
  receipt: false
};

class AddTicket extends React.Component {
  state = INITIAL_STATE;

  save() {
    const {
      name,
      email,
      shipping,
      address,
      lap,
      startgroup,
      ticketPrice,
      registrationFee,
      shippingPrice,
      tshirtPrice,
      participants,
      notes,
      receipt
    } = this.state;
    axios
      .post(`${API_ENDPOINT}/api/tickets/addticket`, {
        name,
        email,
        shipping,
        address,
        lap,
        startgroup,
        ticketPrice,
        registrationFee,
        shippingPrice,
        tshirtPrice,
        participants,
        notes,
        receipt
      })
      .then(() => {
        this.props.fetchTickets();
        this.setState(INITIAL_STATE);
        this.props.close();
      });
  }

  renderParticipants() {
    return this.state.participants.map((participant, index) => {
      return (
        <Card fluid style={{ padding: 12 }} className="fade-in" key={`participant${index}`}>
          <Grid>
            <Grid.Column width={8} style={{ height: 68 }}>
              <Header as="h2">Deltagare {index + 1}</Header>
            </Grid.Column>
            {this.state.participants.length > 1 && (
              <Grid.Column width={8} style={{ textAlign: 'right' }}>
                <Button
                  basic
                  onClick={() => {
                    if (index === 0) {
                      this.setState({
                        participants: this.state.participants.slice(1)
                      });
                    } else {
                      this.setState({
                        participants: [
                          ...this.state.participants.slice(0, index),
                          ...this.state.participants.slice(index + 1)
                        ]
                      });
                    }
                  }}
                  circular
                  icon="remove"
                />
              </Grid.Column>
            )}
          </Grid>
          <Form.Group widths="equal">
            <Form.Input
              fluid
              label="Förnamn"
              type="text"
              value={participant.firstname}
              onChange={input => {
                this.setState({
                  participants: [
                    ...this.state.participants.slice(0, index),
                    { ...this.state.participants[index], firstname: input.target.value },
                    ...this.state.participants.slice(index + 1)
                  ]
                });
              }}
            />
            <Form.Input
              fluid
              label="Efternamn"
              type="text"
              value={participant.lastname}
              onChange={input => {
                this.setState({
                  participants: [
                    ...this.state.participants.slice(0, index),
                    { ...this.state.participants[index], lastname: input.target.value },
                    ...this.state.participants.slice(index + 1)
                  ]
                });
              }}
            />
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Input
              label="Ålder"
              type="text"
              value={participant.age}
              onChange={input => {
                this.setState({
                  participants: [
                    ...this.state.participants.slice(0, index),
                    { ...this.state.participants[index], age: input.target.value.replace(/[^0-9]/g, '') },
                    ...this.state.participants.slice(index + 1)
                  ]
                });
              }}
            />
            <Form.Input
              label="Telefon"
              type="text"
              value={participant.phone}
              onChange={input => {
                this.setState({
                  participants: [
                    ...this.state.participants.slice(0, index),
                    { ...this.state.participants[index], phone: input.target.value },
                    ...this.state.participants.slice(index + 1)
                  ]
                });
              }}
            />
            <Form.Dropdown
              placeholder="Välj storlek"
              fluid
              selection
              label="T-shirt"
              value={participant.tshirt}
              onChange={(event, data) => {
                this.setState({
                  participants: [
                    ...this.state.participants.slice(0, index),
                    { ...this.state.participants[index], tshirt: data.value },
                    ...this.state.participants.slice(index + 1)
                  ]
                });
              }}
              options={[
                {
                  key: `t-shirt${this.props.index}no`,
                  text: 'Nej tack',
                  value: ''
                },
                {
                  key: `t-shirt${this.props.index}xs`,
                  text: 'X-Small',
                  value: 'xs'
                },
                {
                  key: `t-shirt${this.props.index}s`,
                  text: 'Small',
                  value: 's'
                },
                {
                  key: `t-shirt${this.props.index}m`,
                  text: 'Medium',
                  value: 'm'
                },
                {
                  key: `t-shirt${this.props.index}l`,
                  text: 'Large',
                  value: 'l'
                },
                {
                  key: `t-shirt${this.props.index}xl`,
                  text: 'X-Large',
                  value: 'xl'
                }
              ]}
            />
          </Form.Group>
        </Card>
      );
    });
  }

  render() {
    return (
      <Modal
        open={this.props.open}
        onClose={() => {
          this.setState(INITIAL_STATE);
          this.props.close();
        }}
        closeOnDimmerClick={this.state.saved}
        size="large"
      >
        <Modal.Header>
          <Icon name="ticket" /> Lägg till biljett
        </Modal.Header>
        <Modal.Content>
          <Form onSubmit={this.save.bind(this)}>
            <Table>
              <Table.Body>
                <Table.Row>
                  <Table.Cell textAlign="right" style={{ width: 124 }}>
                    <Header sub>Namn</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      fluid
                      placeholder=""
                      value={this.state.name}
                      onChange={input =>
                        this.setState({
                          name: input.target.value,
                          saved: false
                        })
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header sub>Epost</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      fluid
                      placeholder=""
                      value={this.state.email}
                      onChange={input =>
                        this.setState({
                          email: input.target.value,
                          saved: false
                        })
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header sub>Lopp</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Dropdown
                      fluid
                      selection
                      placeholder="Välj stad..."
                      options={this.props.laps.map(lap => {
                        return { key: `lap${lap.id}`, value: lap.id, text: lap.city };
                      })}
                      value={this.state.mountingTime}
                      onChange={(event, data) =>
                        this.setState({
                          lap: data.value,
                          saved: false
                        })
                      }
                    />
                  </Table.Cell>
                </Table.Row>
                {this.state.lap !== 0 && (
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header sub>Grupp</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Grid columns={this.props.laps.find(lap => lap.id === this.state.lap).groups.length}>
                        {this.props.laps
                          .find(lap => lap.id === this.state.lap)
                          .groups.map(group => {
                            const active = this.state.startgroup === group.startgroup;

                            return (
                              <Grid.Column key={`group${group.startgroup}`}>
                                <Button
                                  fluid
                                  as={Card}
                                  style={{
                                    background: active ? '#333' : '#fff'
                                  }}
                                  onClick={() => {
                                    axios
                                      .post(`${API_ENDPOINT}/api/waterslide/price`, {
                                        shoppingCart: {
                                          lap: { id: this.state.lap },
                                          group: group.startgroup,
                                          discountCode: '',
                                          participants: []
                                        }
                                      })
                                      .then(
                                        ({ data: { ticketPrice, registrationFee, shippingPrice, tshirtPrice } }) => {
                                          this.setState({
                                            startgroup: group.startgroup,
                                            ticketPrice,
                                            registrationFee,
                                            shippingPrice,
                                            tshirtPrice
                                          });
                                        }
                                      );
                                  }}
                                >
                                  <Card.Content>
                                    <Card.Header
                                      style={{
                                        fontSize: 18,
                                        marginBottom: 6,
                                        height: 80,
                                        color: active ? '#fff' : '#333'
                                      }}
                                    >
                                      {group.starttime}
                                      <br />
                                      {group.title}
                                    </Card.Header>
                                    <Card.Header style={{ fontSize: 22, color: active ? '#fff' : '#333' }}>
                                      Grupp {group.startgroup}
                                    </Card.Header>
                                  </Card.Content>
                                </Button>
                              </Grid.Column>
                            );
                          })}
                      </Grid>
                    </Table.Cell>
                  </Table.Row>
                )}
                {this.state.startgroup.length > 0 && (
                  <>
                    <Table.Row>
                      <Table.Cell textAlign="right">
                        <Header sub>Hemleverans</Header>
                        <Checkbox
                          toggle
                          style={{ margin: 12 }}
                          checked={this.state.shipping}
                          onClick={() => this.setState({ shipping: !this.state.shipping })}
                        />
                      </Table.Cell>
                      <Table.Cell textAlign="center">
                        {this.state.shipping ? (
                          <TextArea
                            rows={3}
                            autoHeight
                            value={this.state.address}
                            onChange={input =>
                              this.setState({
                                address: input.target.value,
                                saved: false
                              })
                            }
                          />
                        ) : (
                          <Header>Ingen hemleverans</Header>
                        )}
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="right">
                        <Header sub>Pris</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <Grid columns={4}>
                          <Grid.Row>
                            <Grid.Column>
                              <Input
                                fluid
                                label="Biljetter"
                                value={this.state.ticketPrice}
                                onChange={input =>
                                  this.setState({ ticketPrice: Number(input.target.value.replace(/[^0-9]/g, '')) })
                                }
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Input
                                fluid
                                label="Registrering"
                                value={this.state.registrationFee}
                                onChange={input =>
                                  this.setState({ registrationFee: Number(input.target.value.replace(/[^0-9]/g, '')) })
                                }
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Input
                                fluid
                                label="T-Shirt"
                                value={this.state.tshirtPrice}
                                onChange={input =>
                                  this.setState({ tshirtPrice: Number(input.target.value.replace(/[^0-9]/g, '')) })
                                }
                              />
                            </Grid.Column>
                            <Grid.Column>
                              <Input
                                fluid
                                label="Leverans"
                                value={this.state.shippingPrice}
                                onChange={input =>
                                  this.setState({ shippingPrice: Number(input.target.value.replace(/[^0-9]/g, '')) })
                                }
                              />
                            </Grid.Column>
                          </Grid.Row>
                        </Grid>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="right">
                        <Header sub>Deltagare</Header>
                      </Table.Cell>
                      <Table.Cell>
                        {this.renderParticipants()}
                        <Button
                          color="black"
                          fluid
                          onClick={event => {
                            event.preventDefault();
                            this.setState({
                              participants: [
                                ...this.state.participants,
                                { firstname: '', lastname: '', age: '', phone: '', tshirt: '' }
                              ]
                            });
                          }}
                        >
                          Lägg till deltagare
                        </Button>
                      </Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell textAlign="right">
                        <Header sub>Anteckningar</Header>
                      </Table.Cell>
                      <Table.Cell>
                        <TextArea
                          rows={4}
                          autoHeight
                          value={this.state.notes}
                          onChange={input =>
                            this.setState({
                              notes: input.target.value,
                              saved: false
                            })
                          }
                        />
                      </Table.Cell>
                    </Table.Row>
                  </>
                )}
              </Table.Body>
            </Table>
          </Form>
        </Modal.Content>
        <Modal.Actions>
          <Grid>
            <Grid.Row>
              <Grid.Column width={8} textAlign="left" verticalAlign="middle">
                <Checkbox
                  label="Skicka kvitto"
                  checked={this.state.receipt}
                  onClick={() => this.setState({ receipt: !this.state.receipt })}
                />
              </Grid.Column>
              <Grid.Column width={8} textAlign="right">
                <Button
                  color="grey"
                  onClick={() => {
                    this.setState(INITIAL_STATE);
                    this.props.close();
                  }}
                >
                  <Icon name="cancel" /> Avbryt
                </Button>
                <Button color="green" onClick={this.save.bind(this)} disabled={this.state.saved}>
                  <Icon name="save" />
                  Spara
                </Button>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Actions>
      </Modal>
    );
  }
}

const mapStateToProps = ({ tickets }) => {
  const { laps } = tickets;

  return { laps };
};

export default connect(
  mapStateToProps,
  { fetchTickets }
)(AddTicket);
