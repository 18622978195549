import React from 'react';
import { connect } from 'react-redux';
import axios from 'axios';
import { API_ENDPOINT } from '../../../config';
import { Grid, Header, Dropdown, Input, Button } from 'semantic-ui-react';
import { fetchLaps } from '../../../actions';

class FillTickets extends React.Component {
  state = {
    selectedLap: 0,
    fillTickets: {},
    saved: true
  };

  componentDidMount() {
    if (!this.state.selectedLap && this.props.laps.length) {
      this.initLaps(this.props.laps);
    }
  }

  componentWillReceiveProps(nextProps) {
    if (!this.state.selectedLap && nextProps.laps.length) {
      this.initLaps(nextProps.laps);
    }
  }

  initLaps(laps) {
    this.setState({
      fillTickets: laps[0].groups.reduce((acc, next) => {
        return { ...acc, [next.startgroup]: Number(next.filltickets) };
      }, []),
      selectedLap: laps[0].id
    });
  }

  render() {
    if (!this.state.selectedLap) {
      return <div />;
    }

    return (
      <Grid verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={10}>
            <Header>Låtsasbiljetter</Header>
          </Grid.Column>
          <Grid.Column width={6}>
            <Button
              fluid
              disabled={this.state.saved}
              color="green"
              onClick={() => {
                axios
                  .put(`${API_ENDPOINT}/api/tickets/filltickets`, {
                    lap: this.state.selectedLap,
                    fillTickets: this.state.fillTickets
                  })
                  .then(() => {
                    this.props.fetchLaps();
                    this.setState({ saved: true });
                  });
              }}
            >
              Spara
            </Button>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={6}>
            <Dropdown
              fluid
              selection
              value={this.state.selectedLap}
              onChange={(event, data) => {
                this.setState({
                  fillTickets: this.props.laps
                    .find(lap => lap.id === data.value)
                    .groups.reduce((acc, next) => {
                      return { ...acc, [next.startgroup]: Number(next.filltickets) };
                    }, {}),
                  selectedLap: data.value,
                  saved: true
                });
              }}
              options={this.props.laps.reduce((acc, next) => {
                return [
                  ...acc,
                  {
                    key: `lap${next.id}`,
                    value: next.id,
                    text: next.city
                  }
                ];
              }, [])}
            />
          </Grid.Column>
          <Grid.Column width={3} textAlign="center">
            <Header>Sålda</Header>
          </Grid.Column>
          <Grid.Column width={3} textAlign="center">
            <Header>Låtsas</Header>
          </Grid.Column>
          <Grid.Column width={3} textAlign="center">
            <Header>Total</Header>
          </Grid.Column>
        </Grid.Row>
        {this.props.laps
          .find(lap => lap.id === this.state.selectedLap)
          .groups.map((group, index) => {
            const ticketsSold = this.props.tickets2019
              .filter(t => t.lap === this.state.selectedLap && t.startgroup === group.startgroup)
              .reduce((acc, next) => acc + next.participants.length, 0);

            const totalTickets = ticketsSold + this.state.fillTickets[group.startgroup];
            return (
              <Grid.Row key={`group${group.startgroup}`}>
                <Grid.Column width={1}>
                  <Header>{group.startgroup}</Header>
                </Grid.Column>
                <Grid.Column width={5} style={{ marginBottom: 4 }}>
                  {group.title}
                  <br />
                  {group.starttime}
                </Grid.Column>
                <Grid.Column width={3} textAlign="center">
                  {ticketsSold}
                </Grid.Column>
                <Grid.Column width={3} textAlign="center">
                  <Input
                    value={String(this.state.fillTickets[group.startgroup])}
                    onChange={input =>
                      this.setState({
                        fillTickets: { ...this.state.fillTickets, [group.startgroup]: Number(input.target.value) },
                        saved: false
                      })
                    }
                    style={{ width: 60 }}
                  />
                </Grid.Column>
                <Grid.Column width={3} textAlign="center">
                  {totalTickets}
                </Grid.Column>
              </Grid.Row>
            );
          })}
      </Grid>
    );
  }
}

const mapStateToProps = ({ tickets }) => {
  const { tickets2019, laps } = tickets;

  return { tickets2019, laps };
};

export default connect(
  mapStateToProps,
  { fetchLaps }
)(FillTickets);
