import React from "react";
import { connect } from "react-redux";
import { Icon, Modal, Header, Table, Button, Input, Popup } from "semantic-ui-react";
import axios from "axios";
import { API_ENDPOINT } from "../../config";
import { fetchCustomers } from "../../actions";

class ResetMarked extends React.Component {
  render() {
    return (
      <Modal open={this.props.open} onClose={this.props.close} size="small">
        <Header icon="undo" content="Återställ markerade" />
        <Modal.Content>
          <Header.Subheader>Är du säker? Detta kommer att ta bort markeringarna för samtliga kunder.</Header.Subheader>
        </Modal.Content>
        <Modal.Actions>
          <Button color="gray" onClick={() => this.props.close()}>
            Avbryt
          </Button>
          <Button
            content="Ta bort alla markeringar"
            onClick={() => {
              axios.patch(`${API_ENDPOINT}/api/customers/marked/resetall`).then(() => {
                this.props.fetchCustomers();
                this.props.close();
              });
            }}
            negative
          />
        </Modal.Actions>
      </Modal>
    );
  }
}

export default connect(null, { fetchCustomers })(ResetMarked);
