import React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Image, Dropdown, Icon } from 'semantic-ui-react';
import {
  fetchCars,
  fetchChains,
  fetchCustomers,
  fetchEvents,
  fetchSettings,
  fetchPositions,
  fetchStaff,
  fetchLaps,
  fetchTickets,
  fetch2018Tickets,
  logout,
} from '../actions';

import { WEB_ENDPOINT } from '../config';

import Profile from './profile/Profile';

import '../index.css';

class TopMenu extends React.Component {
  state = {
    profileVisible: false,
  };

  componentDidMount() {
    this.props.fetchCars();
    this.props.fetchChains();
    this.props.fetchCustomers();
    this.props.fetchEvents();
    this.props.fetchSettings();
    this.props.fetchPositions();
    this.props.fetchStaff();
    this.props.fetchLaps();
    this.props.fetchTickets();
    this.props.fetch2018Tickets();
  }

  render() {
    const main = this.props.isAdmin
      ? [
          ['Bokningar', 'bookings'],
          ['Säljstöd', 'customers'],
          ['Event', 'events'],
          ['Packlistor', 'packing'],
          ['Utskick', 'campaigns'],
          ['Personal', 'staff'],
          ['Rapporter', 'reports'],
          ['Biljetter', 'tickets'],
        ]
      : this.props.user.accessLevel === 2
      ? [
          ['Bokningar', 'bookings'],
          ['Packlistor', 'packing'],
          ['Säljstöd', 'customers'],
          ['Biljetter', 'tickets'],
        ]
      : this.props.user.accessLevel === 3 || this.props.user.accessLevel === 4
      ? [['Biljetter', 'tickets']]
      : [];

    return (
      <div
        className="no-print"
        style={{
          top: 0,
          position: 'fixed',
          zIndex: 300,
          width: '100%',
        }}
      >
        <Profile open={this.state.profileVisible} handleClose={() => this.setState({ profileVisible: false })} />
        <Menu
          inverted
          style={{
            borderRadius: 0,
            margin: 0,
            zIndex: 20,
            height: 52,
          }}
        >
          <Menu.Item>
            <Image src={`${WEB_ENDPOINT}/logo.png`} size="small" />
          </Menu.Item>
          {main.map((item) => (
            <Menu.Item
              key={item[1]}
              active={this.props.main === item[1]}
              style={{
                backgroundColor: this.props.main === item[1] ? '#fff' : '#191919',
              }}
              as={Link}
              to={`/${item[1]}`}
            >
              <span style={{ color: this.props.main === item[1] ? '#000' : '#fff' }}>{item[0]}</span>
            </Menu.Item>
          ))}
          <Menu.Menu position="right">
            {this.props.isAdmin && (
              <>
                <Menu.Item
                  active={this.props.main === 'graphs'}
                  style={{
                    backgroundColor: this.props.main === 'graphs' ? '#fff' : '#191919',
                  }}
                  as={Link}
                  to={'/graphs'}
                >
                  <span
                    style={{
                      color: this.props.main === 'graphs' ? '#000' : '#fff',
                    }}
                  >
                    <Icon name="area graph" size="large" />
                  </span>
                </Menu.Item>
                <Menu.Item
                  active={this.props.main === 'settings'}
                  style={{
                    backgroundColor: this.props.main === 'settings' ? '#fff' : '#1b1c1d',
                  }}
                  as={Link}
                  to={'/settings'}
                >
                  <span
                    style={{
                      color: this.props.main === 'settings' ? '#000' : '#fff',
                    }}
                  >
                    <Icon name="setting" size="large" />
                  </span>
                </Menu.Item>
              </>
            )}
            <Dropdown text={this.props.user.name} item>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => this.setState({ profileVisible: true })}>Profil</Dropdown.Item>
                {this.props.isAdmin && (
                  <Dropdown.Item as={Link} to="/backups">
                    Backuper
                  </Dropdown.Item>
                )}
                <Dropdown.Divider />
                <Dropdown.Item
                  onClick={() => {
                    window.location = 'https://admin.magnetcompany.net';
                    this.props.logout();
                  }}
                >
                  Logga ut
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }
}

const mapStateToProps = ({ navigation, user }) => {
  const { main, sub } = navigation;

  return {
    main,
    sub,
    user,
    isAdmin: user.accessLevel === 1,
  };
};

export default connect(mapStateToProps, {
  fetchCars,
  fetchChains,
  fetchCustomers,
  fetchEvents,
  fetchPositions,
  fetchSettings,
  fetchStaff,
  fetchLaps,
  fetchTickets,
  fetch2018Tickets,
  logout,
})(TopMenu);
