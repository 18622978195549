import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Grid, Image, Segment } from 'semantic-ui-react';
import { login } from '../actions';

class Login extends React.Component {
  state = {
    username: '',
    password: ''
  };

  onSubmit(event) {
    event.preventDefault();

    this.props.login(this.state.username, this.state.password);
  }

  render() {
    return (
      <div className="login-form" style={{ background: '#111' }}>
        <style>{`
        body > div,
        body > div > div,
        body > div > div > div.login-form {
          height: 100%;
        }
      `}</style>
        <Grid textAlign="center" style={{ height: '100%' }} verticalAlign="middle">
          <Grid.Column style={{ maxWidth: 450 }} className="fade-in">
            <Image src="/logo.png" style={{ margin: 50, width: 320 }} />
            <Form size="large" onSubmit={this.onSubmit.bind(this)}>
              <Segment stacked>
                <Form.Input
                  fluid
                  icon="user"
                  iconPosition="left"
                  placeholder="Användarnamn"
                  value={this.state.username}
                  onChange={input => this.setState({ username: input.target.value.toLowerCase() })}
                />
                <Form.Input
                  fluid
                  icon="lock"
                  iconPosition="left"
                  placeholder="Lösenord"
                  type="password"
                  value={this.state.password}
                  onChange={input => this.setState({ password: input.target.value })}
                />

                <Button color="black" fluid size="large">
                  Logga In
                </Button>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid>
      </div>
    );
  }
}

export default connect(
  null,
  { login }
)(Login);
