import React from 'react';
import { connect } from 'react-redux';
import { Card, Form, Input, Grid, TextArea, Button, Menu, Table } from 'semantic-ui-react';
import axios from 'axios';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css';

import '../utilities/quill.css';
import { modules } from '../utilities/quill';

import { navigate } from '../../actions';
import { API_ENDPOINT } from '../../config';

const SettingsCard = ({ title, value, int, callback }) => {
  return (
    <Table.Row>
      <Table.Cell>{title}</Table.Cell>
      <Table.Cell>
        <Input
          fluid
          value={value}
          onChange={input => callback(input.target.value.replace(',', '.').replace(/[^.0-9]/g, ''))}
          style={{ textAlign: 'right' }}
        />
      </Table.Cell>
    </Table.Row>
  );
};

class Settings extends React.Component {
  state = {
    travelCompensation: '',
    salary: '',
    tax: '',
    milage: '',
    invoiceNr: '',
    nok: '',
    dkk: '',
    mailTemplate: '',
    coc: '',
    mobileInfo: '',
    smsBefore: '',
    smsDuring: '',
    smsAfter: '',
    loadingSettings: true,
    quillEnabled: false,
    saved: true
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.navigate('settings');
    this.loadSettingsFromRedux(this.props);
  }

  componentWillReceiveProps(nextProps) {
    this.props.navigate('settings');
    this.loadSettingsFromRedux(nextProps);
  }

  loadSettingsFromRedux(props) {
    if (!props.settings.loading) {
      const {
        salary,
        travelCompensation,
        tax,
        milage,
        invoiceNr,
        nok,
        dkk,
        mailTemplate,
        coc,
        mobileInfo,
        smsBefore,
        smsDuring,
        smsAfter
      } = props.settings;

      this.setState({
        salary: String(salary),
        travelCompensation,
        tax,
        milage,
        invoiceNr: String(invoiceNr),
        nok,
        dkk,
        mailTemplate,
        coc,
        mobileInfo,
        loadingSettings: false,
        smsBefore,
        smsDuring,
        smsAfter
      });
    }
  }

  save() {
    axios.patch(`${API_ENDPOINT}/api/settings`, this.state).then(() => {
      this.setState({ saved: true });
    });
  }

  render() {
    const settings = [
      {
        title: 'Timlön',
        value: 'salary'
      },
      {
        title: 'Personalens milersättning',
        value: 'travelCompensation'
      },
      {
        title: 'Arbetsgivaravgifter',
        value: 'tax'
      },
      {
        title: 'Milersättning',
        value: 'milage'
      },
      {
        title: 'Senaste fakturanummer',
        value: 'invoiceNr'
      },
      {
        title: 'NOK',
        value: 'nok'
      },
      {
        title: 'DKK',
        value: 'dkk'
      }
    ];

    return (
      <div>
        <div
          style={{
            top: 51,
            position: 'fixed',
            zIndex: 10,
            width: '100%'
          }}
        >
          <Menu
            style={{
              background: '#fff',
              marginLeft: -22,
              paddingRight: 22
            }}
          >
            {/* <Menu.Item>
              <Button
                onClick={() => axios.get(`${API_ENDPOINT}/api/migrate`)}
                icon="upload"
                color="red"
              />
            </Menu.Item> */}
            <Menu.Menu position="right">
              <Menu.Item>
                <Button color="green" disabled={this.state.saved} onClick={this.save.bind(this)}>
                  Spara
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
        <Grid>
          <Grid.Row>
            <Grid.Column width={6}>
              <Card fluid>
                <Card.Content>
                  <Card.Description>
                    <Table basic="very">
                      <Table.Body>
                        {settings.map(({ id, title, value }) => (
                          <SettingsCard
                            key={value}
                            title={title}
                            value={this.state[value]}
                            callback={input =>
                              this.setState({
                                [value]: input,
                                saved: false
                              })
                            }
                          />
                        ))}
                      </Table.Body>
                    </Table>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column width={10}>
              <Card fluid>
                <Card.Content>
                  <Card.Header>Mall för mail</Card.Header>
                  <Card.Description>
                    <Form>
                      <TextArea
                        rows={20}
                        autoHeight
                        value={this.state.mailTemplate}
                        onChange={input =>
                          this.setState({
                            mailTemplate: input.target.value,
                            saved: false
                          })
                        }
                      />
                    </Form>
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <Card fluid>
                <Card.Content>
                  <Card.Header>SMS före event</Card.Header>
                  <Card.Meta>Skickas en timme före eventets uppbyggnad</Card.Meta>
                  <Card.Description>
                    <Form>
                      <TextArea
                        rows={3}
                        autoHeight
                        value={this.state.smsBefore}
                        onChange={input =>
                          this.setState({
                            smsBefore: input.target.value,
                            saved: false
                          })
                        }
                      />
                    </Form>
                    <span style={{ fontSize: 16, marginTop: 6, marginRight: 2, float: 'right' }}>
                      {this.state.smsBefore.length}
                    </span>
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card fluid>
                <Card.Content>
                  <Card.Header>SMS under event</Card.Header>
                  <Card.Meta>Skickas en timme efter att eventet påbörjats</Card.Meta>
                  <Card.Description>
                    <Form>
                      <TextArea
                        rows={3}
                        autoHeight
                        value={this.state.smsDuring}
                        onChange={input =>
                          this.setState({
                            smsDuring: input.target.value,
                            saved: false
                          })
                        }
                      />
                    </Form>
                    <span style={{ fontSize: 16, marginTop: 6, marginRight: 2, float: 'right' }}>
                      {this.state.smsDuring.length}
                    </span>
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card fluid>
                <Card.Content>
                  <Card.Header>SMS efter event</Card.Header>
                  <Card.Meta>Skickas en timme efter att eventet avslutats</Card.Meta>
                  <Card.Description>
                    <Form>
                      <TextArea
                        rows={3}
                        autoHeight
                        value={this.state.smsAfter}
                        onChange={input =>
                          this.setState({
                            smsAfter: input.target.value,
                            saved: false
                          })
                        }
                      />
                    </Form>
                    <span style={{ fontSize: 16, marginTop: 6, marginRight: 2, float: 'right' }}>
                      {this.state.smsAfter.length}
                    </span>
                  </Card.Description>
                </Card.Content>
              </Card>
              <Card fluid>
                <Card.Content>
                  <Card.Header>Uppförandekod (mobilen)</Card.Header>
                  <Card.Description>
                    <ReactQuill
                      theme="bubble"
                      modules={modules}
                      value={this.state.coc}
                      onFocus={() => this.setState({ quillEnabled: true })}
                      onChange={input => this.state.quillEnabled && this.setState({ coc: input, saved: false })}
                    />
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
            <Grid.Column width={8}>
              <Card fluid>
                <Card.Content>
                  <Card.Header>Viktig info (mobilen)</Card.Header>
                  <Card.Description>
                    <ReactQuill
                      theme="bubble"
                      modules={modules}
                      value={this.state.mobileInfo}
                      onFocus={() => this.setState({ quillEnabled: true })}
                      onChange={input => this.state.quillEnabled && this.setState({ mobileInfo: input, saved: false })}
                    />
                  </Card.Description>
                </Card.Content>
              </Card>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  return { settings };
};

export default connect(
  mapStateToProps,
  { navigate }
)(Settings);
