import React from 'react';
import { Table, Header } from 'semantic-ui-react';

import { formatPrice, formatDate } from '../../utilities';

class StaffReportListed extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps() {
    window.scrollTo(0, 0);
  }

  getEvents() {
    let sumHours = 0;
    let sumSalary = 0;
    let sumTravelCompensation = 0;
    let sumPerDiem = 0;

    const rows = this.props.events.map(event => {
      let {
        id,
        startdate,
        enddate,
        duration,
        name,
        customName,
        customerName,
        staff,
        hotelCost,
        travelCompensation,
        totalOut
      } = event;

      totalOut -= hotelCost;

      sumHours += staff.reduce((acc, next) => acc + Math.round(next.hours), 0);
      sumSalary += staff.reduce((acc, next) => acc + Math.round(next.hours) * next.salary, 0);
      sumTravelCompensation += staff.reduce((acc, next) => acc + next.distance * travelCompensation, 0);
      sumPerDiem += staff.reduce((acc, next) => acc + next.perDiem, 0);

      return (
        <Table.Row key={`event${id}`}>
          <Table.Cell>{formatDate(startdate, enddate, duration)}</Table.Cell>
          <Table.Cell>
            <Header>{customName === '' ? name : customName}</Header>
            {customerName}
          </Table.Cell>
          <Table.Cell>{staff.reduce((acc, next) => `${acc}, ${next.name}`, '').substring(2)}</Table.Cell>
          <Table.Cell textAlign="right">
            {formatPrice(staff.reduce((acc, next) => acc + Math.round(next.hours), 0), true)}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {formatPrice(staff.reduce((acc, next) => acc + Math.round(next.hours) * next.salary, 0))}
          </Table.Cell>
          <Table.Cell textAlign="right">
            {formatPrice(staff.reduce((acc, next) => acc + Math.round(next.distance * travelCompensation), 0))}
          </Table.Cell>
          <Table.Cell textAlign="right">{formatPrice(staff.reduce((acc, next) => acc + next.perDiem, 0))}</Table.Cell>
          <Table.Cell textAlign="right">{formatPrice(Math.round(totalOut))}</Table.Cell>
        </Table.Row>
      );
    });

    return { rows, sumHours, sumSalary, sumTravelCompensation, sumPerDiem };
  }

  render() {
    const { rows, sumHours, sumSalary, sumTravelCompensation, sumPerDiem } = this.getEvents();

    return (
      <Table unstackable basic="very" striped width={14}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width={2}>Datum</Table.HeaderCell>
            <Table.HeaderCell width={3}>Event</Table.HeaderCell>
            <Table.HeaderCell width={4}>Personal</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="right">
              Timmar
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="right">
              Lön
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="right">
              Resa
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="right">
              Traktamente
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="right">
              Totalt
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{rows}</Table.Body>
        <Table.Footer>
          <Table.Row>
            <Table.HeaderCell colSpan={3} />
            <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
              {formatPrice(Math.round(sumHours), true)}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
              {formatPrice(Math.round(sumSalary))}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
              {formatPrice(Math.round(sumTravelCompensation))}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
              {formatPrice(Math.round(sumPerDiem))}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="right" style={{ fontWeight: 'bold' }}>
              {formatPrice(Math.round(sumSalary + sumTravelCompensation + sumPerDiem))}
            </Table.HeaderCell>
          </Table.Row>
        </Table.Footer>
      </Table>
    );
  }
}

export default StaffReportListed;
