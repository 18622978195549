import React from 'react';
import { connect } from 'react-redux';
import { Header, Table, Button, Tab, Menu, Label, Loader } from 'semantic-ui-react';
import axios from 'axios';
import moment from 'moment';
import { API_ENDPOINT } from '../../../config';

class FailedPayments extends React.Component {
  state = {
    activeIndex: 1,
    unresolved: [],
    resolved: [],
    loading: true,
    updating: 0
  };

  componentDidMount() {
    this.fetchData();
  }

  fetchData = (mark = false, type) => {
    axios.get(`${API_ENDPOINT}/api/tickets/unpaid`).then(({ data: { unresolved, resolved } }) => {
      if (
        !mark ||
        ((mark && type === 'resolved' && resolved.length === this.state[type].length) ||
          (mark && type === 'unresolved' && unresolved.length === this.state[type].length))
      ) {
        this.setState({ unresolved, resolved, loading: false });
      }
    });
  };

  renderTickets(type) {
    if (this.state.loading) {
      return (
        <div style={{ height: 300 }}>
          <Loader active />
        </div>
      );
    }

    return (
      <Table selectable>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell style={{ width: 72 }}>Datum</Table.HeaderCell>
            <Table.HeaderCell>Beställare</Table.HeaderCell>
            <Table.HeaderCell>Epost</Table.HeaderCell>
            <Table.HeaderCell style={{ textAlign: 'right' }}>Summa</Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell style={{ width: 60 }}>Biljett</Table.HeaderCell>
            <Table.HeaderCell />
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {this.state[type].map((t, index) => (
            <Table.Row key={`ticket${index}`}>
              <Table.Cell>{t.id}</Table.Cell>
              <Table.Cell>
                <b>{`${moment(t.created).format('D')} ${
                  [
                    'januari',
                    'februari',
                    'mars',
                    'april',
                    'maj',
                    'juni',
                    'juli',
                    'augusti',
                    'september',
                    'oktober',
                    'november',
                    'december'
                  ][moment(t.created).format('M') - 1]
                }`}</b>
                <br />
                {moment(t.created).format('HH:mm')}
              </Table.Cell>
              <Table.Cell>{t.name}</Table.Cell>
              <Table.Cell>{t.email}</Table.Cell>
              <Table.Cell style={{ textAlign: 'right' }}>{t.total_price}:-</Table.Cell>
              <Table.Cell>{t.reason}</Table.Cell>
              <Table.Cell textAlign="right">
                <a
                  href={`https://2019.sommarkulbiljetter.se/resume/${t.token}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button circular color="facebook" icon="external" />
                </a>
              </Table.Cell>
              <Table.Cell>
                <Button
                  circular
                  color="black"
                  icon={type === 'resolved' ? 'undo' : 'check'}
                  onClick={async () => {
                    const tickets =
                      index === 0
                        ? this.state[type].slice(1)
                        : [...this.state[type].slice(0, index), ...this.state[type].slice(index + 1)];

                    this.setState({ [type]: tickets });

                    await axios.post(`${API_ENDPOINT}/api/tickets/resolveticket`, {
                      id: t.id,
                      notes: type === 'resolved' ? '' : 'resolved'
                    });

                    this.fetchData(true, type);
                  }}
                />
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    );
  }

  render() {
    return (
      <Tab
        activeIndex={this.state.activeIndex}
        onTabChange={(_, { activeIndex }) => {
          if (activeIndex > 0) {
            this.setState({ activeIndex });
          }
        }}
        panes={[
          {
            menuItem: (
              <Menu.Item as={Header} style={{ marginLeft: -24 }} key="header">
                Misslyckade betalningar
              </Menu.Item>
            ),
            render: () => {}
          },
          {
            menuItem: (
              <Menu.Item key="notsent">
                Att behandla
                {!this.state.loading && (
                  <Label color={this.state.unresolved.length ? 'red' : 'green'}>{this.state.unresolved.length}</Label>
                )}
              </Menu.Item>
            ),
            render: () => this.renderTickets('unresolved')
          },
          {
            menuItem: <Menu.Item key="sent">Klara</Menu.Item>,
            render: () => this.renderTickets('resolved')
          }
        ]}
      />
    );
  }
}

const mapStateToProps = ({ tickets }) => {
  const { tickets2019 } = tickets;

  return { tickets2019 };
};

export default connect(mapStateToProps)(FailedPayments);
