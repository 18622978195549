import { combineReducers } from 'redux';
import app from './appReducer';
import settings from './settingsReducer';
import user from './userReducer';
import navigation from './navigationReducer';
import tickets from './ticketsReducer';

export default combineReducers({
  app,
  settings,
  user,
  navigation,
  tickets,
});
