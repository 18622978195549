import React from 'react';
import { connect } from 'react-redux';
import { Header, Button } from 'semantic-ui-react';

import TicketCity from './TicketCity';
import Tickets2018 from './Tickets2018';

class TicketsSearch extends React.Component {
  state = {
    year: 2019
  };

  render() {
    return (
      <>
        <Header>
          Söker på "{this.props.search}" i{' '}
          <Button
            basic
            color="black"
            size="large"
            style={{ padding: 6, margin: 6 }}
            onClick={() => this.setState({ year: this.state.year === 2019 ? 2018 : 2019 })}
          >
            {this.state.year}
          </Button>
        </Header>

        {this.state.year === 2019 ? (
          <TicketCity search={this.props.search.toLowerCase()} />
        ) : (
          <Tickets2018 search={this.props.search.toLowerCase()} />
        )}
      </>
    );
  }
}

const mapStateToProps = ({ tickets }) => {
  const { tickets2018 } = tickets;

  return { tickets2018 };
};

export default connect(mapStateToProps)(TicketsSearch);
