import { NAVIGATE, SET_TICKET_CITY } from './types';

export function navigate(main) {
  return {
    type: NAVIGATE,
    payload: { main }
  };
}

export function setTicketCity(payload) {
  return {
    type: SET_TICKET_CITY,
    payload
  };
}
