import React from 'react';
import { connect } from 'react-redux';
import { Menu, Button, Icon, Input, Card } from 'semantic-ui-react';

import { navigate } from '../../actions';

import Event from './Event';

class EventList extends React.Component {
  state = {
    search: '',
    edit: 0,
    new: false,
    showInactive: false
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.navigate('events');
  }

  componentWillReceiveProps(nextProps) {
    this.props.navigate('events');
  }

  renderInactive() {
    return this.state.showInactive ? (
      <>
        <Button fluid size="large" disabled>
          Inaktiva event:
        </Button>
        {this.renderEventCard(false)}
      </>
    ) : (
      <Button fluid size="large" onClick={() => this.setState({ showInactive: true })}>
        Visa inaktiva event
      </Button>
    );
  }

  renderHeader() {
    return (
      <div style={{ top: 51, position: 'fixed', zIndex: 10, width: '100%' }}>
        <Menu style={{ background: '#fff', marginLeft: -22 }}>
          <Menu.Item>
            <Button color="green" style={{ cursor: 'pointer' }} onClick={() => this.setState({ new: true, edit: 0 })}>
              <Icon name="plus" />
              Lägg till event
            </Button>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item>
              <Input
                icon="search"
                style={{ marginRight: 22 }}
                placeholder="Sök event..."
                value={this.state.search}
                onChange={input => {
                  this.setState({ search: input.target.value });
                  window.scrollTo(0, 0);
                }}
              />
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }

  renderEventCard(active) {
    return this.props.events
      .filter(event => {
        if (this.state.search.length > 1 && active) {
          return event.name.toLowerCase().includes(this.state.search.toLowerCase());
        } else {
          return event.active === active;
        }
      })
      .filter(({ id }) => id !== 112)
      .map(event => {
        return (
          <Card
            key={`event${event.id}`}
            style={{
              background: `#${event.color}`,
              width: 220,
              height: 80,
              cursor: 'pointer'
            }}
            onClick={() => this.setState({ edit: event.id })}
          >
            <Card.Content>
              <Card.Header>{event.name}</Card.Header>
              {event.bookings && <Card.Meta>{event.bookings} bokningar</Card.Meta>}
            </Card.Content>
          </Card>
        );
      });
  }

  render() {
    return (
      <>
        <Event close={() => this.setState({ edit: 0, new: false })} id={this.state.edit} new={this.state.new} />
        {this.renderHeader()}
        {this.state.search.length > 1 && <h2>Söker på "{this.state.search}"</h2>}
        <Card.Group>
          {this.renderEventCard(true)}
          <br />
          {this.state.search.length <= 1 && this.renderInactive()}
        </Card.Group>
      </>
    );
  }
}

const mapStateToProps = ({ app }) => {
  const { events } = app;

  return { events };
};

export default connect(
  mapStateToProps,
  { navigate }
)(EventList);
