import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import moment from 'moment';
import { Grid, Segment } from 'semantic-ui-react';
import FillTickets from './dashboard/FillTickets';
import TicketsSold from './dashboard/TicketsSold';
import Revenue from './dashboard/Revenue';
import Tshirts from './dashboard/Tshirts';
import Shipping from './dashboard/Shipping';
import FailedPayments from './dashboard/FailedPayments';
import { setTicketCity } from '../../actions';

class TicketDashboard extends React.Component {
  state = {};

  componentDidMount() {
    this.props.setTicketCity('dashboard');
    window.scrollTo(0, 0);
  }

  render() {
    if (this.props.accessLevel === 4) {
      return (
        <Redirect
          to={`/tickets/${this.props.laps
            .find(lap => moment(lap.date).isAfter(moment()))
            .city.toLowerCase()
            .replace(/åä/g, 'a')
            .replace(/ö/g, 'o')}`}
        />
      );
    }

    const showEconomy = this.props.accessLevel === 1 || this.props.accessLevel === 3;

    return (
      <>
        <Grid columns={1}>
          <Grid.Row stretched>
            <Grid.Column>
              <Segment>
                <TicketsSold />
              </Segment>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Grid columns={3}>
          {showEconomy && (
            <Grid.Row stretched>
              <Grid.Column>
                <Segment>
                  <FillTickets />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <Tshirts />
                </Segment>
              </Grid.Column>
              <Grid.Column>
                <Segment>
                  <Revenue />
                </Segment>
              </Grid.Column>
            </Grid.Row>
          )}
          <Grid.Row>
            <Grid.Column width={8}>
              <Shipping />
            </Grid.Column>
            <Grid.Column width={8}>
              <FailedPayments />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </>
    );
  }
}

const mapStateToProps = ({ user, tickets }) => {
  const { accessLevel } = user;
  const { laps } = tickets;

  return { accessLevel, laps };
};

export default connect(
  mapStateToProps,
  { setTicketCity }
)(TicketDashboard);
