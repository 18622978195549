import React from 'react';
import { connect } from 'react-redux';
import { Button, Card, Dropdown, Form, Grid, Header, Icon, Input, Loader, Menu, Table } from 'semantic-ui-react';
import Textarea from 'react-textarea-autosize';
import { API_ENDPOINT } from '../../config';
import { days, months } from '../../utilities';
import { navigate } from '../../actions';
import moment from 'moment-timezone';
import axios from 'axios';

import { DateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';

import BookingStaff from './BookingStaff';

import './datepicker.css';
import './booking.css';

class Mission extends React.Component {
  state = {
    completed: false,
    confirmed: false,
    customName: '',
    duration: 1,
    enddate: moment.utc(),
    focusedInput: null,
    hours: ['00:00-00:00'],
    id: 0,
    internalNotes: '',
    loadingBooking: true,
    newEvent: false,
    newStaff: null,
    privateInternalNotes: '',
    saved: true,
    selectedStaff: [],
    sendSMS: false,
    smsBeforeText: '',
    smsDelay: false,
    staff: [],
    staffFollowUp: '',
    startdate: moment.utc()
  };

  componentDidMount() {
    moment.locale('sv');
    this.props.navigate('bookings', this.props.match.params.id || 'new');

    if (!this.props.match.params.id) {
      this.setState({ loadingBooking: false, newEvent: true });
    } else {
      this.fetchBooking();
    }
  }

  fetchBooking() {
    axios.get(`${API_ENDPOINT}/api/missions/${this.props.match.params.id}`).then(({ data }) => {
      const {
        completed,
        confirmed,
        custom_name: customName,
        duration,
        enddate,
        hours,
        internal_notes: internalNotes,
        private_internal_notes: privateInternalNotes,
        selectedStaff,
        send_sms: sendSMS,
        sms_before_sent: smsBeforeSent,
        sms_delay: smsDelay,
        staff_follow_up: staffFollowUp,
        startdate
      } = data;

      const hourArray = hours.match(/.{11}/g);

      this.setState({
        confirmed,
        customName,
        completed,
        duration,
        enddate: moment.utc(enddate.substring(0, 10)),
        hours: hourArray,
        id: this.props.match.params.id,
        internalNotes,
        loadingBooking: false,
        privateInternalNotes,
        selectedStaff,
        smsBeforeSent,
        smsDelay,
        sendSMS,
        staffFollowUp,
        startdate: moment.utc(startdate.substring(0, 10))
      });
    });
  }

  onChangeCustomer(customer) {
    const { name, notes_to_staff, distance, google_maps } = this.props.customers.find(c => c.id === customer);

    axios.get(`${API_ENDPOINT}/api/customers/${customer}/contacts`).then(({ data }) => {
      this.setState({
        customer,
        contacts: data,
        customerName: name,
        customerNotesToStaff: notes_to_staff,
        distance: Math.ceil((distance * 2) / 10),
        map: google_maps,
        saved: false
      });
    });
  }

  save() {
    const smsBeforeText = `${this.state.customName}\nKl ${this.state.hours[0]}\n${
      this.state.customName.length + this.state.internalNotes.length > 143
        ? this.state.internalNotes.substring(0, 140 - this.state.customName.length) + '...'
        : this.state.internalNotes
    }`;

    axios
      .put(`${API_ENDPOINT}/api/missions/${this.props.match.params.id}`, {
        ...this.state,
        smsBeforeText
      })
      .then(({ data }) => {
        if (this.state.newEvent) {
          this.setState({ id: data.id, token: data.token, newEvent: false, eventInserted: true, saved: true });
          this.props.history.push(`/bookings/mission/${data.id}`);
        } else {
          this.setState({ saved: true });
        }
      });
  }

  onChange(type, value) {
    this.setState({
      [type]: value,
      saved: false
    });
  }

  renderHours() {
    return this.state.hours.map((day, index) => {
      const weekday = moment.utc(this.state.startdate).add(index, 'days');

      return (
        <Card key={`hours${index}`} style={{ textAlign: 'center', width: 152 }}>
          <Header style={{ marginTop: 5, marginBottom: -2 }}>{days[weekday.day()]}</Header>
          {weekday.format('D')} {months[weekday.format('M') - 1].toLowerCase()}
          <Card.Content>
            <Input
              error={day.match(/^\d{2}:\d{2}-\d{2}:\d{2}$/) === null}
              fluid
              value={day}
              onChange={input => {
                const { hours } = this.state;
                hours[index] = input.target.value.replace(/[^\-:0-9]/g, '');
                this.onChange('hours', hours);
              }}
            />
          </Card.Content>
        </Card>
      );
    });
  }

  render() {
    if (this.state.loadingBooking) {
      return <Loader active size="massive" />;
    }

    return (
      <div>
        <div
          style={{
            top: 51,
            position: 'fixed',
            zIndex: 20,
            width: '100%'
          }}
        >
          <Menu
            style={{
              background: '#fff',
              marginLeft: -22,
              paddingRight: 22,
              height: 50
            }}
          >
            {this.props.history.length > 2 && (
              <Menu.Item>
                <Button
                  color="black"
                  onClick={() => (this.state.eventInserted ? this.props.history.go(-2) : this.props.history.goBack())}
                >
                  <Icon name="arrow circle left" />
                  Tillbaka
                </Button>
              </Menu.Item>
            )}
            <Menu.Menu position="right">
              {!this.state.newEvent && (
                <Menu.Item>
                  <Button
                    style={{ margin: 2 }}
                    circular
                    color={this.state.completed ? 'green' : 'grey'}
                    icon={this.state.completed ? 'checkmark box' : 'square outline'}
                    onClick={() => {
                      axios.patch(`${API_ENDPOINT}/api/bookings/${this.state.id}/completed`).then(({ data }) => {
                        const { completed } = data;
                        this.setState({ completed });
                      });
                    }}
                  />
                </Menu.Item>
              )}
              <Menu.Item>
                <Button
                  color="green"
                  disabled={this.state.saved || !this.state.customName.length}
                  onClick={this.save.bind(this)}
                >
                  Spara
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
        <Grid>
          <Grid.Column tablet={16} computer={12} largeScreen={10} widescreen={9}>
            <Table basic="very">
              <Table.Body>
                <Table.Row>
                  <Table.Cell textAlign="right" style={{ width: 120 }}>
                    <Header>Uppdrag</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Input
                      fluid
                      value={this.state.customName}
                      onChange={input => this.onChange('customName', input.target.value)}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Datum</Header>
                  </Table.Cell>
                  <Table.Cell textAlign="center">
                    <DateRangePicker
                      noBorder
                      startDate={this.state.startdate}
                      startDateId="start-date"
                      endDate={this.state.enddate}
                      endDateId="end-date"
                      onDatesChange={({ startDate, endDate }) => {
                        startDate.set({
                          hour: 12,
                          minute: 0,
                          second: 0,
                          millisecond: 0
                        });

                        this.setState({
                          startdate: startDate,
                          saved: false
                        });

                        if (endDate) {
                          endDate.set({
                            hour: 12,
                            minute: 0,
                            second: 0,
                            millisecond: 0
                          });

                          const duration = Math.abs(startDate.utc().diff(endDate.utc(), 'days')) + 1;

                          let hours = this.state.hours.slice(0);

                          if (hours.length > duration) {
                            hours = this.state.hours.slice(0, duration);
                          } else {
                            for (let i = hours.length; i < duration; i++) {
                              hours.push('00:00-00:00');
                            }
                          }

                          this.setState({
                            duration,
                            startdate: startDate,
                            enddate: endDate,
                            hours,
                            saved: false
                          });
                        }
                      }}
                      focusedInput={this.state.focusedInput}
                      onFocusChange={focusedInput => this.setState({ focusedInput })}
                      firstDayOfWeek={1}
                      startDatePlaceholderText="-"
                      endDatePlaceholderText="-"
                      displayFormat="YYYY-MM-DD"
                      isOutsideRange={() => false}
                      minimumNights={0}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>Tider</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Card.Group>{this.renderHours()}</Card.Group>
                  </Table.Cell>
                </Table.Row>
                {this.props.isAdmin ? (
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header>Noteringar</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Form>
                        <Textarea
                          style={{ background: '#ffffdf' }}
                          value={this.state.privateInternalNotes}
                          onChange={input => this.onChange('privateInternalNotes', input.target.value)}
                        />
                      </Form>
                    </Table.Cell>
                  </Table.Row>
                ) : null}
                <Table.Row>
                  <Table.Cell textAlign="right" style={{ width: 100 }}>
                    <Header>Beskrivning</Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Form>
                      <Textarea
                        value={this.state.internalNotes}
                        onChange={input => this.onChange('internalNotes', input.target.value)}
                      />
                    </Form>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell textAlign="right">
                    <Header>
                      Respons från
                      <br />
                      personal
                    </Header>
                  </Table.Cell>
                  <Table.Cell>
                    <Form>
                      <Textarea
                        value={this.state.staffFollowUp || ''}
                        onChange={input => this.onChange('staffFollowUp', input.target.value)}
                      />
                    </Form>
                  </Table.Cell>
                </Table.Row>
                {Number(this.state.hours.join('').replace(/[^0-9]/g, '')) !== 0 ? (
                  <Table.Row>
                    <Table.Cell textAlign="right">
                      <Header>Skicka SMS</Header>
                    </Table.Cell>
                    <Table.Cell>
                      <Dropdown
                        fluid
                        selection
                        value={this.state.sendSMS ? this.state.smsDelay : -1}
                        options={[-1, 0, 15, 30, 45, 60, 90, 120].reduce((acc, next) => {
                          return [
                            ...acc,
                            {
                              key: `smsdelay${next}`,
                              text: next === -1 ? 'Skicka ej' : `${next} min`,
                              value: next
                            }
                          ];
                        }, [])}
                        onChange={(_, data) =>
                          data.value === -1
                            ? this.setState({ saved: false, smsDelay: false, sendSMS: false })
                            : this.setState({ saved: false, smsDelay: data.value, sendSMS: true })
                        }
                      />
                      {this.state.sendSMS ? (
                        <Card fluid style={{ marginTop: 12, padding: 8, background: '#f4f4f4' }}>
                          {this.state.customName}
                          <br />
                          Kl {this.state.hours[0]}
                          <br />
                          {this.state.customName.length + this.state.internalNotes.length > 142
                            ? this.state.internalNotes.substring(0, 139 - this.state.customName.length) + '...'
                            : this.state.internalNotes}
                        </Card>
                      ) : null}
                    </Table.Cell>
                  </Table.Row>
                ) : null}
                <Table.Row>
                  <Table.Cell style={{ width: 140 }} />
                  <Table.Cell>
                    <Button.Group widths={2} fluid>
                      <Button
                        style={{
                          color: '#fff',
                          backgroundColor: this.state.confirmed ? '#ddd' : '#a33'
                        }}
                        onClick={() => this.onChange('confirmed', false)}
                      >
                        Ej slutförd
                      </Button>
                      <Button
                        style={{
                          color: '#fff',
                          backgroundColor: !this.state.confirmed ? '#ddd' : '#393'
                        }}
                        onClick={() => this.onChange('confirmed', true)}
                      >
                        Slutförd
                      </Button>
                    </Button.Group>
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan={2}>
                    <BookingStaff
                      selectedStaff={this.state.selectedStaff}
                      newStaff={this.state.newStaff}
                      selectNewStaff={newStaff =>
                        this.setState({
                          newStaff
                        })
                      }
                      addNewStaff={newStaff =>
                        this.setState({
                          selectedStaff: [...this.state.selectedStaff, newStaff],
                          newStaff: null,
                          saved: false
                        })
                      }
                      editStaff={(type, value, index) => {
                        this.setState({
                          selectedStaff: [
                            ...this.state.selectedStaff.slice(0, index),
                            {
                              ...this.state.selectedStaff[index],
                              [type]: value
                            },
                            ...this.state.selectedStaff.slice(index + 1)
                          ],
                          saved: false
                        });
                      }}
                      remove={staff =>
                        this.onChange('selectedStaff', this.state.selectedStaff.filter(s => s.id !== staff))
                      }
                      save={this.save.bind(this)}
                      mission={true}
                      saveDisabled={this.state.saved || !this.state.customName.length}
                    />
                  </Table.Cell>
                </Table.Row>
                <Table.Row>
                  <Table.Cell colSpan={2}>
                    <Button
                      fluid
                      style={{ marginBottom: 240 }}
                      color="green"
                      disabled={this.state.saved || !this.state.customName.length}
                      onClick={this.save.bind(this)}
                    >
                      Spara
                    </Button>
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
          <Grid.Column tablet={16} computer={6} largeScreen={8} widescreen={7} />
        </Grid>
      </div>
    );
  }
}

const mapStateToProps = ({ app, user }) => {
  const { customers, events } = app;
  const isAdmin = user.accessLevel === 1;

  return { customers, events, isAdmin };
};

export default connect(
  mapStateToProps,
  { navigate }
)(Mission);
