import React from 'react';
import { connect } from 'react-redux';
import { Grid, Header, Table } from 'semantic-ui-react';

const sizes = ['xs', 's', 'm', 'l', 'xl'];

class Tshirts extends React.Component {
  render() {
    return (
      <Grid verticalAlign="middle">
        <Grid.Row>
          <Grid.Column width={16}>
            <Header style={{ marginTop: 5 }}>T-shirts</Header>
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column width={16}>
            <Table basic="very">
              <Table.Header>
                <Table.Row>
                  <Table.HeaderCell style={{ borderBottom: '1px solid #000' }} />
                  <Table.HeaderCell textAlign="center" style={{ borderBottom: '1px solid #000' }}>
                    XS
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ borderBottom: '1px solid #000' }}>
                    S
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ borderBottom: '1px solid #000' }}>
                    M
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ borderBottom: '1px solid #000' }}>
                    L
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ borderBottom: '1px solid #000' }}>
                    XL
                  </Table.HeaderCell>
                  <Table.HeaderCell textAlign="center" style={{ borderBottom: '1px solid #000' }}>
                    Total
                  </Table.HeaderCell>
                </Table.Row>
              </Table.Header>
              <Table.Body>
                {this.props.laps.map((lap, index) => {
                  return (
                    <Table.Row key={`lap${index}`}>
                      <Table.Cell>{lap.city}</Table.Cell>
                      {sizes.map(size => (
                        <Table.Cell textAlign="center" key={`lap${index}size${size}`}>
                          {this.props.tickets2019
                            .filter(t => t.lap === lap.id)
                            .reduce(
                              (acc, next) =>
                                acc +
                                (next.full_refund
                                  ? 0
                                  : next.participants.reduce((acc, next) => acc + (next.tshirt === size ? 1 : 0), 0)),
                              0
                            )}
                        </Table.Cell>
                      ))}
                      <Table.Cell textAlign="center" style={{ fontWeight: 'bold' }}>
                        {this.props.tickets2019
                          .filter(t => t.lap === lap.id)
                          .reduce(
                            (acc, next) =>
                              acc +
                              (next.full_refund
                                ? 0
                                : next.participants.reduce((acc, next) => acc + (next.tshirt !== '' ? 1 : 0), 0)),
                            0
                          )}
                      </Table.Cell>
                    </Table.Row>
                  );
                })}
                <Table.Row>
                  <Table.Cell style={{ borderTop: '1px solid #000' }} />
                  {sizes.map(size => (
                    <Table.Cell
                      style={{ fontWeight: 'bold', borderTop: '1px solid #000' }}
                      textAlign="center"
                      key={`total${size}`}
                    >
                      {this.props.tickets2019.reduce(
                        (acc, next) =>
                          acc +
                          (next.full_refund
                            ? 0
                            : next.participants.reduce((acc, next) => acc + (next.tshirt === size ? 1 : 0), 0)),
                        0
                      )}
                    </Table.Cell>
                  ))}
                  <Table.Cell style={{ fontWeight: 'bold', borderTop: '1px solid #000' }} textAlign="center">
                    {this.props.tickets2019.reduce(
                      (acc, next) =>
                        acc +
                        (next.full_refund
                          ? 0
                          : next.participants.reduce((acc, next) => acc + (next.tshirt !== '' ? 1 : 0), 0)),
                      0
                    )}
                  </Table.Cell>
                </Table.Row>
              </Table.Body>
            </Table>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const mapStateToProps = ({ tickets }) => {
  const { laps, tickets2019 } = tickets;

  return { laps, tickets2019 };
};

export default connect(mapStateToProps)(Tshirts);
