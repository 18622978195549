import React from 'react';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Menu, Button, Input, Icon } from 'semantic-ui-react';
import moment from 'moment';

import TicketDashboard from './TicketDashboard';
import TicketCity from './TicketCity';
import Tickets2018 from './Tickets2018';
import TicketSearch from './TicketSearch';
import AddTicket from './AddTicket';

import { navigate, setTicketCity, fetchLaps, fetchTickets, fetch2018Tickets } from '../../actions';

class Tickets extends React.Component {
  state = {
    search: '',
    active: 'dashboard'
  };

  componentDidMount() {
    window.scrollTo(0, 0);

    this.props.navigate('tickets');
    this.props.fetchLaps();
    this.props.fetchTickets();
    this.props.fetch2018Tickets();
  }

  componentWillReceiveProps(nextProps) {
    this.props.navigate('tickets');
  }

  render() {
    if (!this.props.laps.length) {
      return <div />;
    }

    return (
      <>
        <AddTicket open={this.state.new} close={() => this.setState({ new: false })} />
        <div style={{ top: 51, position: 'fixed', zIndex: 10, width: '100%' }}>
          <Menu pointing secondary style={{ background: '#fff', marginLeft: -22, height: 50 }}>
            {this.props.accessLevel < 4 && (
              <Menu.Item>
                <Button color="green" style={{ cursor: 'pointer' }} onClick={() => this.setState({ new: true })}>
                  <Icon name="plus" />
                  Lägg till biljett
                </Button>
              </Menu.Item>
            )}
            {[
              this.props.accessLevel < 4 ? ['dashboard', 'Översikt'] : [],
              ['norrkoping', 'Norrköping'],
              ['stockholm', 'Stockholm'],
              ['jonkoping', 'Jönköping'],
              ['vasteras', 'Västerås'],
              ['orebro', 'Örebro'],
              ['goteborg', 'Göteborg'],
              ['malmo', 'Malmö'],
              ['tickets2018', '2018']
            ]
              .filter(item => item.length)
              .map(item => (
                <Menu.Item
                  key={item[0]}
                  active={item[0] === this.props.ticket && this.state.search < 3}
                  as={Link}
                  to={`/tickets/${item[0]}`}
                  onClick={() => this.setState({ search: '' })}
                >
                  {item[1]}
                </Menu.Item>
              ))}
            <Menu.Menu position="right">
              <Menu.Item>
                <Input
                  icon="search"
                  style={{ marginRight: 22 }}
                  placeholder="Sök biljett..."
                  value={this.state.search}
                  onChange={input => {
                    this.setState({ search: input.target.value });
                    window.scrollTo(0, 0);
                  }}
                />
              </Menu.Item>
            </Menu.Menu>
          </Menu>
        </div>
        {this.state.search.length > 2 ? (
          <TicketSearch search={this.state.search} />
        ) : this.props.accessLevel < 4 ? (
          <Switch>
            <Route path="/tickets" exact component={TicketDashboard} />
            <Route path="/tickets/dashboard" component={TicketDashboard} />
            <Route path="/tickets/tickets2018" component={Tickets2018} />
            <Route path="/tickets/:city" component={TicketCity} />
          </Switch>
        ) : (
          <Switch>
            <Route
              path="/tickets"
              exact
              component={() => (
                <Redirect
                  to={`/tickets/${this.props.laps
                    .find(lap => moment(lap.date).isAfter(moment()))
                    .city.toLowerCase()
                    .replace(/åä/g, 'a')
                    .replace(/ö/g, 'o')}`}
                />
              )}
            />
            <Route path="/tickets/tickets2018" component={Tickets2018} />
            <Route path="/tickets/:city" component={TicketCity} />
          </Switch>
        )}
      </>
    );
  }
}

const mapStateToProps = ({ navigation, user, tickets }) => {
  const { ticket } = navigation;
  const { accessLevel } = user;
  const { laps } = tickets;

  return { ticket, accessLevel, laps };
};

export default connect(
  mapStateToProps,
  { navigate, setTicketCity, fetchLaps, fetchTickets, fetch2018Tickets }
)(Tickets);
