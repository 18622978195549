import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Grid, Table, Button, Header, Popup, Icon } from 'semantic-ui-react';
import { setTicketCity } from '../../actions';

import ViewTicket from './ViewTicket';

const cities = {
  stockholm: [1, 'Stockholm', '15 juni'],
  jonkoping: [2, 'Jönköping', '29 juni'],
  vasteras: [3, 'Västerås', '7 juli'],
  orebro: [4, 'Örebro', '13 juli'],
  norrkoping: [5, 'Norrköping', '8 juli'],
  goteborg: [6, 'Göteborg', '27 juli'],
  malmo: [7, 'Malmö', '10 augusti']
};

class TicketCity extends React.Component {
  state = { view: null };

  componentDidMount() {
    if (!this.props.search) {
      this.props.setTicketCity(this.props.match.params.city);
    }
    window.scrollTo(0, 0);
  }

  componentWillReceiveProps(nextProps) {
    if (!this.props.search && this.props.match.params.city !== nextProps.match.params.city) {
      this.props.setTicketCity(nextProps.match.params.city);
      window.scrollTo(0, 0);
    }
  }

  render() {
    return (
      <>
        <ViewTicket
          open={this.state.view !== null}
          close={() => this.setState({ view: null })}
          ticket={this.state.view}
        />
        <Grid>
          <Grid.Row>
            <Grid.Column width={8}>
              <Header>
                {this.props.search
                  ? 'Biljetter 2019'
                  : `${cities[this.props.match.params.city][1]} - ${cities[this.props.match.params.city][2]}`}
              </Header>
            </Grid.Column>
            <Grid.Column width={8} textAlign="right">
              <Header style={{ marginRight: 2 }}>
                {!this.props.search &&
                  `${
                    this.props.tickets2019.filter(t => t.lap === cities[this.props.match.params.city][0] && t.picked_up)
                      .length
                  } av ${
                    this.props.tickets2019.filter(t => t.lap === cities[this.props.match.params.city][0]).length
                  } beställningar uthämtade`}
              </Header>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <Table selectable>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Biljett</Table.HeaderCell>
              <Table.HeaderCell>Grupp</Table.HeaderCell>
              <Table.HeaderCell>Beställare</Table.HeaderCell>
              <Table.HeaderCell>Epost</Table.HeaderCell>
              <Table.HeaderCell>Deltagare</Table.HeaderCell>
              <Table.HeaderCell>Anteckningar</Table.HeaderCell>
              <Table.HeaderCell style={{ width: 60 }} />
              <Table.HeaderCell style={{ width: 60 }} />
              <Table.HeaderCell style={{ width: 60 }} />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.props.tickets2019
              .filter(t => {
                if (this.props.search) {
                  const { search } = this.props;
                  const emailMatch = t.email.toLowerCase().includes(search);
                  const ticketMatch = `t0${t.ticket}`.includes(search);
                  let participantMatch = false;
                  t.participants.forEach(({ firstname, lastname, phone }) => {
                    if (
                      firstname.toLowerCase().includes(search) ||
                      lastname.toLowerCase().includes(search) ||
                      `${firstname} ${lastname}`.toLowerCase().includes(search) ||
                      (search.replace(/[^.0-9]/g, '').length > 0 &&
                        phone.toLowerCase().includes(search.replace(/[^.0-9]/g, '')))
                    ) {
                      participantMatch = true;
                    }
                  });

                  return emailMatch || ticketMatch || participantMatch;
                } else {
                  return t.lap === cities[this.props.match.params.city][0];
                }
              })
              .map((t, index) => (
                <Table.Row
                  style={{ cursor: 'pointer' }}
                  key={`ticket${index}`}
                  onClick={() => this.setState({ view: t })}
                >
                  <Table.Cell style={{ width: 80 }}>T0{t.ticket}</Table.Cell>
                  <Table.Cell style={{ width: 60 }}>{t.startgroup}</Table.Cell>
                  <Table.Cell style={{ width: 180 }}>{t.name}</Table.Cell>
                  <Table.Cell style={{ width: 220 }}>{t.email}</Table.Cell>
                  <Table.Cell style={{ width: 200 }}>
                    {t.participants.map((p, index) => (
                      <p key={`ticket${t.id}participant${index}`}>
                        {p.firstname} {p.lastname}
                      </p>
                    ))}
                  </Table.Cell>
                  <Table.Cell>{t.notes}</Table.Cell>
                  <Table.Cell textAlign="right">
                    {t.refund && (
                      <Icon.Group size="huge">
                        <Icon size="small" color="grey" name="credit card outline" style={{ opacity: 0.4 }} />
                        <Icon size="mini" name="undo" />
                      </Icon.Group>
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    {t.shipping && (
                      <Icon color={t.sent ? 'green' : 'red'} size="large" name={`mail${t.sent ? ' outline' : ''}`} />
                    )}
                  </Table.Cell>
                  <Table.Cell textAlign="right">
                    <Popup
                      position="left center"
                      wide="very"
                      trigger={
                        <Button
                          style={{ margin: 2 }}
                          circular
                          color={t.picked_up ? 'green' : 'grey'}
                          icon={t.picked_up ? 'checkmark box' : 'square outline'}
                        />
                      }
                      content={
                        t.picked_up ? `Uthämtad ${moment(t.picked_up_time).format('YYYY-MM-DD HH:mm')}` : 'Ej uthämtad'
                      }
                    />
                  </Table.Cell>
                </Table.Row>
              ))}
          </Table.Body>
        </Table>
      </>
    );
  }
}

const mapStateToProps = ({ tickets }) => {
  const { tickets2019 } = tickets;

  return { tickets2019 };
};

export default connect(
  mapStateToProps,
  { setTicketCity }
)(TicketCity);
