import { FETCH_SETTINGS, SET_LAST_CUSTOMER, SET_LAST_STAFF, SET_CUSTOMER_FILTER } from "./types";
import { API_ENDPOINT } from "../config";

import axios from "axios";

export function fetchSettings() {
  return (dispatch) => {
    axios.get(`${API_ENDPOINT}/api/settings`).then(({ data }) => {
      const salary = Number(data.find((d) => d.id === 1).amount);
      const travelCompensation = Number(data.find((d) => d.id === 2).amount);
      const tax = Number(data.find((d) => d.id === 3).amount);
      const milage = Number(data.find((d) => d.id === 4).amount);
      const invoiceNr = Number(data.find((d) => d.id === 5).amount);
      const nok = Number(data.find((d) => d.id === 6).amount);
      const dkk = Number(data.find((d) => d.id === 7).amount);
      const lastCustomer = Number(data.find((d) => d.id === 8).amount);
      const lastStaff = Number(data.find((d) => d.id === 9).amount);
      const mailTemplate = data.find((d) => d.id === 10).text;
      const coc = data.find((d) => d.id === 11).text;
      const mobileInfo = data.find((d) => d.id === 12).text;
      const smsBefore = data.find((d) => d.id === 13).text;
      const smsDuring = data.find((d) => d.id === 14).text;
      const smsAfter = data.find((d) => d.id === 15).text;

      dispatch({
        type: FETCH_SETTINGS,
        payload: {
          salary,
          travelCompensation,
          tax,
          milage,
          invoiceNr,
          nok,
          dkk,
          lastCustomer,
          lastStaff,
          mailTemplate,
          coc,
          mobileInfo,
          smsBefore,
          smsDuring,
          smsAfter,
        },
      });
    });
  };
}

export function setLastCustomer(value) {
  return (dispatch) => {
    axios.patch(`${API_ENDPOINT}/api/settings/8`, { key: "amount", value }).then(() => {
      dispatch({
        type: SET_LAST_CUSTOMER,
        payload: value,
      });
    });
  };
}

export function setLastStaff(value) {
  return (dispatch) => {
    axios.patch(`${API_ENDPOINT}/api/settings/9`, { key: "amount", value }).then(() => {
      dispatch({
        type: SET_LAST_STAFF,
        payload: value,
      });
    });
  };
}

export function setCustomerFilter(value) {
  return {
    type: SET_CUSTOMER_FILTER,
    payload: value,
  };
}
