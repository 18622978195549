import React from 'react';
import { Container, Grid, List, Loader, Icon, Segment, Header, Card, Image } from 'semantic-ui-react';
import axios from 'axios';
import { API_ENDPOINT } from '../../config';

const INITIAL_STATE = {
  items: [],
  title: '',
  saved: true,
  loading: true,
  error: null,
};

class PublicPackingList extends React.Component {
  state = INITIAL_STATE;

  componentDidMount() {
    this.fetchPackingList(this.props.match.params.id);
  }

  fetchPackingList(id) {
    if (id === 'new') {
      this.setState({
        ...INITIAL_STATE,
        loading: false,
      });
    } else {
      axios.get(`${API_ENDPOINT}/api/packing/${id || this.state.id}`).then(({ data }) => {
        this.setState({ ...INITIAL_STATE, ...data, loading: false });
      });
    }
  }
  render() {
    const { error, title, items, loading } = this.state;

    if (loading) {
      return <Loader size="huge" active />;
    }

    if (error) {
      return (
        <Segment textAlign="center" style={{ height: 600, paddingTop: 240 }}>
          <Icon name="find" size="huge" />
          <Header>Listan kunde ej hittas</Header>
        </Segment>
      );
    }

    return (
      <Container style={{ marginTop: 24, marginBottom: 24 }}>
        <Grid columns="equal" fluid>
          <Grid.Row>
            <Grid.Column>
              <Header size="huge">{title}</Header>
            </Grid.Column>
          </Grid.Row>
          {items.map(({ id, name, sub, details }) => (
            <Grid.Row key={`item-${id}`}>
              <Grid.Column>
                <Header>{name}</Header>
                {sub.map((name, index) => (
                  <List.Item key={`sub-item-${id}-${index}`}>&bull; {name}</List.Item>
                ))}
                {details.map(({ text, image }, index) => (
                  <Card fluid key={`item-details-${id}-${index}`}>
                    {image && (
                      <Image
                        src={`${API_ENDPOINT}/api/static/packing/${image}?i=${Math.ceil(Math.random() * 10000)}`}
                        role="presentation"
                      />
                    )}
                    {text && (
                      <Card.Content>
                        <div dangerouslySetInnerHTML={{ __html: text }} />
                      </Card.Content>
                    )}
                  </Card>
                ))}
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
      </Container>
    );
  }
}

export default PublicPackingList;
