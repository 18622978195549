import React from "react";
import { Link, Route, Redirect } from "react-router-dom";
import { Input, Menu, Dropdown, Button, Icon, Progress } from "semantic-ui-react";
import { connect } from "react-redux";
import Chains from "./Chains";
import ResetMarked from "./ResetMarked";
import { navigate, fetchChains, fetchCustomerCategories, setCustomerFilter } from "../../actions";

import Customer from "./Customer";

class CustomerList extends React.Component {
  state = {
    chains: false,
    resetMarked: false,
  };

  componentDidMount() {
    this.props.navigate("customers");

    this.props.fetchChains();
    this.props.fetchCustomerCategories();
  }

  componentWillReceiveProps() {
    this.props.navigate("customers");
  }

  renderHeader() {
    return (
      <div style={{ top: 51, position: "fixed", zIndex: 10, width: "100%" }}>
        <Menu style={{ background: "#fff", marginLeft: -22, marginRight: 20 }}>
          <Menu.Item>
            <Button color="green" style={{ cursor: "pointer" }} as={Link} to="/customers/new">
              <Icon name="plus" />
              Ny kund
            </Button>
          </Menu.Item>
          <Menu.Menu position="right">
            <Menu.Item style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
              <Progress
                precision={0.1}
                style={{ width: 300 }}
                size="small"
                value={this.props.nrOfMarkedCustomers}
                total={this.props.customers.length}
                color="blue"
              >
                <div style={{ marginTop: 4 }}>
                  <span style={{ fontSize: 16 }}>
                    {this.props.nrOfMarkedCustomers} / {this.props.customers.length}{" "}
                  </span>
                  <span
                    style={{ fontWeight: "normal", marginLeft: 8, cursor: "pointer", color: "#999" }}
                    onClick={() => this.setState({ resetMarked: true })}
                  >
                    (Återställ)
                  </span>
                </div>
              </Progress>
            </Menu.Item>
            <Menu.Item>
              <Button color="black" style={{ cursor: "pointer" }} onClick={() => this.setState({ chains: true })}>
                Kedjor
              </Button>
            </Menu.Item>
          </Menu.Menu>
        </Menu>
      </div>
    );
  }

  renderCustomerMenu() {
    return this.props.customers
      .filter((customer) => {
        if (this.props.customerFilters.search.length < 1) {
          return true;
        }

        const search = this.props.customerFilters.search.toLowerCase();

        if (customer.name.toLowerCase().includes(search)) {
          return true;
        }

        if (customer.city.toLowerCase().includes(search)) {
          return true;
        }

        let found = false;
        customer.contacts.forEach((contact) => {
          if (
            contact.name.toLowerCase().includes(search) ||
            contact.phone.toLowerCase().includes(search) ||
            contact.mobile.toLowerCase().includes(search) ||
            contact.email.toLowerCase().includes(search)
          ) {
            found = true;
          }
        });
        return found;
      })
      .filter(
        (customer) =>
          this.props.customerFilters.country === "all" || customer.country === this.props.customerFilters.country
      )
      .filter(
        (customer) =>
          this.props.customerFilters.category === "all" || customer.category === this.props.customerFilters.category
      )
      .filter(
        (customer) =>
          this.props.customerFilters.chain === "all" || customer.chain === Number(this.props.customerFilters.chain)
      )
      .filter(
        (customer) =>
          this.props.customerFilters.marked === "all" ||
          (this.props.customerFilters.marked === "marked" && customer.marked) ||
          (this.props.customerFilters.marked !== "marked" && !customer.marked)
      )
      .map((customer, index) => (
        <Menu.Item
          as={Link}
          to={`/customers/${customer.id}`}
          key={`customer-${customer.id}-${index}`}
          style={{ paddingTop: 12, paddingBottom: 12 }}
        >
          <div style={{ height: 30, width: 16, float: "left" }}>
            <span style={{ color: "#2e42d3" }}>{`${customer.marked ? "• " : " "}`}</span>
          </div>
          {customer.name}
        </Menu.Item>
      ));
  }

  render() {
    if (this.props.loading) {
      return <div />;
    }

    if (this.props.location.pathname === "/customers") {
      return <Redirect to={`customers/${this.props.lastCustomer}`} />;
    }

    return (
      <div style={{ display: "flex", marginTop: 0 }}>
        <Chains
          open={this.state.chains}
          close={() => this.setState({ chains: false })}
          showChain={(chain) => this.setState({ chain, chains: false })}
        />
        <ResetMarked
          open={this.state.resetMarked}
          close={() => this.setState({ resetMarked: false })}
          nrOfMarkedCustomers={this.props.nrOfMarkedCustomers}
          totalNrOfCustomers={this.props.customers.length}
        />
        {this.renderHeader()}
        <div style={{ width: 200, float: "left", marginRight: 20 }}>
          <Menu vertical>
            <Menu.Item>
              <Input>
                <input
                  placeholder="Sök..."
                  style={{ borderBottomLeftRadius: 0, borderBottomRightRadius: 0, zIndex: 2 }}
                  value={this.props.customerFilters.search}
                  onChange={(input) => {
                    this.props.setCustomerFilter({ search: input.target.value });
                  }}
                />
              </Input>
              <Dropdown
                selection
                fluid
                style={{ borderRadius: 0, marginTop: -1 }}
                options={[
                  { key: "all", value: "all", text: "Alla länder" },
                  { key: "se", value: "se", flag: "se", text: "Sverige" },
                  { key: "no", value: "no", flag: "no", text: "Norge" },
                  { key: "dk", value: "dk", flag: "dk", text: "Danmark" },
                ]}
                value={this.props.customerFilters.country}
                onChange={(_, data) => this.props.setCustomerFilter({ country: data.value })}
              />
              <Dropdown
                selection
                fluid
                style={{ borderRadius: 0, marginTop: -1 }}
                options={[
                  { value: "all", text: "Alla kategorier" },
                  ...this.props.customerCategories.map(({ id, name }) => ({
                    key: `customer-category-${id}`,
                    value: id,
                    text: name,
                  })),
                ]}
                value={this.props.customerFilters.category}
                onChange={(_, data) => {
                  this.props.setCustomerFilter({ category: data.value });
                }}
              />
              <Dropdown
                selection
                fluid
                style={{ borderRadius: 0, marginTop: -1 }}
                options={[
                  {
                    key: `chain${0}`,
                    value: "all",
                    text: "Alla kedjor",
                  },
                  ...this.props.chains.map((chain) => {
                    return {
                      key: `chain${chain.id}`,
                      value: String(chain.id),
                      text: chain.name,
                    };
                  }),
                ]}
                value={String(this.props.customerFilters.chain)}
                onChange={(_, data) => {
                  this.props.setCustomerFilter({ chain: data.value });
                }}
              />
              <Dropdown
                selection
                fluid
                style={{ borderTopLeftRadius: 0, borderTopRightRadius: 0, marginTop: -1 }}
                options={[
                  {
                    key: "all",
                    value: "all",
                    text: "Alla markeringar",
                  },
                  {
                    key: "marked",
                    value: "marked",
                    text: "Endast markerade",
                  },
                  {
                    key: "unmarked",
                    value: "unmarked",
                    text: "Endast omarkerade",
                  },
                ]}
                value={this.props.customerFilters.marked}
                onChange={(_, data) => {
                  this.props.setCustomerFilter({ marked: data.value });
                }}
              />
            </Menu.Item>
            {this.renderCustomerMenu()}
          </Menu>
        </div>
        <div
          style={{
            width: "100%",
            float: "right",
          }}
        >
          <Route path="/customers/:id" component={Customer} />
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ app, settings, user }) => {
  const { customers, chains, customerCategories } = app;
  const { lastCustomer, loading, customerFilters } = settings;
  const nrOfMarkedCustomers = customers.reduce((acc, next) => acc + (next.marked ? 1 : 0), 0);

  return {
    customers,
    nrOfMarkedCustomers,
    chains,
    lastCustomer,
    customerFilters,
    customerCategories,
    loading,
    isAdmin: user.accessLevel === 1,
  };
};

export default connect(mapStateToProps, {
  navigate,
  fetchChains,
  fetchCustomerCategories,
  setCustomerFilter,
})(CustomerList);
