import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, Switch } from 'react-router-dom';
import { Segment, Container, Grid, Header, List } from 'semantic-ui-react';

import TopMenu from './TopMenu';

import Packing from './packing/Packing';
import BookingList from './bookings/BookingList';
import Booking from './bookings/Booking';
import Mission from './bookings/Mission';
import CustomerList from './customers/CustomerList';
import Campaigns from './campaigns/Campaigns';
import Tickets from './tickets/Tickets';
import EventList from './events/EventList';
import Event from './events/Event';
import StaffList from './staff/StaffList';
import Reports from './reports/Reports';
import Graphs from './graphs/Graphs';
import Backups from './backups/Backups';
import Settings from './settings/Settings';

class AppRouter extends React.Component {
  render() {
    if (!this.props.accessLevel) {
      return <div />;
    }

    return (
      <div>
        <TopMenu />
        <div
          style={{
            paddingLeft: 20,
            paddingRight: 20,
            marginTop: 120,
            minHeight: '100vh',
          }}
        >
          {this.props.accessLevel === 1 ? (
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/bookings" />} />
              <Route exact path="/bookings" component={BookingList} />
              <Route path="/bookings/mission/new" component={Mission} />
              <Route path="/bookings/mission/:id" component={Mission} />
              <Route path="/bookings/new" component={Booking} />
              <Route path="/bookings/:id" component={Booking} />

              <Route exact path="/packing" component={Packing} />
              <Route exact path="/packing/:id" component={Packing} />

              <Route path="/customers" component={CustomerList} />
              <Route path="/campaigns" component={Campaigns} />

              <Route path="/tickets" component={Tickets} />

              <Route path="/events" component={EventList} />
              <Route path="/events/new" component={Event} />
              <Route path="/events/:id" component={Event} />

              <Route path="/staff" component={StaffList} />

              <Route path="/reports" component={Reports} />

              <Route path="/graphs" component={Graphs} />

              <Route path="/backups" component={Backups} />
              <Route path="/settings" component={Settings} />
            </Switch>
          ) : this.props.accessLevel === 2 ? (
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/bookings" />} />
              <Route exact path="/bookings" component={BookingList} />
              <Route path="/bookings/mission/new" component={Mission} />
              <Route path="/bookings/mission/:id" component={Mission} />
              <Route path="/bookings/mission" component={Mission} />
              <Route path="/bookings/new" component={Booking} />
              <Route path="/bookings/:id" component={Booking} />

              <Route exact path="/packing" component={Packing} />
              <Route exact path="/packing/:id" component={Packing} />

              <Route path="/customers" component={CustomerList} />

              <Route path="/tickets" component={Tickets} />
            </Switch>
          ) : (
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/tickets" />} />
              <Route path="/tickets" component={Tickets} />
            </Switch>
          )}
        </div>
        <Segment inverted vertical style={{ padding: '5em 0em', marginTop: 30 }}>
          <Container>
            <Grid divided inverted stackable>
              <Grid.Row>
                <Grid.Column style={{ textAlign: 'center' }}>
                  <Header inverted as="h4" content="Magnet & Company Europe AB" />
                  <List link inverted>
                    <List.Item as="a" />
                  </List>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </Container>
        </Segment>
      </div>
    );
  }
}

const mapStateToProps = ({ user }) => {
  const { accessLevel } = user;
  return { accessLevel };
};

export default connect(mapStateToProps)(AppRouter);
